import initialState from './initialState';
import {
  SET_NAME,
  SET_LENDER,
  GET_MENU,
  GET_MENU_SUCCESS,
  GET_MENU_FAILURE,
  SET_PRODUCTS
} from '../actions/actionConstants'


export default function AppConstantsReducer(state = initialState.appConstants, action) {

  switch (action.type) {
    case SET_PRODUCTS:
      return Object.assign({}, state, { lender_product: [].concat(action.data) })
    case SET_NAME:
      return Object.assign({}, state, { header_name: action.data })
    case SET_LENDER:
      return Object.assign({}, state, { providerId: action.data })
    case GET_MENU_SUCCESS:
      return Object.assign({}, state, { menu: action.data }, { lenderConstants: action.meta.providers }, { doc_types: action.meta.doc_types_grouped }, {multiDocs: action.multiDocs}, { loanHistoryStatuses: action.meta.loan_history_statuses })
    default:
      return state;
  }
}

