import axios from 'axios';
import config from '../../src/config';

export const get = (url, body = {}) => {
  const finalUrl = config.API_BASE + url;
  const param = {
    params: {
      ...body
    }
  }
  return new Promise((resolve) => {
    axios
      .get(finalUrl, param)
      .then(response => resolve(response))
      .catch(error => resolve(error.response));
  });
};

export const post = (url, body) => {
  const finalUrl = config.API_BASE + url;
  return new Promise((resolve) => {
    axios
      .post(finalUrl, body)
      .then(response => resolve(response))
      .catch(error => resolve(error.response));
  });
};
