import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import { BLUE } from '../constants/index';
import { BounceLoader } from 'react-spinners';

const CustomButton = (props) => {

  let {
    label = 'Continue',
    type = 'submit',
    fullWidth = false,
    backgroundColor = 'dodgerblue',
    labelColor = '#FFF',
    style = {},
    buttonStyle = {},
    labelStyle = {},
    disabled = false,
    primary = true,
    disabledBackgroundColor = 'lightgrey',
    disabledLabelColor = 'grey',
    isLoading = false,
    ...others
  } = props

  style = Object.assign({
    width: '100%',
  }, style)

  buttonStyle = Object.assign({
    borderRadius: '5px',
    backgroundColor: disabled ? disabledBackgroundColor : backgroundColor
  }, buttonStyle)

  labelStyle = Object.assign({
    fontSize: '14px',
  }, labelStyle)

  return (
    <div className='submit-button' style={{ marginTop: '20px', position: 'relative' }}>
      <RaisedButton
        primary={primary}
        type={type}
        label={label}
        fullWidth={fullWidth}
        backgroundColor={backgroundColor}
        labelColor={labelColor}
        style={style}
        buttonStyle={buttonStyle}
        disabled={disabled || isLoading}
        labelStyle={labelStyle}
        disabledLabelColor={disabledLabelColor}
        {...others}
      />
      {
        isLoading &&
        <div className='loader'>
          <BounceLoader
            color={'white'}
            loading={true}
            size={30}
          />
        </div>
      }
    </div>
  )
}

export default CustomButton;

