import React from 'react';
import TextBox from '../../components/TextBox';
import CustomAsyncSelect from '../CustomAsyncSelect';
import SelectComponent from '../SelectComponent';

export const renderTextField = (
  { input, meta, label, ...custom }
) => {
  const { active, touched, error } = meta
  return <TextBox
    input={input}
    {...custom}
    errorText={!active && touched && error}
  />
};

export const renderSelectField = (
  { input, meta, label, ...custom }
) => {
  const { active, touched, error } = meta
  return (
    <div>
      <label className='text-box-label'>{label}</label>
      <div>
        <SelectComponent
          input={input}
          {...custom}
        />
        <div className='form-error-text'>{!active && touched && error}</div>
      </div>
    </div>
  )
};

export const renderAsyncSelectField = (
  { input, meta, label, ...custom }
) => {
  const { active, touched, error } = meta
  return (
    <div>
      <label className='text-box-label'>{label}</label>
      <div>
        <CustomAsyncSelect
          input={input}
          {...custom}
        />
        <div className='form-error-text'>{!active && touched && error}</div>
      </div>
    </div>
  )
};
