import React, { Component } from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

class Dropdown extends Component {

  render() {
    const {
      floatingLabelText = '',
      disabled = false,
      value = 0,
      items = [],
      className = '',
      keys = [],
      ...others
    } = this.props
    return (
      <div>
        <SelectField
          className={className}
          floatingLabelText={floatingLabelText}
          disabled={disabled}
          value={value}
          {...others}
        >
          {
            items && items.map((val, i) => {
              return (
                keys.length == 0
                  ? <MenuItem key={i} value={val.id || val.store_id} primaryText={val.name} />
                  : <MenuItem key={i} value={val[keys[0]]} primaryText={val[keys[1]]} />
              );
            })
          }
        </SelectField>
      </div>
    );
  }
}

export default Dropdown;