import React, { Component } from 'react';

class ProviderDocuments extends Component {
    render() {
        const {
            text =[]
        } = this.props;

        return (
            <div className='doc-container' style={{ textAlign: 'left' }}>
            <hr/>
                {
                    text.map((item, index) => {
                        return (
                            <div key={index} className='font-normal'>
                                <div>Loan Amount: {item.title}</div>
                                <div>Required Documents:</div>
                                <ul>
                                    {
                                        item.value.map((val, i) => {
                                            return <li key={i}>{val}</li>
                                        })
                                    }
                                </ul>
                            </div>
                        )

                    })
                }
            </div>
        );
    }
}

export default ProviderDocuments;