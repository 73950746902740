import React, { Component } from 'react';
import SingleTextFieldForm from '../components/SingleTextFieldForm';
import { connect } from 'react-redux';
import * as EditActions from '../actions/EditPageActions';
import SearchFormUI from '../components/SearchFormUI';
import InfoComponent from '../components/InfoComponent';
import { isEmpty } from '../utils/utility'
import { getFormValues } from 'redux-form';

class DebitTransactionContainer extends Component {

  componentWillUnmount() {
    // resetting loan details state
    this.props.resetLoanDetails();
  }

  doDebit = (values) => {
    const {
      details
    } = this.props

    this.props.debitTransaction({
      loanId: details && details.length > 0 ? details[0].id : null,
      amount: values.amount
    })
  }

  //[@TODO: Move this function to utility ]
  extractDetailsToArray = (detailsObj) => {
    const sanitizedObj = detailsObj
      ? Array.isArray(detailsObj) && detailsObj.length > 0
        ? detailsObj[0]
        : {}
      : {}
    try {
      if (!isEmpty(sanitizedObj)) {
        return [
          {
            label: 'Name',
            text: sanitizedObj.name
          },
          {
            label: 'Account No.',
            text: sanitizedObj.account_no
          },
          {
            label: 'Mobile',
            text: sanitizedObj.mobile
          },
          {
            label: 'Sanction Amount',
            text: sanitizedObj.sanction_amount_formatted
          },
          {
            label: 'Available Amount',
            text: sanitizedObj.available_amount_formatted
          }
        ]
      }
      else {
        return []
      }
    }
    catch (err) {
      return []
    }
  }

  render() {
    const {
      formError,
      msg,
      details
    } = this.props
    return (
      <div>
        <SearchFormUI
          {...this.props}
        >
          <div className='fluid-width-45'>
            <div className='heading'>
              Debit Transactions
              </div>
            <div>
              <InfoComponent
                details={this.extractDetailsToArray(details)}
              />
            </div>
            <div>
              <SingleTextFieldForm
                onSubmit={this.doDebit}
                formName='debit'
                textBoxName='amount'
                textLabel='Debit Transaction Amount'
                buttonLabel='Submit'
                className='width-100'
              />
            </div>
            {
              formError && formError.length > 0 &&
              <div style={{ margin: '10px' }}>
                {
                  formError.map((item, i) => {
                    return (
                      <div className="alert alert-danger" role="alert" key={i}>
                        {item}
                      </div>
                    )
                  })
                }
              </div>
            }
            {
              msg &&
              <div style={{ margin: '10px' }}>
                <div className="alert alert-success" role="alert">
                  {msg}
                </div>
              </div>
            }
          </div>
        </SearchFormUI>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    details: state.loans.details,
    formError: state.loans.formError,
    msg: state.loans.msg,
    error: state.loans.error,
    searchFormValues: getFormValues('search')(state),
  };
}

export default connect(mapStateToProps, {
  ...EditActions
})(DebitTransactionContainer);