import {
  RESET_TRANSACTIONS,
  FETCH_TRANSACTIONS,
  APPROVE_TRANSACTION,
  REJECT_TRANSACTION,
  RESET_APPROVE_SUCCESS_MESSAGE,
  RESET_TRANSACTION_ERROR,

  SET_LENDER
} from './actionConstants'

export const fetchTransactions = (data) => {
  return {
    type: FETCH_TRANSACTIONS,
    payload: data
  }
}
export const approveTransaction = (data) => {
  return {
    type: APPROVE_TRANSACTION,
    payload: data
  }
}
export const rejectTransaction = (data) => {
  return {
    type: REJECT_TRANSACTION,
    payload: data
  }
}

export const resetApprovalSuccess = (data) => {
  return {
    type: RESET_APPROVE_SUCCESS_MESSAGE,
    data
  }
}

export const resetTransactionError = (data) => {
  return {
    type: RESET_TRANSACTION_ERROR,
    data
  }
}

export const resetTransaction = () => {
  return {
    type: RESET_TRANSACTIONS,
  }
}

export const setLender = (data) => {
  return {
    type: SET_LENDER,
    data
  }
}