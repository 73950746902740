import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';

class CustomPagination extends Component {
    render() {
        const {
            pageCount=10,
            onPageChange = () =>{ console.log('Page Changed')},
            forcePage = 0,
            customMsg
        } = this.props;
        return (
            <div>
                <ReactPaginate
                    forcePage={forcePage}
                    onPageChange={onPageChange}
                    // disableInitialCallback={true}
                    initialPage={0}
                    pageCount={pageCount}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    activeClassName={'active-page'}
                    previousClassName={'prev'}
                    nextClassName='next'
                    pageClassName='all-page'
                    previousLabel={pageCount ? 'Previous':''}
                    nextLabel={pageCount ? 'Next':''}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                />
                { pageCount == 0 && <div className='textbox'><h6>{customMsg ? customMsg : 'No data to show'}</h6></div>}
            </div>
        );
    }
}

export default CustomPagination;