import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as CustomerActions from '../actions/SearchCustomerPageActions'
import CustomerOnboardForm from '../components/CustomerOnboardForm';
import { getFormValues } from 'redux-form';
import { UtilityService } from '../Services'
import { CreditReportService } from '../Services/CreditReportService'
// import CustomFilePond from '../components/CustomFilePond';
import { getSetLocale, findObjectInArray, postSetLocale, sanitizeDocuments } from '../utils/utility'
import { GridLoader } from 'react-spinners';
import { prepareOnboardSubmit } from '../utils/prepareOnboardSubmit'
import { resetForm } from '../actions/MembershipFormActions';
import ProviderDocuments from '../components/ProviderDocuments';
import config from '../../src/config';
import { debounce } from '../utils/utility'
import { CustomFilePond } from 'chqbook-ui-components/dist'
import 'chqbook-ui-components/dist/index.css';

const API_URL_UPLOAD = '/user/doc/admin-upload';
const API_URL_INSTRUMENT = '/instruments/new';
const API_URL_GET_USER_DOCS = '/user/docs';
const API_URL_GET_LEAD_DOCS = '/lead/docs';
const API_URL_SUBMIT = '/lead/credit-line'
const API_UNVERIFIED_LEAD = '/lead/add/unverified'
const API_GET_REQUIRED_DOCS = '/provider/docs'
const API_URL_EDIT = '/lead/credit-line/update'
const API_URL_DOCS_UPDATE = '/lead/docs/update'

const PRODUCT_ID = 15
const LIMIT = 50;

const OTHER_DOC = 'other_doc'
// --------------------------------------------------------------------------------------------------
//  This component is used in three scenarios where action slug of the route is: submit, edit, upload
// --------------------------------------------------------------------------------------------------

class OnboardContainer extends Component {
    service = new UtilityService(this);
    creditReport = new CreditReportService(this);

    constructor(props) {
        super(props);
        this.state = {
            sendOTPAlert: null,
            otpBtnClickFlag: false,
            isSubmitting: false,
            fetchDocumentComplete: false,
            errors: [],
            msg: null,
            meta: {},
            uploadErrors: [],

            instruments: [],
            userDocs: [],
            leadDocs: [],
            finalDocList: [],
            updatedDocList: [],
            updateDocErrors: {},

            text: [],

            removedDocs: []
        }
        this.setState = this.setState.bind(this);
        this.handleChangeDebounced = debounce(this.handleValueChange, 700)
    }

    componentDidMount() {
        const leadId = this.props.match.params.leadId;
        const userId = this.props.match.params.userId;

        //instruments call for productId: 15
        getSetLocale(API_URL_INSTRUMENT, { productId: PRODUCT_ID }, this.setState, { data: 'instruments' })
            .then(() => {
                if (leadId) {
                    // user info API has been called after instruments API is resolved to meet the condition of calling 
                    //docs text API based on providerId in ComponentDidUpdate
                    this.props.searchCustomerAccount({ leadId })
                }
            })

        if (leadId && userId) {
            //getting lead docs
            getSetLocale(API_URL_GET_LEAD_DOCS, { leadId, userId, thumbnails: 1 }, this.setState, { data: 'leadDocs' })
                .then(() => {
                    //getting user docs
                    getSetLocale(API_URL_GET_USER_DOCS, { userId, limit: LIMIT, objectType: 24, thumbnails: 1 }, this.setState, { data: 'userDocs' })
                        .then(() => {
                            const {
                                leadDocs,
                                userDocs
                            } = this.state;

                            const {
                                multiDocs
                            } = this.props;

                            try {
                                let finalDocList = [];
                                let tmpLeadDocs = leadDocs.length !== 0 ? leadDocs[0].lead_docs : []
                                if (tmpLeadDocs.length == 0) {
                                    finalDocList = userDocs;
                                    this.setState({ finalDocList: [].concat(userDocs), fetchDocumentComplete: true })
                                }
                                else {
                                    finalDocList = userDocs.filter((item) => {
                                        const result = tmpLeadDocs.find(obj => obj['pretty_name'] == item.pretty_name || item.id == obj.media_file_id)
                                        return result === undefined ? true : false
                                    })
                                    finalDocList = finalDocList.concat(tmpLeadDocs);
                                    this.setState({ finalDocList: [].concat(finalDocList), fetchDocumentComplete: true })
                                }
                                //setting updatedDocList in state
                                this.setState({ updatedDocList: [].concat(finalDocList) })
                            } catch (err) { console.log('Something went wrong', err) }
                        })
                })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // At this point, we're in the "commit" phase, so it's safe to load the new data.
        const {
            info,
            values
        } = this.props;
        const {
            instruments
        } = this.state

        const action = this.props.match.params.action;

        try {
            if (action == 'submit' && values && values.lender && prevProps.values && values.requiredLimit !== undefined && prevProps.values.requiredLimit === undefined) {
                // This condition is checked when the component is first mounted
                console.log('-------')
                let obj = findObjectInArray(instruments, values.lender, 'id')
                getSetLocale(API_GET_REQUIRED_DOCS, { loanAmount: values.requiredLimit, productId: PRODUCT_ID, providerId: obj.provider_id }, this.setState, { data: 'text' })
            }
            else if (action == 'upload' && info.instrument_id && !prevProps.info.instrument_id) {
                let obj = findObjectInArray(instruments, info.instrument_id, 'id')
                console.log('-----')
                getSetLocale(API_GET_REQUIRED_DOCS, { loanAmount: info.loan_amount, productId: PRODUCT_ID, providerId: obj.provider_id }, this.setState, { data: 'text' })
            }
        } catch (err) { console.log('Something went wrong', err) }

    }

    componentWillUnmount() {
        //resetting all the details on Component Unmount as *getCustomerAccount saga is being used in onboarding container
        this.props.resetForm({ formName: 'onboard' });
        this.props.resetCustomerDetails();
    }

    handleValueChange = (event, value, index, name) => {
        const {
            values
        } = this.props;
        const {
            instruments
        } = this.state;
        //  when the instrument dropdown value is changed OR if the required limit is changed
        try {
            console.log('-------')
            let obj = findObjectInArray(instruments, values.lender, 'id')
            getSetLocale(API_GET_REQUIRED_DOCS, { loanAmount: values.requiredLimit, productId: PRODUCT_ID, providerId: obj.provider_id }, this.setState, { data: 'text' })
        } catch (err) { console.log('Something went wrong', err) }
    }

    checkAndUpdateDoc = (docs, leadId, userId, doc) => {
        //if editing already submitted lead
        const action = this.props.match.params.action;
        const {
            info
        } = this.props;

        if (action === 'submit' && info.duplicate !== 2) {
            let payload = sanitizeDocuments(docs);
            payload = payload.length !== 0 ? JSON.stringify(payload) : null
            postSetLocale(API_URL_DOCS_UPDATE, { leadDocs: payload, userId, leadId }, this.setState)
                .then(() => {
                    this.setState({ updateDocErrors: {} })
                })
                .catch((err) => {
                    this.setState(prevState => {
                        return { updateDocErrors: Object.assign({}, prevState.updateDocErrors, { [doc.pretty_name]: err.data.errors[0] }) }
                    })
                })
        }
    }

    setFreshUploadedDoc = (doc) => {
        const {
            finalDocList,
            updatedDocList
        } = this.state;

        const {
            multiDocs
        } = this.props

        const leadId = this.props.match.params.leadId;
        const userId = this.props.match.params.userId;

        if (updatedDocList.length === 0 && finalDocList.length > 0) {
            let updatedDocList = finalDocList.filter(item => {
                //there can be multiple other documents
                return item.pretty_name == doc.pretty_name && !multiDocs.hasOwnProperty(item.pretty_name) ? false : true
            })
            updatedDocList.push(doc)
            this.setState({ updatedDocList: [].concat(updatedDocList) })
            this.checkAndUpdateDoc(updatedDocList, leadId, userId, doc)
        }
        else if (updatedDocList.length > 0) {
            let docList = updatedDocList.filter(item => {
                return item.pretty_name == doc.pretty_name && !multiDocs.hasOwnProperty(item.pretty_name) ? false : true
            })
            docList.push(doc)
            this.setState({ updatedDocList: [].concat(docList) })
            this.checkAndUpdateDoc(docList, leadId, userId, doc)
        }
        else {
            this.setState({ updatedDocList: [].concat(doc) })
            this.checkAndUpdateDoc([].concat(doc), leadId, userId, doc)
        }
    }

    submit = (values = {}) => {
        const leadId = this.props.match.params.leadId;
        const userId = this.props.match.params.userId;
        const action = this.props.match.params.action;

        const {
            finalDocList,
            updatedDocList
        } = this.state;

        const {
            info
        } = this.props;

        if (action == 'submit' && info.duplicate !== 2) {
            //editing already created lead
            const payload = updatedDocList.length === 0
                ? prepareOnboardSubmit({ ...values, unverifiedLeadId: leadId, leadDocs: finalDocList, productId: PRODUCT_ID, action, userId })
                : prepareOnboardSubmit({ ...values, unverifiedLeadId: leadId, leadDocs: updatedDocList, productId: PRODUCT_ID, action, userId })
            // console.log('PAYLOAD', payload)
            //send consent in request
            postSetLocale(API_URL_EDIT, payload, this.setState, { data: 'meta', errors: 'errors', msg: 'msg', isLoading: 'isSubmitting' })
                .then((res) => {
                    const leadId = res.data.data.results.lead_id
                    this.props.history.push(`/success/${leadId}`)
                })
                .catch(err => { })
        }
        else if (action == 'submit' && info.duplicate == 2) {
            //if duplicate === 2 calling final submit API
            const payload = updatedDocList.length === 0
                ? prepareOnboardSubmit({ ...values, unverifiedLeadId: leadId, leadDocs: finalDocList, productId: PRODUCT_ID })
                : prepareOnboardSubmit({ ...values, unverifiedLeadId: leadId, leadDocs: updatedDocList, productId: PRODUCT_ID })
            // console.log('PAYLOAD', payload)
            //send consent in request
            postSetLocale(API_URL_SUBMIT, payload, this.setState, { data: 'meta', errors: 'errors', msg: 'msg', isLoading: 'isSubmitting' })
                .then((res) => {
                    const leadId = res.data.data.results.lead_id
                    this.props.history.push(`/success/${leadId}`)
                })
                .catch(err => { })
        }
        else if (action == 'edit') {
            //creating new lead
            const payload = prepareOnboardSubmit({ ...values, productId: PRODUCT_ID })
            // console.log('EDIT', payload)
            postSetLocale(API_UNVERIFIED_LEAD, payload, this.setState, { data: 'meta', errors: 'errors', msg: 'msg', isLoading: 'isSubmitting' })
                .then((res) => {
                    const userId = res.data.data.results.user_id
                    // if leadId already exists with the same number it returns an object
                    const leadId = res.data.data.results.lead_id instanceof Object
                        ? res.data.data.results.lead_id.lead_id
                        : res.data.data.results.lead_id
                    window.location.href = config.BASE_URI + `/onboard/upload/${leadId}/${userId}`;
                })
                .catch(err => { })
        }
        else if (action == 'upload') {
            const payload = updatedDocList.length === 0
                ? prepareOnboardSubmit({ ...values, unverifiedLeadId: leadId, leadDocs: finalDocList, productId: PRODUCT_ID, metroCardNo: info.store_unique_id, experianConsent: true })
                : prepareOnboardSubmit({ ...values, unverifiedLeadId: leadId, leadDocs: updatedDocList, productId: PRODUCT_ID, metroCardNo: info.store_unique_id, experianConsent: true })
            // console.log('PAYLOAD', payload)
            //send consent in request
            postSetLocale(API_URL_SUBMIT, payload, this.setState, { data: 'meta', errors: 'errors', msg: 'msg', isLoading: 'isSubmitting' })
                .then((res) => {
                    const leadId = res.data.data.results.lead_id
                    this.props.history.push(`/success/${leadId}`)
                })
                .catch(err => { })
        }
    }

    handleOTPClick = () => {
        if (this.props.values) {
            this.service.sendOTP({
                mobile: this.props.values.mobile,
                type: 1
            })
        }
    }

    handleReset = () => {
        //reset local error and msg + dispatch form clear function
        this.setState({ errors: [], msg: null }, () => {
            this.props.resetForm({ formName: 'onboard' });
        })
    }

    removeUpdateError = (err, prettyName) => {
        this.setState(prevState => {
            return { updateDocErrors: Object.assign({}, prevState.updateDocErrors, { [prettyName]: null }) }
        })
    }

    filterDocArray = ({ arr, name, id }) => {
        let filteredArray = arr.map((item) => {
            if (item.file_name && item.file_name != name) {
                return item
            }
            else if (item.filepondId && item.filepondId != id) {
                return item
            }
            return {
                ...item,
                status: 0,
                removed: true
            }
        })
        return filteredArray
    }

    onRemoveFile = (fileName, prettyName, fileId) => {
        const leadId = this.props.match.params.leadId;
        const userId = this.props.match.params.userId;

        const {
            updatedDocList
        } = this.state;

        let updatedDocuments = this.filterDocArray({ arr: updatedDocList, name: fileName, id: fileId })

        this.setState(prevState => {
            return {
                updatedDocList: [].concat(updatedDocuments),
                removedDocs: prevState.removedDocs.concat(fileName),
            }
        })

        // console.log('updatedDocs',updatedDocuments)

        this.checkAndUpdateDoc(updatedDocuments, leadId, userId, { pretty_name: prettyName }) //calling update API
    }

    render() {
        const {
            otpBtnClickFlag,
            sendOTPAlert,
            errors,
            instruments,
            finalDocList,
            msg,
            isSubmitting,
            fetchDocumentComplete,
            text,
            updatedDocList,
            updateDocErrors,
            removedDocs
        } = this.state

        const {
            doc_types,
            multiDocs,
            values,
            info
        } = this.props;

        // console.log('finalDocList', finalDocList)
        // console.log('updatedDocList', updatedDocList)

        return (
            <div>
                <CustomerOnboardForm
                    hideForm={this.props.match.params.action == 'upload'}
                    onSubmit={this.submit}
                    toggleOtpBtnClickFlag={this.service.toggleOtpBtnClickFlag}
                    otpBtnClickFlag={otpBtnClickFlag}
                    handleOTP={this.handleOTPClick}
                    sendOTPAlert={sendOTPAlert}
                    lenderOptions={instruments}
                    lenderKeys={['id', 'name']}
                    consent={values ? values.experianConsent : false}
                    otpFlag={values ? values.mobile : ''}
                    mobileFlag={info.duplicate !== undefined && info.duplicate !== 2}
                    handleReset={this.handleReset}
                    isSubmitting={isSubmitting}
                    showFormBtns={true}
                    disableFields={false}
                    handleChange={this.handleChangeDebounced}
                >
                    {
                        !(this.props.match.params.action == 'edit') &&
                        <div className='documents-upload textbox'>
                            <h6>Upload KYC Documents <b className='tick'>&#x2713;</b></h6>
                            <ProviderDocuments text={text} />
                            {
                                fetchDocumentComplete
                                    ? Object.keys(doc_types).map((val, outerIndex) => {
                                        return (
                                            <div key={outerIndex}>
                                                <h4 style={{ textDecoration: 'underline' }}>{val}</h4>
                                                {
                                                    doc_types[val].map((item, index) => {
                                                        let obj = findObjectInArray(finalDocList, item.file_name, 'pretty_name')
                                                        const initUrl = obj
                                                            ? obj.file
                                                                ? 'data:image/jpg;base64,' + obj.file
                                                                : true //cannot assign it null as the component itself assigns state as null for all the falsy values
                                                            : null;

                                                        let otherUrls = finalDocList.filter((item) => {
                                                            //filtering the documents belonging to other docs category from the finalDocList
                                                            //We also find if any of the value with other_doc filename exists in removed docs
                                                            //if yes then we exclude it and filter that also
                                                            //this returned array is used for download buttons corresponding to other docs
                                                            const result = removedDocs.find(val => val == item.file_name)
                                                            return result === undefined && multiDocs.hasOwnProperty(item.pretty_name) ? true : false
                                                        })
                                                        return (
                                                            <div key={index}>
                                                                {
                                                                    multiDocs.hasOwnProperty(item.file_name)
                                                                        ? <div>
                                                                            <CustomFilePond
                                                                                initUrl={otherUrls}
                                                                                documentName={item.user_text}
                                                                                url={config.API_BASE + API_URL_UPLOAD}
                                                                                metadata={{
                                                                                    docType: item.id,
                                                                                    userId: this.props.match.params.userId
                                                                                }}
                                                                                setResponseMeta={this.setFreshUploadedDoc}
                                                                                prettyName={item.file_name}
                                                                                setError={this.removeUpdateError}
                                                                                onRemoveFile={this.onRemoveFile}
                                                                                multiple={true}
                                                                                allowMultiple={true}
                                                                                allowReplace={false}
                                                                                maxFiles={50}
                                                                            />
                                                                            {
                                                                                updateDocErrors[item.file_name] &&
                                                                                <div className="alert alert-danger" style={{ bottom: '15px', borderColor: 'red' }} role="alert">
                                                                                    {'Failed to update file: ' + updateDocErrors[item.file_name]}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        : <div>
                                                                            <CustomFilePond
                                                                                initUrl={initUrl}
                                                                                documentName={item.user_text}
                                                                                url={config.API_BASE + API_URL_UPLOAD}
                                                                                metadata={{
                                                                                    docType: item.id,
                                                                                    userId: this.props.match.params.userId
                                                                                }}
                                                                                setResponseMeta={this.setFreshUploadedDoc}
                                                                                prettyName={item.file_name}
                                                                                setError={this.removeUpdateError}
                                                                                onRemoveFile={this.onRemoveFile}
                                                                                fileName={obj ? obj.file_name : null}
                                                                                fileSize={obj ? obj.file_size : null}
                                                                                fileType={obj ? obj.file_type : null}
                                                                            />
                                                                            {
                                                                                updateDocErrors[item.file_name] &&
                                                                                <div className="alert alert-danger" style={{ bottom: '15px', borderColor: 'red' }} role="alert">
                                                                                    {'Failed to update file: ' + updateDocErrors[item.file_name]}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                }
                                                            </div>
                                                        );
                                                    })
                                                }
                                                <br />
                                            </div>
                                        );
                                    })
                                    : <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <GridLoader
                                            color={'dodgerblue'}
                                            loading={true}
                                            size={10}
                                        />
                                    </div>
                            }
                        </div>
                    }
                </CustomerOnboardForm>
                {
                    errors && errors.length > 0 &&
                    <div className='membership-msg'>
                        {
                            errors.map((item, i) => {
                                return (
                                    <div className="alert alert-danger" role="alert" key={i}>
                                        {item}
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                {
                    msg &&
                    <div className='membership-msg'>
                        <div className="alert alert-success" role="alert">
                            {msg}
                        </div>
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        multiDocs: state.appConstants.multiDocs,
        doc_types: state.appConstants.doc_types,
        info: state.customers.info,
        values: getFormValues('onboard')(state),
    };
}

export default connect(mapStateToProps, {
    ...CustomerActions, resetForm
})(OnboardContainer);