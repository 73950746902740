import React, { Component } from 'react';
import { reduxForm } from 'redux-form'
import ReduxTextField from '../ReduxFormFields/ReduxTextField';
import {
  lessThanEqual,
  firstLetterUpper,
  normalizeName,
  normalizeMetroNumber,
  normalizeEliteMetroNumber
} from '../utils/normalizerUtility'
import {
  required,
  mobile,
  maxLength72,
  minLength3,
  gstValidator,
  maxLength15,
  name,
  maxDate,
  maxMonth,
  isNumber,
  address,
  pincode,
  pancard,
  eliteMetroNumber
} from '../utils/fieldValidate'
import ReduxSelectField from '../ReduxFormFields/ReduxSelectField';
import {
  GENDER_OPTION,
  EMPLOYMENT_TYPE_OPTIONS,
  // NOMINEE_RELATION_TYPE,
  METRO_PLANS,
  BLUE, RED
} from '../constants'
import CustomButton from './Button';
import UploadContainer from './Upload';
import CountTimer from './CountTimer';
const height = '45px';

class TestMembershipForm extends Component {

  focusChange = (fieldName, limit, event, value, ) => {
    if (value.length == limit) {
      document.getElementsByName(fieldName)[0].focus()
    }
  }

  render() {
    const {
      pristine, reset, submitting, // submitting not working as api call is not from redux
      isSubmitting,
      handleSubmit,
      handleFieldChange,
      handleFieldBlur,
      handleReset,
      handleOTP,
      metroHolderFlag,
      setImgFile,
      imgFile = null,
      sendOTPAlert,
      otpBtnClickFlag,
      toggleOtpBtnClickFlag,
      onScanBarcode = () => { },
      metroCardImage
      // nomineeFlag
    } = this.props
    return (
      <React.Fragment>
        <form onSubmit={handleSubmit}>
          <div className='flex-vertical inner-container'>
            {/* <ReduxSelectField
              label='Membership Plan'
              validate={[required]}
              name='planId'
              options={METRO_PLANS}
            /> */}
            <div style={{ width: '150px' }}>
              <CustomButton
                label='Scan Barcode'
                onClick={onScanBarcode}
                type='button'
              />
              {metroCardImage && <img src={metroCardImage} alt='metroCardImage' style={{ width: '300px' }} />}
              <br />
            </div>
            <div className='flex-horizontal'>
              <div className='width-65'>
                <ReduxTextField
                  textLabel='10 Digit Metro Card No.'
                  style={{ height }}
                  name='metroCardNumber'
                  validate={[required]}
                  normalize={normalizeMetroNumber}
                  type='tel'
                  onChange={this.focusChange.bind(null, 'metroHolderNumber', 12)}
                  onBlur={handleFieldBlur}
                />
              </div>
              <div className='width-35'>
                <ReduxTextField
                  textLabel='CH'
                  style={{ height }}
                  name='metroHolderNumber'
                  validate={[required]}
                  type='tel'
                  normalize={lessThanEqual(1)}
                  onChange={handleFieldChange}
                />
              </div>
            </div>
            {/* <UploadContainer
              setImgFile={setImgFile}
              imgFile={imgFile}
            /> */}
            <ReduxTextField
              textLabel='16 Digit QC Number'
              style={{ height }}
              name='eliteMetroNumber'
              normalize={normalizeEliteMetroNumber}
              validate={[required, eliteMetroNumber]}
              type='tel'
            />
            {/* <ReduxTextField
              textLabel='Email'
              style={{ height }}
              name='email'
              validate={[required]}
              type='email'
            /> */}
            {/* {
              metroHolderFlag && //remove BusinessName and GSTIN Field if metroCard Number's last digit == 1
              <React.Fragment>
                <ReduxTextField
                  textLabel='Business Name'
                  style={{ height }}
                  name='businessName'
                  maxLength='72'
                  validate={[required, maxLength72, minLength3]}
                  normalize={firstLetterUpper}
                />
                <ReduxTextField
                  textLabel='GSTIN Number'
                  style={{ height }}
                  name='gstNumber'
                  normalize={lessThanEqual(15)}
                  validate={[gstValidator]}
                  maxLength='15'
                />
              </React.Fragment>
            } */}
            <ReduxTextField
              textLabel='First Name'
              style={{ height }}
              name='firstname'
              normalize={normalizeName}
              validate={[required, name, minLength3]}
              maxLength='15'
            />
            {/* <ReduxTextField
              textLabel='Middle Name'
              style={{ height }}
              name='middlename'
              normalize={normalizeName}
              validate={[name, maxLength15]}
              maxLength='15'
            /> */}
            <ReduxTextField
              textLabel='Last Name'
              style={{ height }}
              name='lastname'
              normalize={normalizeName}
              validate={[required, name, maxLength15]}
              maxLength='15'
            />
            {/* <ReduxSelectField
              label='Gender'
              validate={[required]}
              name='gender'
              options={GENDER_OPTION}
            /> */}
            {/* <div className='flex-horizontal item-end-align'>
              <div className='width-25'>
                <ReduxTextField
                  textLabel='DOB'
                  style={{ height }}
                  name='dobDate'
                  hintText='Day'
                  validate={[required, maxDate, isNumber]}
                  type='tel'
                  max='31'
                  normalize={lessThanEqual(2)}
                  onChange={this.focusChange.bind(null, 'dobMonth', 2)}
                />
              </div>
              <div className='width-25'>
                <ReduxTextField
                  style={{ height }}
                  name='dobMonth'
                  hintText='Month'
                  validate={[required, maxMonth, isNumber]}
                  type='tel'
                  normalize={lessThanEqual(2)}
                  onChange={this.focusChange.bind(null, 'dobYear', 2)}
                />
              </div>
              <div className='width-50'>
                <ReduxTextField
                  style={{ height }}
                  name='dobYear'
                  hintText='Year'
                  validate={[required, isNumber]}
                  type='tel'
                  normalize={lessThanEqual(4)}
                />
              </div>
            </div> */}
            <ReduxSelectField
              label='Employment Type'
              validate={[required]}
              name='empType'
              options={EMPLOYMENT_TYPE_OPTIONS}
            />
            {/* <ReduxTextField
              textLabel='Home Address'
              style={{ height }}
              name='address'
              validate={[required, minLength3]}
              maxLength='72'
            />
            <ReduxTextField
              textLabel='Pincode'
              style={{ height }}
              name='pincode'
              normalize={lessThanEqual(6)}
              validate={[required, pincode]}
              type='tel'
            /> */}
            {/* {
              nomineeFlag &&
              <React.Fragment>
                <ReduxTextField
                  textLabel='Nominee First Name'
                  style={{ height }}
                  name='nomineeFirstname'
                  normalize={normalizeName}
                  validate={[required, name, minLength3]}
                  maxLength='15'
                />
                <ReduxTextField
                  textLabel='Nominee Last Name'
                  style={{ height }}
                  name='nomineeLastname'
                  normalize={normalizeName}
                  validate={[required, name, minLength3]}
                  maxLength='15'
                />
                <ReduxSelectField
                  label='Nominee Relationship'
                  validate={[required]}
                  name='nomineeRelationType'
                  options={NOMINEE_RELATION_TYPE}
                />
              </React.Fragment>
            } */}
            <ReduxTextField
              textLabel='Pancard'
              style={{ height }}
              name='pancard'
              validate={[pancard]}
              maxLength='10'
            />
            <ReduxTextField
              textLabel='Mobile'
              style={{ height }}
              name='mobile'
              validate={[required, mobile]}
              type='tel'
              normalize={lessThanEqual(10)}
              showBtn={true}
              sideBtnContainerStyle={{ width: '70px', height: '65px' }}
              sideBtnLabel={
                otpBtnClickFlag
                  ? <CountTimer toggle={toggleOtpBtnClickFlag} />
                  : 'Send OTP'}
              sideBtnlabelStyle={{ fontSize: '12px', top: '8px' }}
              sideBtnCoreStyle={{ height: '44px' }}
              sideBtnDisabled={otpBtnClickFlag}
              onClick={handleOTP}
            />
            {sendOTPAlert && <p className='form-success-text'>{sendOTPAlert}</p>}
            <ReduxTextField
              textLabel='OTP'
              style={{ height }}
              name='otp'
              validate={[required]}
              type='tel'
              normalize={lessThanEqual(4)}
            />
          </div>
          <div className='flex-horizontal'>
            <div className='width-100 padding-side'>
              <CustomButton
                label='Submit'
                fullWidth={true}
                buttonStyle={{ backgroundColor: BLUE }}
                disabled={isSubmitting || pristine}
                isLoading={isSubmitting}
              />
            </div>
            <div className='width-100 padding-side'>
              <CustomButton
                type='button'
                label='Clear'
                fullWidth={true}
                buttonStyle={{ backgroundColor: RED }}
                disabled={isSubmitting}
                onClick={handleReset}
              />
            </div>
          </div>
          <br />
        </form>
      </React.Fragment>
    );
  }
}

const FormFunc = reduxForm({
  form: 'membership',
  enableReinitialize: true,
  forceUnregisterOnUnmount: true, // this is to fix the sync error persist bug on field unmount in redux-form
});

TestMembershipForm = FormFunc(TestMembershipForm);

export default TestMembershipForm;