import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux';
import { compose } from 'redux';
import ReduxTextField from '../ReduxFormFields/ReduxTextField';
import CustomButton from '../components/Button';
import { isEmpty } from '../utils/utility'
import {
  required
} from '../utils/fieldValidate'
import { PROVIDER_ID_IDFC } from '../constants/index'

class ApprovalModalForm extends Component {

  sanitizeError = (errArr = [], id) => {
    try {
      if (errArr.length > 0) {
        // don't need to do any operation just return this array's last index 
        //object as that will be the latest error corresponding to this transactionId
        return errArr[errArr.length - 1][id] ? errArr[errArr.length - 1][id] : []
      }
      else {
        return []
      }
    }
    catch (err) {
      return []
    }
  }

  render() {
    const {
      className = '',
      handleSubmit,
      pristine,
      submitting,
      onCancel,
      formError = [],
      transactionId,
      approvalMsg,
      mismatchError,
      min,
      max,
      toggle
    } = this.props
    console.log(toggle)
    return (
      <div className={className}>
        <form onSubmit={handleSubmit}>
          <div className='fluid-width-50'>
            <div className='flex-vertical width-100'>
              <div style={{ paddingBottom: '5px', fontSize: '20px' }}> Fill in the below mentioned fields </div>
              <br />
              <div>
                <label>
                  <Field
                    name="toggle"
                    component="input"
                    type="radio"
                    value="1"
                  />{' '}
                  PDC
                   </label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <label>
                  <Field
                    name="toggle"
                    component="input"
                    type="radio"
                    value="0"
                  />{' '}
                  NACH
                 </label>
              </div>
              <ReduxTextField
                name='pdcDate'
                type='date'
                textLabel={toggle == '0' ? 'Repayment Date' : 'PDC Cheque Date'}
                max={max}
                min={min}
                required={true}
              />
              &nbsp;
              {
                toggle == '1' &&
                <React.Fragment>

                  <ReduxTextField
                    name='chequeNum'
                    type='tel'
                    textLabel='PDC Cheque Number'
                    // validate={[required]}
                    required={true}
                  />
                  &nbsp;
              <ReduxTextField
                    name='chequeAccountNo'
                    type='tel'
                    textLabel='Cheque Account Number'
                    required={true}
                  />
                  &nbsp;
              <ReduxTextField
                    name='bankName'
                    textLabel='Bank Name'
                    required={true}
                  />
                  &nbsp;
              <ReduxTextField
                    name='ifsc'
                    textLabel='IFSC'
                    required={true}
                  />
                  &nbsp;
                  <ReduxTextField
                    name='micrCode'
                    textLabel='MICR Code'
                    required={true}
                  />
                  &nbsp;
                </React.Fragment>
              }
              <ReduxTextField
                name='invoiceNo'
                textLabel='Invoice Number'
                required={true}
              />
              &nbsp;
              <ReduxTextField
                name='amount'
                textLabel='Invoice Amount'
                type='tel'
                required={true}
              />
              <div className='flex-horizontal j-center'>
                <div className='width-35'>
                  <CustomButton
                    label='Confirm'
                    fullWidth={true}
                    type='submit'
                    disabled={pristine || submitting}
                  />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
              <div className='width-35'>
                  <CustomButton
                    label='Cancel'
                    fullWidth={true}
                    type='button'
                    onClick={onCancel}
                  />
                </div>
              </div>
              {
                !mismatchError &&
                <div style={{ margin: '10px' }}>
                  {
                    this.sanitizeError(formError, transactionId).map((item, i) => {
                      return (
                        <div className="alert alert-danger" role="alert" key={i}>
                          {item}
                        </div>
                      )
                    })
                  }
                </div>
              }
              {
                mismatchError &&
                <div style={{ margin: '10px' }}>
                  <div className="alert alert-danger" role="alert">
                    {mismatchError}
                  </div>
                </div>
              }
              {
                approvalMsg &&
                <div style={{ margin: '10px' }}>
                  <div className="alert alert-success" role="alert">
                    {approvalMsg}
                  </div>
                </div>
              }
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default compose(
  connect((state, props) => {

    const initialValues = {
      toggle: props.providerId == PROVIDER_ID_IDFC ? '0' : '1'
    }

    const {
      formName = 'dataForm',
    } = props;
    return {
      form: formName,
      initialValues
    }
  }),
  reduxForm({
    enableReinitialize: true,
  }),
)(ApprovalModalForm);