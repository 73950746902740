import React, { Component } from 'react';
import {buildUrlWithQueryParams} from '../utils/utility'
import { API_URL_DOWNLOAD } from '../constants/apiUrls'

const pdfSrc = 'https://res.cloudinary.com/chqbook/image/upload/v1566540954/icons/pdf-placeholder.png';
const docSrc = 'https://res.cloudinary.com/chqbook/image/upload/v1566540966/icons/doc_placeholder.png';

class DocumentPreview extends Component {
    download = () => {
        const {
            userId,
            mediaFileId
        } = this.props;

        let url = buildUrlWithQueryParams(API_URL_DOWNLOAD, { userId, mediaFileId })
        window.open(url, '_blank')
    }

    renderPreview() {
        const {
            src = '',
            alt = '',
            fileType,
        } = this.props;

        switch (fileType) {
            case 'pdf':
                return <img onClick={this.download} alt={alt} src={src ? src : pdfSrc} width='100%' height='100%' />;;
            case 'docx':
                return <img onClick={this.download} alt={alt} src={docSrc} width='100%' height='100%' />;
            default:
                return <img onClick={this.download} alt={alt} src={src} width='100%' height='100%' />;
        }
    }

    render() {

        const {
            alt = '',
            timeStamp = null
        } = this.props;

        const dateTime = timeStamp ? timeStamp.split(' ') : [];

        return (
            <div>
                <div style={{ marginLeft: '10px' }}>
                    <div className='form-success-text'>
                        {alt}
                    </div>
                    <div style={{ fontSize: '12px' }}>
                        Uploaded on: {dateTime[0] + ', ' + dateTime[1]}
                    </div>
                </div>
                <div className='thumbnail'>
                    {
                        this.renderPreview()
                    }
                </div>
            </div>
        );
    }
}

export default DocumentPreview;