import React, { Component } from 'react';

class InfoComponent extends Component {

  render() {

    const {
      details = []
    } = this.props

    return (
      <div className='width-100 paper-fluid'>
        {
          details.length > 0 && details.map((item, i) => {
            return (
              <p key={i}><b>{item.label}</b> ~ {item.text}</p>
            );
          })
        }
      </div>
    );
  }
}

export default InfoComponent;