import {
  SEARCH_LOANS,
  SEND_FORM_VALUES,
  RESET_LOAN_DETAILS,
  CREDIT_TRANSACTION_INITIATE,
  DEBIT_TRANSACTION_INITIATE
} from './actionConstants'

export const searchLoans = (data) => {
  return {
    type: SEARCH_LOANS,
    payload: data
  }
}

export const sendFormValues = (data) => {
  return {
    type: SEND_FORM_VALUES,
    payload: data
  }
}

export const resetLoanDetails = (data) => {
  return {
    type: RESET_LOAN_DETAILS,
  }
}

export const creditTransaction = (data) => {
  return {
    type: CREDIT_TRANSACTION_INITIATE,
    payload: data
  }
}

export const debitTransaction = (data) => {
  return {
    type: DEBIT_TRANSACTION_INITIATE,
    payload: data
  }
}