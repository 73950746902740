
import { Link } from 'react-router-dom';
import { get, post } from '../api';

export class UtilityService {
  constructor(props) {
    this.component = props;
  }

  // changeHeaderText = (path, text) => {
  //   if (this.component.props.path === path) {
  //     this.component.setState({ headerText: text })
  //   }
  //   else {
  //     this.component.setState({ headerText: 'Metro Dashboard' })
  //   }
  // }

  downloadSampleFile = ({content, sampleFileFormat = 'csv', filename = 'upload_response'}) => {
    try {
      if (content) {
        var blob = new Blob([content], { type: `text/${sampleFileFormat}` })
        var a = document.createElement('a')
        var ev = document.createEvent('MouseEvents')
        a.href = window.URL.createObjectURL(blob)
        a.dataset.downloadurl = [`text/${sampleFileFormat}`, a.download, a.href].join(':')
        a.download = filename + `.${sampleFileFormat}`
        ev.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
        a.dispatchEvent(ev)
      }
    }
    catch (err) {
      console.log('ERROR',err)
      alert('Uh oh! something went wrong..')
    }
  }

  toggleOtpBtnClickFlag = () => {
    this.component.setState({ otpBtnClickFlag: !this.component.state.otpBtnClickFlag })
  }

  sendOTP = (payload) => {
    const SEND_OTP_URL = '/user/otp';
    this.toggleOtpBtnClickFlag();
    post(SEND_OTP_URL, payload)
    .then(res => {
      if (res.status == 200) {
        const msg = res.data.data.message
        this.component.setState({ sendOTPAlert: msg })
      }
      else if (res.status == 400) {
        const err = res.data.message;
        console.log('SEND_OTP_ERROR', err)
        this.toggleOtpBtnClickFlag();
      }
    })
  }
}