import initialState from './initialState';
import {
    SEARCH_CUSTOMER,
    SEARCH_CUSTOMER_SUCCESS,
    SEARCH_CUSTOMER_FAILURE,
    SEARCH_CUSTOMER_ACCOUNT,
    RESET_CUSTOMER_DETAILS
  } from '../actions/actionConstants';


export default function CustomerReducer(state = initialState.customers, action) {

  switch (action.type) {

    case SEARCH_CUSTOMER:
      return Object.assign({}, state, { isLoaded: false }, { error: null },)

    case SEARCH_CUSTOMER_ACCOUNT:
      return Object.assign({}, state, { isLoaded: false }, { error: null },)

    case SEARCH_CUSTOMER_SUCCESS:
      return Object.assign({}, state, { isLoaded: true }, { info: action.data })

    case SEARCH_CUSTOMER_FAILURE:
      return Object.assign({}, state, { isLoaded: true }, { error: action.error })

    case RESET_CUSTOMER_DETAILS:
      return Object.assign({}, state, { info: [] })

    default:
      return state;
  }
}

