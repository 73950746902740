import React, { Component } from 'react';
import Paper from 'material-ui/Paper';

const style = {
    // height: '100%',
    width: '100%',
    // margin: 20,
    textAlign: 'center',
    display: 'inline-block',
    padding: '10px',
    cursor: 'pointer'
    // justifyContent:'space-around'
};


class LenderRow extends Component {
    render() {
        const {
            item,
            clickHandle = () => { }
        } = this.props;

        return (
            <div>
                <Paper style={style} zDepth={1} onClick={() => clickHandle()}>
                    <div className='flex-horizontal font-normal'>
                        <div><b>Lead Id: </b></div>
                        &nbsp;&nbsp;
                    <div>{item.lead_id}</div>
                    </div>
                    <div className='flex-horizontal font-normal'>
                        <div><b>Business Name: </b></div>
                        &nbsp;&nbsp;
                    <div>{item.organization}</div>
                    </div>
                    <div className='flex-horizontal font-normal'>
                        <div><b>Created On: </b></div>
                        &nbsp;&nbsp;
                    <div>{item.created_at}</div>
                    </div>
                    {
                        item.store_unique_id &&
                        <div className='flex-horizontal font-normal'>
                            <div><b>Metro Card Number: </b></div>
                            &nbsp;&nbsp;
                    <div>{item.store_unique_id}</div>
                        </div>
                    }
                    {
                        item.store_code &&
                        <div className='flex-horizontal font-normal'>
                            <div><b>DC: </b></div>
                            &nbsp;&nbsp;
                    <div>{item.store_code}</div>
                        </div>
                    }
                    {
                        item.store_name &&
                        <div className='flex-horizontal font-normal'>
                            <div><b>Store: </b></div>
                            &nbsp;
                    <div>{item.store_name}</div>
                        </div>
                    }
                    <div className='flex-horizontal font-normal'>
                        <div><b>Status: </b></div>
                        &nbsp;&nbsp;
                    <div>{item.status_text}</div>
                    </div>
                </Paper>
            </div>
        );
    }
}

export default LenderRow;