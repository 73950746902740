export const prepareMembershipAutoPopulate = (data) => {
  const {
    first_name = null,
    middle_name = null,
    last_name = null,
    mobile = null,
    email = null,
    business_name = null,
    gst_num = null,
    gender = null,
    dob = null,
    emp_type = null,
    address1 = null,
    address2 = null,
    address3 = null,
    pin_code = null,
    pan = null,
  } = data

  let dobYear = null, dobMonth = null, dobDate = null;
  if (dob) {
    let userDOB = new Date(dob);
    dobDate = userDOB.getDate();
    dobMonth = userDOB.getMonth() + 1;
    dobYear = userDOB.getFullYear();
  }


  return {
    mobile,
    // email,
    // businessName: business_name,
    // gstNumber: gst_num,
    firstname: first_name,
    // middlename: middle_name,
    lastname: last_name,
    // gender,
    // empType: emp_type,
    // pincode: pin_code,
    pancard: pan,
    // address: `${address1 ? address1 + ' ' : ''}${address2 ? address2 + ' ' : ''}${address3 ? address3 : ''}`,
    // dobYear,
    // dobMonth,
    // dobDate
  }
}