
import React, { Component } from 'react';
import CustomTable from '../components/CustomTable';
import SingleTextFieldForm from '../components/SingleTextFieldForm';
import { THIRD_PARTY_LOANS_SEARCH_PARAMS } from '../constants/index'

class SearchFormUI extends Component {

  doSearch = (values) => {
    this.props.searchLoans({
      [values.searchBy]: values.card
    })
  }

  onRowClick = (val) => {
    this.props.searchLoans({
      loanId: val
    })
  }

  render() {
    const {
      details,
      error,
      children,
      searchFormValues
    } = this.props
    return (
      <div>
        <br />
        <SingleTextFieldForm
          selectFieldOptions={THIRD_PARTY_LOANS_SEARCH_PARAMS}
          showSelectField={true}
          textBoxType='text'
          onSubmit={this.doSearch}
          error={error}
          disableButton={searchFormValues && searchFormValues.searchBy && searchFormValues.card ? false : true}
        />
        {
          details && details.length > 1 &&
          <CustomTable
            details={details}
            onRowClick={this.onRowClick}
          />
        }
        {
          details && details.length == 1 &&
          <div className='children'>
            {children}
          </div>
        }
        {
          details && details.length == 0 &&
          <div className='textbox inner-container'>No results found !</div>
        }
      </div>
    );
  }
}

export default SearchFormUI;