import React, { Component } from 'react';
import CustomerOnboardForm from '../components/CustomerOnboardForm';
import { connect } from 'react-redux';
import * as CustomerActions from '../actions/SearchCustomerPageActions';
import { getFormValues } from 'redux-form';
import { UtilityService } from '../Services';
import { CreditReportService } from '../Services/CreditReportService';
import { resetForm } from '../actions/MembershipFormActions';

class CreditScoreContainer extends Component {
    service = new UtilityService(this);
    creditReport = new CreditReportService(this);

    constructor(props) {
        super(props);
        this.state = {
            sendOTPAlert: null,
            otpBtnClickFlag: false,
            errors: [],
            meta: {},
            isSubmitting: false,
            uploadErrors: [],
        }
        this.setState = this.setState.bind(this);
    }

    componentDidMount() {
        const leadId = this.props.match.params.leadId;
        if (leadId) {
            this.props.searchCustomerAccount({ leadId })
        }
    }

    handleReset = () => {
        //reset local error and msg + dispatch form clear function
        this.setState({ errors: [] }, () => {
            this.props.resetForm({ formName: 'onboard' });
        })
    }


    submit = (values) => {
        const leadId = this.props.match.params.leadId;
        const userId = this.props.match.params.userId;

        this.creditReport.makeCallToFetchCreditReport({
            firstName: values.firstname,
            lastName: values.lastname,
            mobile: values.mobile,
            otp: values.otp,
            pan: values.pancard,
            creditReportState: this.state.meta,
            pullCreditReport: values.experianConsent,
            userId
        })
            .then(() => {
                //if successful push to onboarding page
                this.props.history.push(`/onboard/submit/${leadId}/${userId}`)
            })
    }

    handleOTPClick = () => {
        if (this.props.values) {
            this.service.sendOTP({
                mobile: this.props.values.mobile,
                type: 6,
            })
        }
    }

    render() {
        const {
            otpBtnClickFlag,
            sendOTPAlert,
            errors,
            isSubmitting
        } = this.state;

        const {
            values
        } = this.props

        return (
            <div>
                <CustomerOnboardForm
                    onSubmit={this.submit}
                    toggleOtpBtnClickFlag={this.service.toggleOtpBtnClickFlag}
                    otpBtnClickFlag={otpBtnClickFlag}
                    handleOTP={this.handleOTPClick}
                    sendOTPAlert={sendOTPAlert}
                    creditScoreFlag={true}
                    consent={values ? values.experianConsent : false}
                    handleReset={this.handleReset}
                    isSubmitting={isSubmitting}
                />
                {
                    errors && errors.length > 0 &&
                    <div className='membership-msg'>
                        {
                            errors.map((item, i) => {
                                return (
                                    <div className="alert alert-danger" role="alert" key={i}>
                                        {item}
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        values: getFormValues('onboard')(state),
    };
}

export default connect(mapStateToProps, {
    ...CustomerActions, resetForm
})(CreditScoreContainer);