import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from '../api/index'
import config from '../../src/config';
import Header from './Header';
import { findInObjectArray } from '../utils/utility'
import { Redirect } from 'react-router-dom'
import * as AppActions from '../actions/AppActions';
import { RiseLoader } from 'react-spinners';
import { ADDITIONAL_ROUTES } from '../constants/index'
const API_URL_LOGIN = '/user/login';

class AuthenticatedRoute extends Component {

  constructor(props) {
    super(props)
    this.state = {
      render: false,
    }
  }

  componentDidMount() {
    const path = encodeURIComponent(window.location.href);
    //checking for logged in user
    get(API_URL_LOGIN)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {

          //getting the menu items
          this.props.getMenu();

          const user = res.data.data.results
          //setting user in session storage
          sessionStorage.setItem('user', JSON.stringify(user));

          let storeIds = user.management.provider_roles.stores.store_ids

          // setting products list
          let productsList = user.management.provider_roles.products.products_data;
          // this.props.setProducts(productsList);
          sessionStorage.setItem('products',JSON.stringify(productsList))

          if (Array.isArray(storeIds) && storeIds.length > 0) {
            //if user has permission of atleast one store
            // finding the storeId object from session in new logged in user's store data
            const storeSetFlag = findInObjectArray(user.management.provider_roles.stores.stores_data, JSON.parse(window.sessionStorage.getItem('storeId')));
            // setting new storeId if storeId not found in session or storeSetFlag is falsy
            if (!sessionStorage.getItem('storeId') || !storeSetFlag) {
              sessionStorage.setItem('storeId', JSON.stringify(user.management.provider_roles.stores.stores_data[0].store_id));
            }
          }
          // // render the page
          this.setState({
            render: true,
          })
        }
        else {
          window.location.href = config.SSO_URI + `?service=admin&redirectUrl=${path}`;
        }
      })
  }

  render() {
    const {
      component: Component,
      menu,
      ...rest
    } = this.props

    const {
      render,
    } = this.state;

    if (menu.length !== 0 && (findInObjectArray(menu, rest.path, 'action') || findInObjectArray(ADDITIONAL_ROUTES, rest.path, 'action'))) {
      //if path accessed by user found in menu - go to that path -no action
    }
    else if (menu.length !== 0) { // else go to first item's path in the menu
      return (<Redirect to={menu[0].action} />)
    }

    return (
      <Route {...rest} render={matchProps => {
        return (
          render && menu.length !== 0 ?
            <div>
              <Header {...rest} />
              <Component {...matchProps} />
            </div> :
            <div>
              {render && <Header {...rest} />}
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40vh' }}>
                <RiseLoader
                  color={'dodgerblue'}
                  loading={true}
                  size={18}
                />
              </div>
            </div>
        )
      }} />
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: state.appConstants.menu,
  };
}

export default connect(mapStateToProps, { ...AppActions })(AuthenticatedRoute);