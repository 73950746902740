import {
  SEND_FORM_AUTOPOPULATE,
  RESET_MEMBERSHIP_FORM
} from './actionConstants'

export const sendFormAutoPopulate = (data) => {
  return {
    type: SEND_FORM_AUTOPOPULATE,
    payload: data
  }
}

export const resetForm = (data) => {
  return {
    type: RESET_MEMBERSHIP_FORM,
    payload: data
  }
}

