import React, { Component } from 'react';

class SelectComponent extends Component {
  render() {
    const {
      input,
      options = [],
      keys = ['id', 'text'],
      disabled = false,
      containerStyle={}
    } = this.props
    
    return (
      <div style={containerStyle}>
        <select className="form-control"
          {...input}
          disabled={disabled}
        >
          <option hidden value="">Choose Here</option>
          {options.map((item, i) =>
            <option key={i} value={item[keys[0]]}>{item[keys[1]]}</option>
          )}
        </select>
      </div>
    );
  }
}

export default SelectComponent;