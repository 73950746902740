
import { Link } from 'react-router-dom';
import { get, post } from '../api';
import { isEmpty } from '../utils/utility'

const EXPERIAN_API_URL = '/credit-report/doc';

export class CreditReportService {
  constructor(props) {
    this.component = props;
  }

  viewFullReport = (userId) => {
    get(EXPERIAN_API_URL, { userId }).then((res) => {
      if (res.status == 200) {
        const newWin = window.open();
        newWin.document.write(res.data);
      }
      else {
        this.component.setState({creditReportError: res.data.errors[0]})
      }
    })
  }

  //function to check the credit limit of the user

  getCreditLimit = ({
    userId
  }) => {
    return new Promise((resolve, reject) => {
      let url = '/third-party/loans/recommendations';
      let data = {
        userId
      }
      post(url, data)
        .then(res => {
          if (res.status == 200 || res.status == 201) {
            // console.log('Err', res)
            resolve(res);
          }
          else if (res.status >= 400 || res.status <= 500) {
            // console.log('Err', res)
            reject(res);
          }
        })
    })
  }

  //function to set the metadata from the response from pull credit report api

  setMetaData = (metadata) => {
    if (metadata !== undefined && !isEmpty(metadata)) {
      let stageOneId = null;
      let stageTwoId = null;
      let nextAction = '';

      if (metadata.stage_one_id) {
        stageOneId = metadata.stage_one_id;
      }
      else if (metadata.stgOneHitId) {
        stageOneId = metadata.stgOneHitId;
      }

      if (metadata.stage_two_id) {
        stageTwoId = metadata.stage_two_id;
      }
      else if (metadata.stgTwoHitId) {
        stageTwoId = metadata.stgTwoHitId;
      }

      nextAction = metadata.next_action ? metadata.next_action : '';

      return {
        extraParams: {
          stageOneId,
          stageTwoId,
        },
        nextAction
      }
    }
    else {
      return {
        extraParams: {},
        nextAction: ''
      }
    }
  }

  //function to fetch the credit report - self pull api

  makeCallToFetchCreditReport = ({
    creditReportState = {},
    mobile,
    otp = null,
    pan,
    firstName = null,
    lastName = null,
    userId,
    pullCreditReport
  }) => {
    return new Promise((resolve, reject) => {

      this.component.setState({ isSubmitting: true })

      let url = creditReportState.nextAction ? creditReportState.nextAction : '/credit-report/self-pull';
      let extraParams = creditReportState.extraParams;
      let data = {
        maskedDelivery: 1,
        mobile,
        otp,
        type: 6,
        pan,
        firstName,
        lastName,
        pullCreditReport,
        userId,
        // userId: 2521, //only applicable in dev environment
        ...extraParams
      };
      post(url, data)
        .then(res => {
          if (res.status == 200 || res.status == 201) {
            this.component.setState({ isSubmitting: false })
            const meta = {
              extraParams: {},
              nextAction: ''
            }
            this.component.setState({
              errors: [],
              meta
            })
            resolve(res);
          }
          else if (res.status >= 400 || res.status <= 500) {
            this.component.setState({ isSubmitting: false })
            const meta = this.setMetaData(res.data.metadata)
            this.component.setState({
              errors: res.data.errors,
              meta
            })
            reject(res);
          }
        })
    })
  }
}