import React from 'react';
import TimerDisplay from './TimerDisplay';


class CountTimer extends React.Component {
    constructor() {
        super();
        this.state = {
            sec: 30,
            min: 0
        }
    }

    componentDidMount() {
        this.count = setInterval(this._tick, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.count);
    }

    _resetTimer = () => {
        this.setState({
            sec: 30,
            min: 0
        })
    }

    _tick = () => {
        if (this.state.sec === 0 && this.state.min > 0) {
            this.setState(prevState => ({
                sec: prevState.sec + 59,
                flag: true,
                min: prevState.min - 1
            }));
        }

        else if (this.state.sec > 0 && this.state.min >= 0) {
            this.setState(prevState => ({
                sec: prevState.sec - 1,
                flag: true,
            }));
        }
        else if (this.state.sec === 0 && this.state.min === 0) {
            this.props.toggle();
            return;
        }

    }

    render() {
        const { sec, min } = this.state;

        return (
            <TimerDisplay
                min={min}
                sec={sec}
            />
        );

    }
}

export default CountTimer;