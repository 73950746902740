import Drawer from 'material-ui/Drawer';
import React, { Component } from 'react'
import MenuItem from 'material-ui/MenuItem';
import { Link, withRouter, Route } from 'react-router-dom';
import { isEmpty, findObjectInArray } from '../utils/utility'

export default class DrawerContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const user = window.sessionStorage.getItem('user') !== null ? JSON.parse(window.sessionStorage.getItem('user')) : {}
    const storeId = window.sessionStorage.getItem('storeId') ? JSON.parse(window.sessionStorage.getItem('storeId')) : null

    const {
      menu,
      setHeaderText
    } = this.props;

    const selectedStore = !isEmpty(user) ? findObjectInArray(user.management.provider_roles.stores.stores_data, storeId) : null

    return (
      <div>
        <Drawer
          docked={false}
          width={200}
          open={this.props.open}
          onRequestChange={this.props.handleToggle}
        >
          <h6 style={{
            textAlign: 'center',
            marginTop: '15px'
          }}>
            {
              selectedStore
                ? `${selectedStore.name}(DC-${selectedStore.store_code})`
                : user.name
            }
          </h6>
          {
            menu && menu.map((item, i) => {
              return (
                <Link key={item.key} style={{ textDecoration: 'none' }} to={item.action} onClick={setHeaderText.bind(null,item.name)}>
                  <MenuItem onClick={this.props.handleToggle}>
                    {item.name}
                  </MenuItem>
                </Link>
              )
            })
          }
          {isEmpty(user) ?
            null : <MenuItem onClick={this.props.handleClose}>Logout</MenuItem>}
        </Drawer>
      </div>
    )
  }
}
