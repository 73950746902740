import React, { Component } from 'react'
import TextBoxWithButton from '../components/TextBoxWithButton';
import ReduxTextField from '../ReduxFormFields/ReduxTextField';
import CustomButton from '../components/Button';
import { reduxForm } from 'redux-form'
import { BLUE, RED } from '../constants';
import Paper from 'material-ui/Paper';

const style = {
  // height: '100%',
  width: '100%',
  margin: 20,
  textAlign: 'center',
  display: 'inline-block',
  padding: '10px'
  // justifyContent:'space-around'
};

class TransactionRow extends Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }


  render() {
    const {
      handleSubmit,
      pristine, reset, submitting,
      item,
      errors
    } = this.props

    // console.log('err', errors)
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <div className='outer-container item-center-align j-around hidden-mobile'>
            <div style={{width:'12%'}}>
              {item.created_at}
            </div>
            <div style={{width:'8%'}}>
              {item.account_no}
            </div>
            <div style={{width:'20%'}}>
              {item.name}
            </div>
            <div style={{width:'5%'}}>
              {item.amount_formatted}
            </div>
            <div className='inner-container' style={{width:'20%'}}>
              <ReduxTextField
                name={`${item.id}-amount`}
                textLabel='Invoice Amount'
                type='number'
                textDescription={errors ? errors[`${item.id}`][0] : null}
              />
            </div>
            <div>
              <CustomButton
                label='Approve'
                fullWidth={true}
                buttonStyle={{ backgroundColor: BLUE }}
                disabled={submitting}
              />
            </div>
            <div>
              <CustomButton
                label='Reject'
                fullWidth={true}
                buttonStyle={{ backgroundColor: RED }}
                onClick={this.props.rejectClick}
                type="button"
                disabled={submitting}
              />
            </div>
          </div>
          <div className='outer-container item-center-align j-around hidden-desktop'>
            <Paper style={style} zDepth={2}>
              <div className='flex-horizontal j-between'>
                <div>Metro Card Number</div>
                <div>{item.account_no}</div>
              </div>
              <div className='flex-horizontal j-between'>
                <div>Customer Name</div>
                <div className='info-div'>{item.name}</div>
              </div>
              <div className='flex-horizontal j-between'>
                <div>Transaction Initiated On</div>
                <div className='info-div'>{item.created_at}</div>
              </div>
              <hr />
              <div className='flex-horizontal j-between'>
                <div className='flex-vertical box-2'>
                  <h6> &#8377; {item.amount_formatted}</h6>
                  <h6>Initiated Amount</h6>
                  {errors ? <p style={{
                    fontSize: '11px',
                    color: 'red',
                  }}>{errors[`${item.id}`][0]}</p> : null}
                </div>
                <div className='inner-container width-45'>
                  <ReduxTextField
                    name={`${item.id}-amount`}
                    textLabel='Amount'
                    type='tel'
                    textDescription='Invoice Amount'
                  />
                </div>
              </div>
              <div className='flex-horizontal j-around'>
                <div>
                  <CustomButton
                    label='Approve'
                    fullWidth={true}
                    buttonStyle={{ backgroundColor: BLUE }}
                    disabled={submitting}
                  />
                </div>
                <div>
                  <CustomButton
                    label='Reject'
                    fullWidth={true}
                    buttonStyle={{ backgroundColor: RED }}
                    onClick={this.props.rejectClick}
                    type="button"
                    disabled={submitting}
                  />
                </div>
              </div>
            </Paper>
          </div>
        </form>
      </div>
    )
  }
}

const FormFunc = reduxForm({
  form: 'metro',
  enableReinitialize: true,
});

TransactionRow = FormFunc(TransactionRow);

export default TransactionRow;

