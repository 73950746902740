import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class SuccessPage extends Component {
    render() {
        return (
            <div className='textbox' style={{marginTop:'150px'}}>
                <img src='https://res.cloudinary.com/chqbook/image/upload/v1560865292/icons/correct.png' style={{width:'150px'}}/>
                <div className='inner-container'>
                <h6>Congratulations! Your Application has been successfully submitted.</h6>
                <h6>LeadId: {this.props.match.params.leadId}</h6>
                <p><Link style={{ textDecoration: 'none' }} to='/leads'>Click here</Link> to continue</p>
               </div>
            </div>
        );
    }
}

export default SuccessPage;