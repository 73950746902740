import initialState from './initialState';
import {
  SEARCH_LOANS,
  SEARCH_LOANS_SUCCESS,
  SEARCH_LOANS_FAILURE,

  SEND_FORM_VALUES,
  SEND_FORM_VALUES_SUCCESS,
  SEND_FORM_VALUES_FAILURE,

  CREDIT_TRANSACTION_INITIATE,
  DEBIT_TRANSACTION_INITIATE,

  RESET_LOAN_DETAILS
} from '../actions/actionConstants'


export default function EditLoansReducer(state = initialState.loans, action) {

  switch (action.type) {

    case SEARCH_LOANS:
      return Object.assign({}, state, { details: null },{ isLoaded: false }, { error: null }, { formError: null }, { msg: null })

    case SEARCH_LOANS_SUCCESS:
      return Object.assign({}, state, { isLoaded: true }, { details: action.data }, { error: null })

    case SEARCH_LOANS_FAILURE:
      return Object.assign({}, state, { isLoaded: true }, { error: action.error })

    case SEND_FORM_VALUES:
      return Object.assign({}, state, { isLoaded: false }, { formError: null }, { msg: null })

    case CREDIT_TRANSACTION_INITIATE:
      return Object.assign({}, state, { isLoaded: false }, { formError: null }, { msg: null })

    case DEBIT_TRANSACTION_INITIATE:
      return Object.assign({}, state, { isLoaded: false }, { formError: null }, { msg: null })

    case SEND_FORM_VALUES_SUCCESS:
      return Object.assign({}, state, { isLoaded: true }, { msg: action.data }, { formError: null })

    case SEND_FORM_VALUES_FAILURE:
      return Object.assign({}, state, { isLoaded: true }, { formError: action.error }, { msg: null })

    case RESET_LOAN_DETAILS:
    return Object.assign({}, state, { details: null })

    default:
      return state;
  }
}

