import React, { Component } from 'react';
import Paper from 'material-ui/Paper';
import { isEmpty } from '../utils/utility'
const style = {
    // height: '100%',
    width: '100%',
    // margin: 20,
    textAlign: 'center',
    display: 'inline-block',
    padding: '10px',
    cursor: 'pointer'
    // justifyContent:'space-around'
};
class DetailsCard extends Component {
    render() {
        const {
            item = {},
            keys = [],
            headers = [],
            clickHandle = () => { },
            hide
        } = this.props;

        return (
            !isEmpty(item) &&
            <Paper style={style} zDepth={1} onClick={() => clickHandle()}>
                {
                    keys.map((val, i) => {
                        return (
                            <div className='flex-horizontal font-normal item-center-align' key={i}>
                                {
                                    item[val] !== '0' && !hide[i] && <div><b>{headers[i]}: </b> &nbsp;&nbsp;</div>
                                }
                                                               
                            {
                                    val == 'pre_approved_amount_formatted'
                                        ? item[val] != '0' && !hide[i] && <div className='tick'>{item[val]}&#x2713;</div>
                                        : item[val] !== '0' && !hide[i] && <div>{item[val]}</div>
                                }
                            </div>
                        );
                    })
                }
            </Paper>
        );
    }
}

export default DetailsCard;