import React, { Component } from 'react'
import ReduxTextField from '../ReduxFormFields/ReduxTextField';
import CustomButton from './Button';

export default class TextBoxWithButton extends Component {
  render() {
    let {
      textBoxName = 'name',
      textLabel = 'label',
      textDescription = 'description',
      buttonLabel = '',
      wrapperClass = '',
      textBoxType = 'text',
      textStyle = {},
      ...others
    } = this.props;
    // console.log('others', others)
    return (
      <React.Fragment>
        <div className='width-65'>
          <ReduxTextField
            name={textBoxName}
            textLabel={textLabel}
            textDescription={textDescription}
            fullWidth={true}
            type={textBoxType}
            style={textStyle}
          />
        </div>
        <div style={{ width: '15px' }}></div>
        <div className='width-35'>
          <CustomButton
            label={buttonLabel}
            {...others}
            fullWidth={true}
            labelStyle={{
              paddingLeft:'0px',
              paddingRight: '0px',
        }}
      />
        </div>
      </React.Fragment>
    )
  }
}
