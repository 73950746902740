import initialState from './initialState';
import {
  FETCH_LOANS,
  FETCH_LOANS_SUCCESS,
  FETCH_LOANS_FAILURE,


  FETCH_DETAIL,
  FETCH_DETAIL_SUCCESS,
  FETCH_DETAIL_FAILURE,

  INITIATE_TRANSACTION,
  INITIATE_TRANSACTION_SUCCESS,
  INITIATE_TRANSACTION_FAILURE,

  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,

  RESET_DETAILS
} from '../actions/actionConstants'


export default function TransactionReducer(state = initialState.transaction, action) {

  switch (action.type) {

    case FETCH_LOANS:
      return Object.assign({}, state, { loanDetails:[] }, { isLoaded: false }, { error: null }, { InitiateError: null }, { otpError: null }, { transactionMsg: null }, { otpVerificationMsg: null })

    case FETCH_LOANS_SUCCESS:
      return Object.assign({}, state, { isLoaded: true }, { loanDetails: action.data })

    case FETCH_LOANS_FAILURE:
      return Object.assign({}, state, { isLoaded: true }, { error: action.error })


    case FETCH_DETAIL:
      return Object.assign({}, state, { isLoaded: false }, { error: null }, { InitiateError: null }, { otpError: null }, { transactionMsg: null }, { otpVerificationMsg: null })

    case FETCH_DETAIL_SUCCESS:
      return Object.assign({}, state, { isLoaded: true }, { info: action.data })

    case FETCH_DETAIL_FAILURE:
      return Object.assign({}, state, { isLoaded: true }, { error: action.error })

    // Transactions
    case INITIATE_TRANSACTION:
      return Object.assign({}, state, { isLoaded: false }, { InitiateError: null }, { transactionMsg: null })

    case INITIATE_TRANSACTION_SUCCESS:
      return Object.assign({}, state, { isLoaded: true }, { transactionData: action.data }, { transactionMsg: action.msg }, { otpError: action.error }, { otpVerificationMsg: null })

    case INITIATE_TRANSACTION_FAILURE:
      return Object.assign({}, state, { isLoaded: true }, { InitiateError: action.error }, { otpError: null }, { otpVerificationMsg: null })

    // OTP Verification
    case VERIFY_OTP:
      return Object.assign({}, state, { isLoaded: false }, { otpError: null }, { otpVerificationMsg: null })

    case VERIFY_OTP_SUCCESS:
      return Object.assign({}, state, { isLoaded: true }, { otpVerificationMsg: action.msg }, { InitiateError: null }, { transactionMsg: null })

    case VERIFY_OTP_FAILURE:
      return Object.assign({}, state, { isLoaded: true }, { otpError: action.error })

    case RESET_DETAILS:
      return Object.assign({}, state, { info: null },{ loanDetails:[] }, { error: null }, { InitiateError: null }, { otpError: null }, { transactionMsg: null }, { otpVerificationMsg: null })

    default:
      return state;
  }
}

