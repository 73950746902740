import React, { Component } from 'react';

class Image extends React.PureComponent {
    render() {
        const {
            src = null
        } = this.props;
        return (
            <div className='img-container fluid-width-img padding-side'>
                {
                    src &&
                    <img src={src} width={'100%'} />
                }
            </div>
        );
    }
}

export default Image;