import config from '../config';
import { getcbMetroCardInfo, fieldSanitize } from './utility';

export const getCommonAPIfields = (data) => {
  const {
    email = null,
    firstname = null,
    middlename = null,
    lastname = null,
    mobile = null,
    dobDate = null,
    dobMonth = null,
    dobYear = null,
    empType = null,
    pancard = null,
    gender = null,
    pincode = null,
    nomineeName = null,
    nominee = null,
  } = data;

  return {
    firstName: firstname,
    // middleName: middlename,
    lastName: lastname,
    email,
    mobile,
    dob:
      dobDate && dobMonth && dobYear
        ? `${dobYear}-${dobMonth}-${dobDate}`
        : null,
    gender,
    // empType,
    pan: pancard,
    pincode: pincode.pincode,
    cityName: pincode.city_name,
    nomineeData: {
      nomineeName,
      nomineeRelationType: nominee,
    },
  };
};

export const getMembershipPayAndSavefields = (data) => {
  const {
    address,
    pincode,
    eliteMetroNumber,
    metroCardNumber,
    metroHolderNumber,
    businessName,
    gstNumber,
    // nomineeFirstname,
    // nomineeLastname,
    // nomineeRelationType,
    planId,
    otp,
  } = data;

  const cbMetroCardInfo = getcbMetroCardInfo({
    metroHolderNumber,
    metroCardNumber,
  });

  return {
    ...getCommonAPIfields(data),
    qcCardNumber: fieldSanitize(eliteMetroNumber),
    cbMetroCardNo: cbMetroCardInfo.cbMetroCardNo,
    metroCardNo: fieldSanitize(metroCardNumber),
    // companyName: businessName,
    // address1: address,
    // pincode,
    // nomineeData: {
    //   nomineeFirstName: nomineeFirstname,
    //   nomineeLastName: nomineeLastname,
    //   nomineeRelationType,
    //   nomineeMiddleName: null,
    //   nomineeDob: null,
    // },
    // gstNum: gstNumber,
    planId: config.PLAN_ID,
    otp,
  };
};
