import React, { Component } from 'react';
import { CustomFilePond } from 'chqbook-ui-components/dist'
import TableComponent from '../components/TableComponent';
import DownloadButton from '../components/DownloadButton';
import { UtilityService } from '../Services';
import config from '../../src/config';
import 'chqbook-ui-components/dist/index.css';
import { post } from '../api'
import { API_URL_DOWNLOAD_APPROVAL_VIA_UPLOAD_SAMPLE_FILE } from '../constants/apiUrls'

class UploadContainer extends Component {
  service = new UtilityService(this);

  constructor(props) {
    super(props);
    this.state = {
      errors: []
    };
  }

  setError = (e) => {
    this.setState({ errors: e })
  }

  doDownload = () => {
    post(API_URL_DOWNLOAD_APPROVAL_VIA_UPLOAD_SAMPLE_FILE)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          this.service.downloadSampleFile({ content: res.data, filename: 'sample_file' });
        }
      })
  }

  onSuccessfulUpload = (obj, res) => {
    this.service.downloadSampleFile({ content: res });
  }

  render() {
    const {
      errors
    } = this.state;

    return (
      <div style={{ padding: '20px' }}>
        <h6>
          * Kindly upload the file in .xslx(Excel) format
            <br />
        </h6>
        <CustomFilePond
          url={config.API_BASE + '/third-party/loans/approve-tx-via-upload'}
          acceptedFileTypes={null}
          fileValidateTypeLabelExpectedTypesMap={null}
          fileValidateTypeDefaultMsg='Please select a file with valid extension'
          onSuccessUpload={this.onSuccessfulUpload}
        />
        <DownloadButton
          handleClick={this.doDownload}
        />
        {
          errors.length > 0
            ? typeof errors[0] == 'object'
              ? <div style={{ marginTop: '-25px' }}>
                <TableComponent
                  details={errors}
                  keys={['type', 'value']}
                  headers={['Error type', 'Value']}
                />
              </div>
              : <div className="alert alert-danger" style={{ margin: '20px' }}>{errors[0]}</div>
            : null
        }
      </div>
    );
  }
}

export default UploadContainer;