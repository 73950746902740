import React, { Component } from 'react';
import ReactModal from 'react-modal';


const customStyles = {
  overlay : {
    backgroundColor: 'rgba(0, 0, 0, 0.7)'
  }
};

class Modal extends Component {

  render() {
    const {
      showModal,
      handleCloseModal,
      children
    } = this.props;

    return (
      <div>
        <ReactModal
          isOpen={showModal}
          contentLabel="Transactions Approval"
          onRequestClose={handleCloseModal}
          shouldCloseOnOverlayClick={true}
          ariaHideApp={false}
          style={customStyles}
        >
          {children}
        </ReactModal>
      </div>
    );
  }
}

export default Modal;