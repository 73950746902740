import { take, put, fork, call, select } from 'redux-saga/effects';
import { change, reset } from 'redux-form';
import {
  SEND_FORM_AUTOPOPULATE,
  RESET_MEMBERSHIP_FORM
} from '../actions/actionConstants';
import { isEmpty } from '../utils/utility';

// This is a utility function to change the form field values
function* changeFormField(formName, fields) {
  const keys = Object.keys(fields);
  if (!isEmpty(keys)) {
    try {
      // Iterate over keys list and populate form based on key passed
      // do not use forEach loop to dispatch change actions in redux-form 
      for (let i = 0; i < keys.length; i++) {
        yield put(change(formName, keys[i], fields[keys[i]]))
      }
    } catch (error) {
      console.log("Error", error);
    }
  }
}

function* getMembershipInfo() {
  while (true) {
    const request = yield take(SEND_FORM_AUTOPOPULATE);
    const { payload } = request;
    yield call(changeFormField, 'membership', payload)
  }
}

function* resetMembershipForm() {
  while (true) {
   const request = yield take(RESET_MEMBERSHIP_FORM);
   const { payload } = request;
   try{
     yield put(reset(payload.formName));
   }
   catch(err){
     console.log('Something went wrong', err)
   }
  }
}


export default function* () {
  yield fork(getMembershipInfo);
  yield fork(resetMembershipForm);
}
