const DEV = {
  API_BASE: 'https://partner.uat.chqbook.com/api',
  SSO_URI: 'https://accounts.uat.chqbook.com/signin/user/v1',
  SITE_URL: 'https://uat.chqbook.com',
  BASE_URI: 'https://partner.uat.chqbook.com',
  PLAN_ID: 26,
};

const STAGING = {
  API_BASE: 'https://partner.staging.chqbook.com/api',
  SSO_URI: 'https://accounts.staging.chqbook.com/signin/user/v1',
  SITE_URL: 'https://staging.chqbook.com',
  BASE_URI: 'https://partner.staging.chqbook.com',
  PLAN_ID: 26,
};

const UAT = {
  API_BASE: 'https://partner.uat.chqbook.com/api',
  SSO_URI: 'https://accounts.uat.chqbook.com/signin/user/v1',
  SITE_URL: 'https://uat.chqbook.com',
  BASE_URI: 'https://partner.uat.chqbook.com',
  PLAN_ID: 26,
};

const PROD = {
  API_BASE: 'https://partner.chqbook.com/api',
  SSO_URI: 'https://accounts.chqbook.com/signin/user/v1',
  SITE_URL: 'https://www.chqbook.com',
  BASE_URI: 'https://partner.chqbook.com',
  PLAN_ID: 2,
};

let config = {};

switch (process.env.REACT_APP_ENV) {
  case 'staging': {
    config = STAGING;
    break;
  }
  case 'uat': {
    config = UAT;
    break;
  }
  case 'production': {
    config = PROD;
    break;
  }
  default:
    config = DEV;
    break;
}

export default {
  // Add common config values here
  ...config,
};
