import { take, put, fork, call, select } from 'redux-saga/effects';
import {
  SEARCH_CUSTOMER,
  SEARCH_CUSTOMER_ACCOUNT,
  SEARCH_CUSTOMER_SUCCESS,
  SEARCH_CUSTOMER_FAILURE,
  GET_HISTORY
} from '../actions/actionConstants';

import { postAPI, changeFormField } from './EditSaga'
import { get } from '../api';

const getCustomerInfo = (state) => state.customers;

export function* getAPI({
  append = '',
  payload = {},
  url = '',
  failAction = '',
  successAction = '',
  key = 'results',
  successCB = () => { console.log('Success') }, //this is the callback function which is called in case of success status
  failCB = () => { console.log('Fail') } //this is the callback function which is called in case of failure status
}) {
  let finalUrl = append ? url+'/'+append : url;
  const fetchDataResponse = yield call(
    get,
    finalUrl, payload);
  if (fetchDataResponse.status >= 400 || fetchDataResponse.status >= 401) {
    yield put({
      type: failAction,
      error: fetchDataResponse.data.errors,
    });
    // console.log('error detail: ', fetchDataResponse);
    yield call(failCB);
  }
  else if (fetchDataResponse.status === 200 || fetchDataResponse.status === 201) {
    // debugger;
    yield put({
      type: successAction,
      data: fetchDataResponse.data.results[key],
    });
    // console.log('resp detail: ', fetchDataResponse)
    yield call(successCB);
  }
}

function* setFormFields() {
  let customer = yield select(getCustomerInfo); // getCustomerInfo from redux store
  const fields = [
    { name: 'lender', value: customer.info.instrument_id },
    { name: 'requiredLimit', value: customer.info.loan_amount	},
    { name: 'companyName', value: customer.info.company_name },
    { name: 'pancard', value: customer.info.pan },
    { name: 'mobile', value: customer.info.user_mobile },
    { name: 'firstname', value: customer.info.first_name },
    { name: 'lastname', value: customer.info.last_name },
    { name: 'metroCardNo', value: customer.info.metro_card_no },
  ]
  yield call(changeFormField, 'onboard', fields);
}

function* checkIfDataFound(history) {
    let customer = yield select(getCustomerInfo); // getCustomerInfo from redux store
    if (customer.info.length === 0) {
      history.obj.push('/onboard/edit');
    }
}

function* getCustomerAPI({
  successCB = () => { },
  payload = {}
}) {
  yield call(postAPI, {
    payload,
    url: '/third-party/loans/user/search',
    failAction: SEARCH_CUSTOMER_FAILURE,
    successAction: SEARCH_CUSTOMER_SUCCESS,
    successCB: successCB

  })
}

function* getCustomerDetailAPI({
  successCB = () => { },
  payload = {}
}) {
  yield call(getAPI, {
    payload:{},
    append: payload.leadId,
    url: '/lead/details',
    failAction: SEARCH_CUSTOMER_FAILURE,
    successAction: SEARCH_CUSTOMER_SUCCESS,
    successCB: successCB,
    key:'lead_data'
  })
}

function* getCustomers() {
  while (true) {
    const request = yield take(SEARCH_CUSTOMER);
    const history = yield take(GET_HISTORY);
    const { payload } = request;
    const onSuccess = checkIfDataFound.bind(null, history)  //creating the function reference and binding with parameters
    yield call(getCustomerAPI, { successCB: onSuccess, payload });
  }
}


function* getCustomerAccount() {
  while (true) {
    const request = yield take(SEARCH_CUSTOMER_ACCOUNT);
    const { payload } = request;
    yield call(getCustomerDetailAPI, { successCB: setFormFields, payload });
  }
}


export default function* () {
  yield fork(getCustomers);
  yield fork(getCustomerAccount);
}
