import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import { Provider } from 'react-redux';
import configureStore, { history } from './store/configureStore';

const { store } = configureStore();

const main = (
  <Provider store={store} history={history}>
    <App />
  </Provider>
);

ReactDOM.render(main, document.getElementById('root'));

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    ReactDOM.render(
      <Provider store={store} history={history}>
        <NextApp />
      </Provider>
      ,
      document.getElementById('root')
    );
  });
}

unregister();
