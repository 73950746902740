import React, { Component } from 'react';
import { findInObjectArray } from '../utils/utility'
import { CustomFilePond } from 'chqbook-ui-components/dist'
import 'chqbook-ui-components/dist/index.css';
import config from '../../src/config';

const API_URL_UPLOAD = '/user/doc/admin-upload';
const OTHER_DOC = 'other_doc'

class PendingDocuments extends Component {

    render() {
        const {
            requiredDocs,
            leadDocs,
            info,
            setFreshUploadedDoc = () => { },
            removeUpdateError = () => { },
            updateDocErrors = {},
            multiDocs
        } = this.props;

        return (
            <div>
                {
                    !(info instanceof Array) &&
                    Object.keys(requiredDocs).map((val, outerIndex) => {
                        return (
                            <div key={outerIndex}>
                                <h4 style={{ textDecoration: 'underline', textAlign: 'center' }}>{val}</h4>
                                {
                                    requiredDocs[val].map((item, index) => {
                                        return <div key={index}>
                                            {
                                                findInObjectArray(leadDocs.length !== 0 ? leadDocs[0].lead_docs : [], item.file_name, 'pretty_name') && !multiDocs.hasOwnProperty(item.file_name)
                                                    ? null
                                                    : multiDocs.hasOwnProperty(item.file_name)
                                                        ? <div>
                                                            <CustomFilePond
                                                                documentName={item.user_text}
                                                                url={config.API_BASE + API_URL_UPLOAD}
                                                                metadata={{
                                                                    docType: item.id,
                                                                    userId: info.user_id
                                                                }}
                                                                setResponseMeta={setFreshUploadedDoc}
                                                                prettyName={item.file_name}
                                                                setError={removeUpdateError}
                                                                multiple={true}
                                                                allowMultiple={true}
                                                                allowReplace={false}
                                                                maxFiles={50}
                                                            />
                                                            {
                                                                updateDocErrors[item.file_name] &&
                                                                <div className="alert alert-danger" style={{ bottom: '15px', borderColor: 'red' }} role="alert">
                                                                    {'Failed to update file: ' + updateDocErrors[item.file_name]}
                                                                </div>
                                                            }
                                                        </div>
                                                        : <div>
                                                            <CustomFilePond
                                                                documentName={item.user_text}
                                                                url={config.API_BASE + API_URL_UPLOAD}
                                                                metadata={{
                                                                    docType: item.id,
                                                                    userId: info.user_id
                                                                }}
                                                                setResponseMeta={setFreshUploadedDoc}
                                                                prettyName={item.file_name}
                                                                setError={removeUpdateError}
                                                            />
                                                            {
                                                                updateDocErrors[item.file_name] &&
                                                                <div className="alert alert-danger" style={{ bottom: '15px', borderColor: 'red' }} role="alert">
                                                                    {'Failed to update file: ' + updateDocErrors[item.file_name]}
                                                                </div>
                                                            }
                                                        </div>
                                            }
                                        </div>
                                    })
                                }
                                <br />
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}

export default PendingDocuments;