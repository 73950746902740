import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import { debounce } from '../utils/utility'

class CustomAsyncSelect extends Component {

    _onChange = (value) => {
        const {
            input,
            onSelectOption = () => { }
        } = this.props;

        input.onChange(value)
        onSelectOption(value); // custom function to be triggered from props; if required
    }

    render() {
        const {
            cacheOptions = true,
            keys = {
                id: 'id',
                name: 'name'
            },
            defaultOptions = false,
            loadOptions = () => { },
            delay = 0,
        } = this.props;

        return (
            <>
                <AsyncSelect
                    cacheOptions={cacheOptions}
                    loadOptions={debounce(loadOptions, delay)}
                    defaultOptions={defaultOptions}
                    getOptionLabel={option => option[keys.name]}
                    getOptionValue={option => option[keys.id]}
                    onChange={this._onChange}
                />
            </>
        );
    }
}

export default CustomAsyncSelect;