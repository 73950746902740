import React, { Component } from 'react';
import { reduxForm } from 'redux-form'
import ReduxTextField from '../ReduxFormFields/ReduxTextField';
import { Field } from 'redux-form';
import CustomButton from './Button';
import {
  required,
  mobile,
  pancard,
  name,
  minLength3,
  maxLength15,
} from '../utils/fieldValidate';

import {
  lessThanEqual,
  normalizeName,
  normalizeMetroNumber
} from '../utils/normalizerUtility';

import CountTimer from './CountTimer';
import ReduxSelectField from '../ReduxFormFields/ReduxSelectField';

const height = '45px';
const showMoreContent = <span>You hereby unconditionally consent to such credit information being provided by Experian at your registered email id and also through your Chqbook account as per your independent registration with Chqbook subject to Experian <a target='_blank' href="https://www.chqbook.com/terms">T&C</a>.</span>
const showMoreFixedContent = ' You hereby appoint Chqbook as your authorised representative to receive your credit information from Experian.'

class CustomerOnboardForm extends Component {
  constructor(props) {
    super(props);
    this.state={
      showMoreFlag: false,
      showMoreLabel:'Show More...',
      showMoreText:''
    }
  }

  handleShowMore = (e) => {
    const{
      showMoreFlag
    } = this.state;

    if(!showMoreFlag){
      this.setState({
        showMoreFlag: true,
        showMoreLabel:'Show Less',
        showMoreText: showMoreContent
      })
    }
    else{
      this.setState({
        showMoreFlag: false,
        showMoreLabel:'Show More...',
        showMoreText: ''
      })
    }
    e.preventDefault()
    // e.stopPropagation()
  }

  render() {
    const {
      pristine,
      handleSubmit,
      toggleOtpBtnClickFlag,
      otpBtnClickFlag,
      handleOTP,
      sendOTPAlert,
      creditScoreFlag,
      lenderOptions = [],
      lenderKeys,
      consent,
      isSubmitting,
      handleReset = () => { },
      showFormBtns = true,
      disableFields = false,
      hideForm = false,
      handleChange = () => { },
      otpFlag = '',
      mobileFlag = false
    } = this.props;

    return (
      <React.Fragment>
        <form onSubmit={handleSubmit}>
          <div className='flex-vertical inner-container'>
            {
              !hideForm &&
              <div>
                {
                  !creditScoreFlag &&
                  <ReduxSelectField
                    label='Lender'
                    validate={[required]}
                    name='lender'
                    options={lenderOptions}
                    keys={lenderKeys}
                    disabled={disableFields}
                    onChange={handleChange}
                  />
                }
                <ReduxTextField
                  textLabel='Business Name'
                  style={{ height }}
                  name='companyName'
                  normalize={normalizeName}
                  validate={[required]}
                  maxLength='255'
                  disabled={disableFields}
                />
                <ReduxTextField
                  textLabel='First Name'
                  style={{ height }}
                  name='firstname'
                  normalize={normalizeName}
                  validate={[required, name, minLength3]}
                  maxLength='15'
                  disabled={disableFields}
                />
                <ReduxTextField
                  textLabel='Last Name'
                  style={{ height }}
                  name='lastname'
                  normalize={normalizeName}
                  validate={[required, name, maxLength15]}
                  maxLength='15'
                  disabled={disableFields}
                />
                <ReduxTextField
                  textLabel='Pancard'
                  style={{ height }}
                  name='pancard'
                  validate={[required, pancard]}
                  maxLength='10'
                  disabled={disableFields}
                />
                {
                  !creditScoreFlag &&
                  <div>
                    <ReduxTextField
                      textLabel='10 Digit Metro Card No.'
                      style={{ height }}
                      name='metroCardNo'
                      validate={[required]}
                      normalize={normalizeMetroNumber}
                      type='tel'
                      disabled={disableFields}
                    />
                    <ReduxTextField
                      textLabel='Required Limit'
                      style={{ height }}
                      name='requiredLimit'
                      validate={[required]}
                      type='tel'
                      disabled={disableFields}
                      onChange={handleChange}
                    />
                  </div>
                }
                <ReduxTextField
                  textLabel='Mobile'
                  style={{ height }}
                  name='mobile'
                  validate={[required, mobile]}
                  type='tel'
                  normalize={lessThanEqual(10)}
                  showBtn={true}
                  sideBtnContainerStyle={{ width: '70px', height: '65px' }}
                  sideBtnLabel={
                    otpBtnClickFlag
                      ? <CountTimer toggle={toggleOtpBtnClickFlag} />
                      : 'Send OTP'}
                  sideBtnlabelStyle={{ fontSize: '12px', top: '8px' }}
                  sideBtnCoreStyle={{ height: '44px' }}
                  sideBtnDisabled={otpBtnClickFlag || otpFlag.length !== 10 || mobileFlag}
                  onClick={handleOTP}
                  disabled={disableFields || mobileFlag}
                />
                {sendOTPAlert && <p className='form-success-text'>{sendOTPAlert}</p>}
                <ReduxTextField
                  textLabel='OTP'
                  style={{ height }}
                  name='otp'
                  validate={mobileFlag ? [] : [required]}
                  type='tel'
                  normalize={lessThanEqual(4)}
                  disabled={disableFields || mobileFlag}
                />
              </div>
            }
            {(!creditScoreFlag || hideForm) &&
              <div>
                {this.props.children}
                <br />
              </div>
            }
            {
              showFormBtns && !hideForm &&
              <div className='flex-vertical'>
              <div className='experian-consent'>
                <div>
                  <Field
                    style={{ transform: 'scale(1.4)', outline: 'none', cursor: 'pointer' }}
                    name="experianConsent"
                    id="experianConsent"
                    component="input"
                    type="checkbox"
                  />
                </div>
                &nbsp;&nbsp;
            <label htmlFor="experianConsent" className='font-normal'>
                  {showMoreFixedContent}{this.state.showMoreText}<span className='text-link' onClick={this.handleShowMore}>&nbsp;&nbsp;{this.state.showMoreLabel}</span>
            </label>
            </div>
            <div className='flex-horizontal exp-img-section'>
              <div className='flex-vertical experian-img-label'>
                <div>Credit Score</div>
                <div>Powered by</div>
              </div>
              <img
                src="https://res.cloudinary.com/chqbook/image/upload/q_auto:best/v1535356828/logos/experian.png"
                style={{
                  width: '100px',
                  height: '30px'
                }}
              />
            </div>
              </div>
            }
          </div>
          {
            showFormBtns &&
            <div className='flex-horizontal'>
              <div className='width-100 padding-side'>
                <CustomButton
                  label='Submit'
                  fullWidth={true}
                  // buttonStyle={{ backgroundColor: BLUE }}
                  disabled={(!consent && !hideForm) || pristine || isSubmitting}
                  isLoading={isSubmitting}
                />
              </div>
              <div className='width-100 padding-side'>
                <CustomButton
                  type='button'
                  label='Clear'
                  fullWidth={true}
                  // buttonStyle={{ backgroundColor: RED }}
                  disabled={isSubmitting}
                  onClick={handleReset}
                />
              </div>
            </div>
          }
          <br />
        </form>
      </React.Fragment>
    );
  }
}

const FormFunc = reduxForm({
  form: 'onboard',
  enableReinitialize: true,
  forceUnregisterOnUnmount: true, // this is to fix the sync error persist bug on field unmount in redux-form
});

CustomerOnboardForm = FormFunc(CustomerOnboardForm);

export default CustomerOnboardForm;