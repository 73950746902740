import {
  SET_NAME,
  FETCH_DETAIL,
  INITIATE_TRANSACTION,
  VERIFY_OTP,
  GET_MENU,
  RESET_DETAILS,
  GET_HISTORY,
  SET_PRODUCTS,
  FETCH_LOANS
} from './actionConstants'

export const setName = (name) => {
  return {
    type: SET_NAME,
    data: name
  }
}

export const fetchLoans = (data) => {
  return {
    type: FETCH_LOANS,
    payload: data
  }
}

export const fetchDetails = (data) => {
  return {
    type: FETCH_DETAIL,
    payload: data
  }
}

export const initiateTransaction = (data) => {
  return {
    type: INITIATE_TRANSACTION,
    payload: data
  }
}

export const verifyOTP = (data) => {
  return {
    type: VERIFY_OTP,
    payload: data
  }
}

export const getMenu = () => {
  return {
    type: GET_MENU
  }
}

export const resetDetails = () => {
  return {
    type: RESET_DETAILS
  }
}

export const getHistory = (obj) => {
  return {
    type: GET_HISTORY,
    obj
  }
}

export const setProducts = (data) => {
  return {
    type: SET_PRODUCTS,
    data
  }
}