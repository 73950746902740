import React, { Component } from 'react';
import Paper from 'material-ui/Paper';

class LeadDetails extends Component {
    render() {
        const {
            item,
            viewReport = () => { },
            errorReport = null
        } = this.props;

        return (
            <div>
                <div className='flex-horizontal font-normal'>
                    <div><b>Lead Id: </b></div>
                    &nbsp;&nbsp;
                    <div>{item.lead_id}</div>
                </div>
                <div className='flex-horizontal font-normal'>
                    <div><b>Business Name: </b></div>
                    &nbsp;&nbsp;
                    <div>{item.company_name}</div>
                </div>
                {
                    item.office_address &&
                    <div className='flex-horizontal font-normal'>
                        <div><b>Office Address: </b></div>
                        &nbsp;&nbsp;
                    <div>{item.office_address}</div>
                    </div>
                }
                {
                    item.instrument_name &&
                    <div className='flex-horizontal font-normal'>
                        <div><b>Instrument: </b></div>
                        &nbsp;&nbsp;
                    <div>{item.instrument_name}</div>
                    </div>
                }
                {
                    item.emp_type_text &&
                    <div className='flex-horizontal font-normal'>
                        <div><b>Borrower Type: </b></div>
                        &nbsp;&nbsp;
                    <div>{item.emp_type_text}</div>
                    </div>
                }
                {
                    item.business_vintage &&
                    <div className='flex-horizontal font-normal'>
                        <div><b>Business Vintage: </b></div>
                        &nbsp;&nbsp;
                    <div>{item.business_vintage}</div>
                    </div>
                }
                <div className='flex-horizontal font-normal'>
                    <div><b>Customer Name: </b></div>
                    &nbsp;&nbsp;
                    <div>{item.user_name}</div>
                </div>
                <div className='flex-horizontal font-normal'>
                    <div><b>Mobile: </b></div>
                    &nbsp;&nbsp;
                    <div>{item.user_mobile}</div>
                </div>
                {
                    item.user_dob &&
                    <div className='flex-horizontal font-normal'>
                        <div><b>Date Of Birth: </b></div>
                        &nbsp;&nbsp;
                    <div>{item.user_dob}</div>
                    </div>
                }
                {
                    item.address &&
                    <div className='flex-horizontal font-normal'>
                        <div><b>Address: </b></div>
                        &nbsp;&nbsp;
                    <div>{item.address}</div>
                    </div>
                }
                {
                    item.pan &&
                    <div className='flex-horizontal font-normal'>
                        <div><b>PAN: </b></div>
                        &nbsp;&nbsp;
                    <div>{item.pan}</div>
                    </div>
                }
                <div className='flex-horizontal font-normal'>
                    <div><b>Metro Card: </b></div>
                    &nbsp;&nbsp;
                    <div>{item.store_unique_id}</div>
                </div>
                <div className='flex-horizontal font-normal'>
                    <div><b>DC: </b></div>
                    &nbsp;&nbsp;
                    <div>{item.store_code}</div>
                </div>
                <div className='flex-horizontal font-normal'>
                    <div><b>Store: </b></div>
                    &nbsp;&nbsp;
                    <div>{item.store_name}</div>
                </div>
                <div className='flex-horizontal font-normal'>
                    <div><b>Created On: </b></div>
                    &nbsp;&nbsp;
                    <div>{item.created_at}</div>
                </div>
                <div className='flex-horizontal font-normal'>
                    <div><b>Status: </b></div>
                    &nbsp;&nbsp;
                    <div>{item.status_text}</div>
                </div>
                <div className='flex-horizontal font-normal'>
                    <div><b>Credit Score: </b></div>
                    &nbsp;&nbsp;
                    <div>{item.credit_score ? item.credit_score : 'Not Available'}</div>
                </div>
                {
                    item.credit_score &&
                    <div className='flex-horizontal font-normal'>
                        <div
                            onClick={viewReport}
                            style={{ textDecoration: 'underline', color: '#2196f3', cursor: 'pointer' }}
                        >View Full Report</div>
                        &nbsp;&nbsp;
                        {
                            errorReport &&
                            <p style={{ color: 'red' }}>{' -' + errorReport}</p>
                        }
                    </div>
                }

            </div>
        );
    }
}

export default LeadDetails;