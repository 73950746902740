import React, { PureComponent } from 'react';
import { RED } from '../constants/index';

class TimerDisplay extends PureComponent {

  render() {
    const { min, sec } = this.props;
    return (
      <div className='flex-horizontal' style={{ justifyContent: 'space-around' }}>
        <span>
          <p
          style={{ color: RED, fontSize:'16px' }}>
          {'0' + min}:{sec < 10 ? '0' + sec : sec}
          </p>
        </span>
      </div>
    );

  }
}

export default TimerDisplay;