import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as AppActions from '../actions/AppActions';
import { resetTransaction } from '../actions/ApprovalPageActions';
import '../App.css';
import FormContainer from '../components/FormContainer';
import { getFormValues } from 'redux-form';
import Dropdown from '../components/Dropdown';
import TableComponent from '../components/TableComponent';
import CustomTable from '../components/CustomTable';
import { GridLoader } from 'react-spinners';
import {
  findInObjectArray,
  findObjectInArray,
  isEmpty,
} from '../utils/utility';
import { ACCOUNT_PROVIDER } from '../constants';

// uncomment the user object when working in dev as currently local login is not supported
// const user = {
//   "id": 23057,
//   "name": "Nitesh Nitesh",
//   "middle_name": "",
//   "last_name": "Nitesh",
//   "email": "mahesh@test.com",
//   "mobile": "9158960318",
//   "dob": "1987-03-02",
//   "gender": 1,
//   "gross_monthly_income": 190000,
//   "address_line_1": "wrRAR SRAER SRAER",
//   "address_line_2": null,
//   "address_line_3": null,
//   "address_type": 0,
//   "pin_code": "122001",
//   "locality_id": 0,
//   "source": 2,
//   "city_id": 113,
//   "pan": "FVSPS6933A",
//   "aadhar": null,
//   "qualification_type": null,
//   "created_at": "14 Sep, 2018",
//   "is_verified": 1,
//   "last_autologin": null,
//   "last_login": "23 Nov, 2018",
//   "user_id": 23057,
//   "password": "$2y$10$AISXf92fl/3Bo.0CxfkDHOP/88D47dwMvi9WtMr5pvFbUaeQ/kPyy",
//   "emp_type": 1,
//   "company_name": null,
//   "membership_card_no": "1004130020354995",
//   "city_name": "Nashik",
//   "first_name": "Nitesh",
//   "pincode": "122001",
//   "credit_score": null,
//   "user_data": true,
//   "dob_formatted": "2 Mar, 1987",
//   "emp_type_text": "Salaried",
//   "gmi": 190000,
//   "gender_text": "Male",
//   "avatar": "",
//   "address": "wrRAR SRAER SRAER",
//   "expiry": "2018-12-23 16:51:46",
//   "management": {
//     "cb": [55, 56],
//     "provider_roles": {
//       "cities": {
//         "city_ids": [],
//         "permission_types": []
//       },
//       "agents": {
//         "agent_ids": [],
//         "permission_types": []
//       },
//       "providers": {
//         "provider_ids": [],
//         "permission_types": []
//       },
//       "localities": {
//         "locality_ids": [],
//         "permission_types": []
//       },
//       "stores": {
//         "store_ids": [
//           9
//         ],
//         "permission_types": 1,
//         "stores_data": [
//           {
//             "store_id": 9,
//             "store_code": "54",
//             "object_type": 11,
//             "name": "Metro Delhi - Ghaziabad",
//             "address": "Lal Kuan",
//             "city_id": 1,
//             "pin_code": "201009",
//             "status": 1,
//             "description": null,
//             "created_by": null,
//             "created_at": "2018-08-07 13:39:16"
//           },
//           {
//             "store_id": 24,
//             "store_code": "23",
//             "object_type": 11,
//             "name": "Metro Nasik",
//             "address": "Lal Kuan",
//             "city_id": 1,
//             "pin_code": "201009",
//             "status": 1,
//             "description": null,
//             "created_by": null,
//             "created_at": "2018-08-07 13:39:16"
//           }
//         ]
//       }
//     }
//   }
// }

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stores: [],
      selectedStore: 0,
      payload: {},
      clicked: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.loanDetails.length == 1) {
      return {
        payload: Object.assign(
          {},
          {
            loanId: props.loanDetails[0].id,
            userId: props.loanDetails[0].user_id,
            // accountProvider: props.loanDetails[0].account_provider,
          }
        ),
      };
    } else if (props.loanDetails.length > 1 && !state.clicked) {
      return { payload: {} };
    }

    // Return null if the state hasn't changed
    return null;
  }

  componentDidMount() {
    const selectedStore =
      window.sessionStorage.getItem('storeId') !== null
        ? JSON.parse(window.sessionStorage.getItem('storeId'))
        : 0;
    //comment the below line in case of working in dev
    const user =
      window.sessionStorage.getItem('user') !== null
        ? JSON.parse(window.sessionStorage.getItem('user'))
        : {};

    this.setState({
      stores: user.management.provider_roles.stores.stores_data,
      selectedStore,
    });
  }

  componentWillUnmount() {
    this.props.resetTransaction(); // resetting transactions
    this.props.resetDetails(); //resetting details
  }

  handleChange = (event, index, value) => {
    //[@TODO: StoreIds should be moved to redux store..currently its stored in local state and used globally using session storage]
    // console.log('store changed', value)
    this.setState({ selectedStore: value });
    sessionStorage.setItem('storeId', JSON.stringify(value));
    window.location.reload();
  };

  limitCheck = () => {
    const card = this.props.values ? this.props.values.card : null;
    this.props.fetchLoans({
      accountNo: card,
      accountProvider: ACCOUNT_PROVIDER,
      storeIds: this.state.selectedStore,
      status: 1,
    });
    this.setState({ payload: {}, clicked: false });
  };

  verifyAmount = () => {
    const { payload } = this.state;
    const card = this.props.values ? this.props.values.card : null;
    const amount = this.props.values ? this.props.values.amount : null;
    this.props.initiateTransaction({
      accountNo: card,
      accountProvider: 'METRO',
      amount,
      storeIds: this.state.selectedStore,
      ...payload,
    });
  };

  authorize = () => {
    const { payload } = this.state;

    const transactionId = this.props.transactionData
      ? this.props.transactionData.transaction_id
      : null;
    const otp = this.props.values ? this.props.values.otp : null;
    this.props.verifyOTP({
      transactionId,
      otp,
      storeIds: this.state.selectedStore,
      ...payload,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { payload } = this.state;

    if (payload.loanId && prevState.payload.loanId != payload.loanId) {
      const card = this.props.values ? this.props.values.card : null;
      this.props.fetchDetails({
        accountNo: card,
        accountProvider: ACCOUNT_PROVIDER,
        storeIds: this.state.selectedStore,
        loanId: payload.loanId,
      });
    }
  }

  setLoanId = (loanId) => {
    const { loanDetails } = this.props;
    try {
      let selectedObj = findObjectInArray(loanDetails, loanId, 'id');
      this.setState({
        payload: Object.assign({}, { loanId, userId: selectedObj.user_id }),
        clicked: true,
      });
    } catch (err) {
      console.log('Something went wrong', err);
    }
  };

  render() {
    const {
      loanDetails,
      info,
      isLoaded,
      error,
      InitiateError,
      otpError,
      otpVerificationMsg,
      transactionMsg,
      values,
      errorTxns,
      txnsIsLoaded,
    } = this.props;

    let errors = {
      error,
      InitiateError,
      otpError,
    };
    let msgs = {
      otpVerificationMsg,
      transactionMsg,
    };
    const { stores, selectedStore, payload } = this.state;
    // console.log('payload', payload)
    return (
      <div className="flex-vertical main">
        <div className="inner-container-dropdown">
          <Dropdown
            items={stores}
            value={selectedStore}
            floatingLabelText="Stores"
            onChange={this.handleChange}
          />
        </div>
        <br />
        <div>
          <FormContainer
            limitCheck={this.limitCheck}
            verifyAmount={this.verifyAmount}
            authorize={this.authorize}
            userInfo={info}
            errors={errors}
            msgs={msgs}
            checkLimitDisabled={!values || !values.card}
            show={loanDetails.length == 0 || !isEmpty(payload)}
          />
          {loanDetails.length > 1 && isEmpty(payload) && isLoaded && (
            <CustomTable
              details={loanDetails}
              headers={['ID', 'Name', 'Lender']}
              keys={['id', 'name', 'provider_name']}
              onRowClick={this.setLoanId}
            />
          )}
          {!isEmpty(payload) && !errorTxns && isLoaded && txnsIsLoaded && (
            <TableComponent
              tableName="Details of last 3 transactions :"
              details={this.props.txns ? this.props.txns : []}
              keys={['created_at', 'amount_formatted', 'pdc_date']}
              headers={['Transaction Date', 'Bill Value', 'PDC date']}
            />
          )}
          {errorTxns instanceof Array && txnsIsLoaded && (
            <div className="padding-side form-error-text">
              No Transactions found: {errorTxns[0]}
            </div>
          )}
        </div>
        <div className="flex-center" style={{ padding: '60px' }}>
          <GridLoader color={'dodgerblue'} loading={!isLoaded} size={10} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loanDetails: state.transaction.loanDetails,
    info: state.transaction.info,
    isLoaded: state.transaction.isLoaded,
    transactionData: state.transaction.transactionData,
    error: state.transaction.error,
    InitiateError: state.transaction.InitiateError,
    otpError: state.transaction.otpError,
    otpVerificationMsg: state.transaction.otpVerificationMsg,
    transactionMsg: state.transaction.transactionMsg,
    values: getFormValues('metro')(state),
    txns: state.approval.txns,
    errorTxns: state.approval.errorTxns,
    txnsIsLoaded: state.approval.isLoaded,
  };
}

export default connect(mapStateToProps, {
  ...AppActions,
  resetTransaction,
})(HomePage);
