export const lessThanEqual = maxCount =>
  (value, previousValue) => {
    // console.log('value.length', value.length);
    return value.length <= maxCount ? value : previousValue
  }

export const lessThanEqualWithOtherField = (otherField, maxCount) =>
  (value, previousValue, allValues) => {
    // console.log('---------otherField, maxCount: ', otherField, maxCount);
    let otherFieldValueLength = allValues[otherField].length;
    // console.log('otherFieldValueLength: ', otherFieldValueLength);
    if (value.length + otherFieldValueLength <= maxCount) {
      return value;
    }
    else {
      return previousValue;
    }
  }

export const removeSpaceFromFront = (value) => {
  let newValue = value && value == " "
    ? null
    : value;
  
  return newValue;
}

export const normalizeName = value => {
  // remove starting space
  let newValue = removeSpaceFromFront(value);
  
  // UpperCase first letter of name
  return newValue && newValue[0].toUpperCase() + newValue.substr(1);
}

export const firstLetterUpper = value => value && value[0].toUpperCase() + value.substr(1);

export const normalizeAadhar = (value, previousValue) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 4) {
      return onlyNums + '-'
    }
    if (onlyNums.length === 8) {
      return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4) + '-'
    }
  }
  if (onlyNums.length <= 4) {
    return onlyNums
  }
  if (onlyNums.length <= 8) {
    return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4)
  }
  return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4, 8) + '-' + onlyNums.slice(8, 12)
}


export const normalizeMetroNumber = (value, previousValue) => {
  // [@INFO] If replacing '-' with say ' '(space). Be sure to change split logics accordingly 
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 2) {
      return onlyNums + '-'
    }
    if (onlyNums.length === 8) {
      return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2) + '-'
    }
  }
  if (onlyNums.length <= 2) {
    return onlyNums
  }
  if (onlyNums.length <= 8) {
    return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2)
  }
  return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 8) + '-' + onlyNums.slice(8, 10)
}

export const normalizeEliteMetroNumber = (value, previousValue) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 4) {
      return onlyNums + '-'
    }
    if (onlyNums.length === 8) {
      return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4) + '-'
    }
    if (onlyNums.length === 12) {
      return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4, 8) + '-' + onlyNums.slice(8, 12) + '-'
    }
  }
  if (onlyNums.length <= 4) {
    return onlyNums
  }
  if (onlyNums.length <= 8) {
    return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4)
  }
  if (onlyNums.length <= 12) {
    return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4, 8) + '-' + onlyNums.slice(8)
  }
  if (onlyNums.length <= 16) {
    return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4, 8) + '-' + onlyNums.slice(8, 12) + '-' + onlyNums.slice(12)
  }
  return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4, 8) + '-' + onlyNums.slice(8, 12) + '-' + onlyNums.slice(12, 16)
}