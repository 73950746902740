export const BLUE = '#002B46'
export const RED = '#EE3338'
export const PROVIDER_ID_IDFC = 384;

export const getStoreId = () => {
  return JSON.parse(window.sessionStorage.getItem('storeId'))
}

export const GENDER_OPTION = [
  { text: 'Male', id: 1, fieldValue: 'male' },
  { text: 'Female', id: 2, fieldValue: 'female' }
]

export const EMPLOYMENT_TYPE_OPTIONS = [
  { id: 1, text: 'Salaried', fieldValue: 'salaried' },
  { id: 2, text: 'Self-Employed', fieldValue: 'selfEmpl' },
  // { id: 18, text: 'HoReCa', fieldValue: 'horeca' },
  // { id: 19, text: 'Trader', fieldValue: 'trader' },
  // { id: 20, text: 'Services', fieldValue: 'services' },
  // { id: 12, text: 'Companies and Offices', fieldValue: 'companiesAndOffices' }
]

export const NOMINEE_RELATION_TYPE = [
  { value: 1, code: 'father', text: 'Father', id: 1, fieldValue: 'father' },
  { value: 2, code: 'mother', text: 'Mother', id: 2, fieldValue: 'mother' },
  { value: 3, code: 'son', text: 'Son', id: 3, fieldValue: 'son' },
  { value: 4, code: 'daughter', text: 'Daughter', id: 4, fieldValue: 'daughter' },
  { value: 5, code: 'brother', text: 'Brother', id: 5, fieldValue: 'brother' },
  { value: 6, code: 'sister', text: 'Sister', id: 6, fieldValue: 'sister' },
  { value: 7, code: 'spouse', text: 'Spouse', id: 7, fieldValue: 'spouse' },
]

export const METRO_PLANS = [
  { text: 'Metro Chqbook Bandhan Elite Plus', id: 2, },
  { text: 'Metro Chqbook Bandhan Elite', id: 1 }
]

export const MEMBERSHIP_HEADER_TEXT = 'Bandhan Enrollment Form'

export const SEARCH_PARAMS = [
  { name: 'Mobile', id: 'mobile', },
  { name: 'Metro Card No.', id: 'accountNo', },
  { name: 'Business Name', id: 'companyQ', },
]

export const THIRD_PARTY_LOANS_SEARCH_PARAMS = [
  { text: 'Mobile', id: 'mobile', },
  { text: 'Account Number', id: 'accountNo', },
]

export const LEAD_SEARCH_PARAMS = [
  { name: 'Mobile', id: 'mobile', },
  { name: 'Metro Card No', id: 'storeUniqueId', },  
]

export const LEAD_STATUS = [
  { id: '-1', name: 'All' },
  { id: 1, name: 'Under Process' },
  { id: 3, name: 'Followup' },//
  { id: 4, name: 'Need Additional Docs' },//
  { id: 5, name: 'Docs Collected' },//
  { id: 7, name: 'Approved' },//
  { id: 8, name: 'Rejected' },//
  { id: 9, name: 'Not Interested' },//
  { id: 11, name: 'Disbursed' },//
]

export const ADDITIONAL_ROUTES = [
  { action: '/health-check' },
  { action: '/onboard/:action/:leadId?/:userId?' },
  { action: '/score/:leadId?/:userId?' },
  { action: '/success/:leadId?' },
  { action: '/xiaomi-membership' }
]

export const SHOW_OTP = 'MEM_ACT'

export const ACCOUNT_PROVIDER = null
