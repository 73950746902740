import React from 'react';
import { Field } from 'redux-form';
import { renderAsyncSelectField } from '../ReduxFormFields/utils/fields'

const ReduxAsyncSelect = (props) => {
    return (
      <Field
        {...props}
        component={renderAsyncSelectField}
      />
    );
}

export default ReduxAsyncSelect