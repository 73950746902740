import React, { Component } from 'react';
import CustomCheckbox from '../components/CustomCheckbox';
import DocumentPreview from './DocumentPreview';

class CheckboxesWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checkboxes: []
        }
    }

    setCheckBoxValue = (value, name = '') => {
        const {
            getValueArray = () => { }
        } = this.props;

        this.setState({ [name]: value })
        this.setState((oldState) => {
            let checkboxes = {
                ...oldState
            }
            delete checkboxes.checkboxes; //deleting checkboxes object
            let checkboxesArr = Object.values(checkboxes)
            checkboxesArr = checkboxesArr.filter(item => {
                return item !== 0
            })
            getValueArray(checkboxesArr);
            return { checkboxes: [].concat(checkboxesArr) }
        })
    }

    render() {
        const {
            checkboxes = [],
            showPreview = false,
            userId
        } = this.props;

        return (
            <div className='flex-wrap'>
                {
                    checkboxes.map((item, index) => {
                        return (
                            <CustomCheckbox
                                key={index}
                                setCheckBoxValue={this.setCheckBoxValue}
                                checkboxName={item.pretty_name + index} // to keep the checkbox name as unique
                                value={item.media_file_id}
                                label={item.title}
                                checked={item.verification_status == 1 ? true : false}
                            >
                                {
                                    showPreview &&
                                    <DocumentPreview
                                        key={index + 1}
                                        src={item.temp_url ? item.temp_url : 'data:image/jpg;base64,' + item.file}
                                        alt={item.title}
                                        fileType={item.file_type}
                                        timeStamp={item.created_at}
                                        mediaFileId={item.media_file_id}
                                        userId={userId}
                                    />
                                }
                            </CustomCheckbox>
                        );
                    })
                }
            </div>
        );
    }
}

export default CheckboxesWrapper;