export const loanDetailHistoryKeys = [
    {
      id: 'loan_history_id',
      title: 'Loan History Id'
    },
    {
      id: 'history_status',
      title: 'History Status'
    },
    {
      id: 'user_status',
      title: 'User Status',
    },
    {
      id: 'admin_status',
      title: 'Admin Status',
    },
    {
      id: 'title',
      title: 'Title'
    },
    {
      id: 'created_by',
      title: 'Created By'
    },
    {
      id: 'created_at_formatted',
      title: 'Created At'
    }
  ];