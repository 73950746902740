export const SET_NAME = 'SET_NAME';

export const FETCH_LOANS = 'FETCH_LOANS';
export const FETCH_LOANS_SUCCESS = 'FETCH_LOANS_SUCCESS';
export const FETCH_LOANS_FAILURE = 'FETCH_LOANS_FAILURE';


export const FETCH_DETAIL = 'FETCH_DETAIL';
export const FETCH_DETAIL_SUCCESS = 'FETCH_DETAIL_SUCCESS';
export const FETCH_DETAIL_FAILURE = 'FETCH_DETAIL_FAILURE';
export const RESET_DETAILS = 'RESET_DETAILS'

export const INITIATE_TRANSACTION = 'INITIATE_TRANSACTION';
export const INITIATE_TRANSACTION_SUCCESS = 'INITIATE_TRANSACTION_SUCCESS';
export const INITIATE_TRANSACTION_FAILURE = 'INITIATE_TRANSACTION_FAILURE';

export const VERIFY_OTP = 'VERIFY_OTP';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE';

//Aproval Page Actions

export const RESET_TRANSACTIONS = 'RESET_TRANSACTIONS';
export const FETCH_TRANSACTIONS = 'FETCH_TRANSACTIONS';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_FAILURE = 'FETCH_TRANSACTIONS_FAILURE';

export const APPROVE_TRANSACTION = 'APPROVE_TRANSACTION';
export const APPROVE_TRANSACTION_SUCCESS = 'APPROVE_TRANSACTION_SUCCESS';
export const APPROVE_TRANSACTION_FAILURE = 'APPROVE_TRANSACTION_FAILURE';
export const RESET_APPROVE_SUCCESS_MESSAGE = 'RESET_APPROVE_SUCCESS_MESSAGE';
export const RESET_TRANSACTION_ERROR = 'RESET_TRANSACTION_ERROR';

export const REJECT_TRANSACTION = 'REJECT_TRANSACTION';
export const REJECT_TRANSACTION_SUCCESS = 'REJECT_TRANSACTION_SUCCESS';
export const REJECT_TRANSACTION_FAILURE = 'REJECT_TRANSACTION_FAILURE';

// Edit Page Actions

export const SEARCH_LOANS = 'SEARCH_LOANS';
export const SEARCH_LOANS_SUCCESS = 'SEARCH_LOANS_SUCCESS';
export const SEARCH_LOANS_FAILURE = 'SEARCH_LOANS_FAILURE';

export const SEND_FORM_VALUES = 'SEND_FORM_VALUES';
export const SEND_FORM_VALUES_SUCCESS = 'SEND_FORM_VALUES_SUCCESS';
export const SEND_FORM_VALUES_FAILURE = 'SEND_FORM_VALUES_FAILURE';
export const RESET_LOAN_DETAILS = 'RESET_LOAN_DETAILS';

export const CREDIT_TRANSACTION_INITIATE = 'CREDIT_TRANSACTION_INITIATE';
export const DEBIT_TRANSACTION_INITIATE = 'DEBIT_TRANSACTION_INITIATE';

//Membership Page Actions
export const SEND_FORM_AUTOPOPULATE = 'SEND_FORM_AUTOPOPULATE';
export const RESET_MEMBERSHIP_FORM = 'RESET_MEMBERSHIP_FORM';

//setting lender through the dropdown
export const SET_LENDER = 'SET_LENDER';

//menu
export const GET_MENU = 'GET_MENU'
export const GET_MENU_SUCCESS = 'GET_MENU_SUCCESS'
export const GET_MENU_FAILURE = 'GET_MENU_FAILURE'

//search customers
export const SEARCH_CUSTOMER = 'SEARCH_CUSTOMER'
export const SEARCH_CUSTOMER_ACCOUNT = 'SEARCH_CUSTOMER_ACCOUNT';
export const SEARCH_CUSTOMER_SUCCESS = 'SEARCH_CUSTOMER_SUCCESS'
export const SEARCH_CUSTOMER_FAILURE = 'SEARCH_CUSTOMER_FAILURE'
export const RESET_CUSTOMER_DETAILS = 'RESET_CUSTOMER_DETAILS';

export const GET_HISTORY = 'GET_HISTORY';

//set products from login data
export const SET_PRODUCTS = 'SET_PRODUCTS';