import { change } from 'redux-form';
import { post, get } from '../api'
import config from '../../src/config';
import { API_URL_PINCODE } from '../constants/apiUrls'

export function changeReduxFormValue(data, store) {
  store.dispatch(
    change('metro', 'customer', data.name)
  )
}

export function isEmpty(obj) {
  if (typeof obj == 'object' && Object.keys(obj).length === 0 && obj.constructor === Object) {
    return true;
  }
  else {
    return false;
  }
}

export function autoPopulateFormDataUtility(list) {
  let values = {}
  if (list && list.length > 0) {
    values.mobile = list[0].mobile ? list[0].mobile : null;
    values.card = list[0].account_no ? list[0].account_no : null;
    values.amount = list[0].sanction_amount ? list[0].sanction_amount : null;
    values.toggle = list[0].status_text ? list[0].status_text : null;
    values.id = list[0].id ? list[0].id : null;
    values.newCreditLine = true //setting default true

    return values
  }
}

export function findInObjectArray(arr, id, key = 'store_id') {
  const result = arr.find(obj => obj[key] == id)
  return result === undefined ? false : true
}

export function findObjectInArray(arr, id, key = 'store_id') {
  return arr.find(obj => obj[key] == id)
}

//only when you know that the argument is a number
export function isInt(n) {
  try {
    return n % 1 === 0;
  }
  catch (err) {
    return false
  }
}

export function getDateString(dateObj) {
  // adding 0 if length <= 1
  let month = (dateObj.getMonth() + 1).toString().length <= 1 ? '0' + (dateObj.getMonth() + 1).toString() : (dateObj.getMonth() + 1)
  let day = dateObj.getDate().toString().length <= 1 ? '0' + dateObj.getDate().toString() : dateObj.getDate()
  return dateObj.getFullYear() + '-' + month + '-' + day;
}

export function addDaysInDate(dateObj, dayNum) {
  let someDate = new Date(dateObj)
  someDate.setDate(someDate.getDate() + dayNum);
  return someDate
}

export const getAge = (dobYear) => {
  const d = new Date();
  const currentYear = d.getFullYear();
  let age = null;
  if (dobYear) {
    age = currentYear - dobYear;
  }
  return age;
}

export function getMetroNumberSubComponent(props) {
  const {
    metroHolderNumber,
    metroCardNumber
  } = props;

  let metroStoreCode = null;
  let metroCustomerNumber = null;

  try {
    const metroComponents = metroCardNumber.split("-");
    metroStoreCode = metroComponents[0];
    metroCustomerNumber = metroComponents[1];
  } catch (error) {
    console.log('Error', error);
  }

  return {
    metroStoreCode,
    metroCustomerNumber,
    metroHolderNumber
  }
}

export function checkForMetroNumberCombination(props) {
  // Check for valid MetroNumber
  const {
    metroStoreCode,
    metroCustomerNumber,
    metroHolderNumber,
  } = props;

  if (metroStoreCode && metroCustomerNumber && metroHolderNumber) {
    const customMetroHolderNumber = '0' + metroHolderNumber;
    const metroNumberLength = metroStoreCode.length + metroCustomerNumber.length + customMetroHolderNumber.length;
    // metroNumberLength should be 10 digits; prefixed '0'
    if (metroNumberLength == 10) {
      return true;
    }
    else {
      return false
    }
  }
  else {
    return false;
  }
}

export const getcbMetroCardInfo = (obj) => {
  const {
    metroStoreCode,
    metroCustomerNumber,
    metroHolderNumber = null
  } = getMetroNumberSubComponent(obj);

  let cbMetroCardNo = null;

  if (checkForMetroNumberCombination({
    metroStoreCode,
    metroCustomerNumber,
    metroHolderNumber
  })) {
    // debugger;
    const customMetroHolderNumber = `0${metroHolderNumber}`
    cbMetroCardNo = `${metroStoreCode}${metroCustomerNumber}${customMetroHolderNumber}`;
  }
  return {
    cbMetroCardNo,
    metroStoreCode,
    metroCustomerNumber,
    metroHolderNumber
  }
}

export const fieldSanitize = (num) => {
  if (num) {
    return num.replace(/[\W_]+/g, "")
  }
  else {
    return "";
  }
}

export const checkMembershipPermissions = (arr) => {
  const filteredArr = arr.filter((x) => {
    return [2, 3, 25, 44, 45, 46].indexOf(x) !== -1
  })
  if (filteredArr.length == 6 && filteredArr.length == arr.length) {
    // console.log(filteredArr)
    return true;
  }
  else {
    return false;
  }
}

export const checkMembershipPermissionsUnstrict = (arr) => {
  const filteredArr = arr.filter((x) => {
    return [2, 3, 25, 44, 45, 46].indexOf(x) !== -1
  })
  if (filteredArr.length == 6 && filteredArr.length != arr.length) {
    return true;
  }
  else {
    return false;
  }
}

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}


export const getSetLocale = (url, payload, setState, params = {}) => {
  //always hardbind setState with component's this before using this function
  setState({ [params.isLoading]: true })
  return new Promise((resolve, reject) => {
    get(url, payload)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          setState({
            [params.meta]: res.data.metadata,
            [params.data]: res.data.data.results,
            [params.isLoading]: false
          })
          resolve(res)
        }
        else {
          setState({ [params.isLoading]: false })
        }
      })
  })
}

export const postSetLocale = (url, payload, setState, params = {}) => {
  //always hardbind setState with component's this before using this function
  return new Promise((resolve, reject) => {
    setState({ [params.isLoading]: true })
    post(url, payload)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          setState({
            [params.meta]: res.data.metadata,
            [params.data]: res.data.data ? res.data.data.results : res.data.results,
            [params.msg]: res.data.data ? res.data.data.message : res.data.message,
            [params.errors]: [],
            [params.isLoading]: false
          })
          resolve(res);
        }
        else if (res.status >= 400 || res.status <= 500) {
          setState({
            [params.errors]: res.data.errors,
            [params.msg]: null,
            [params.isLoading]: false
          })
          reject(res);
        }
      })
  })
}

export function buildUrlWithQueryParams(url, parameters) {
  let qs = "";
  for (let key in parameters) {
    let value = parameters[key];
    qs += encodeURIComponent(key) + "=" + encodeURIComponent(value) + "&";
  }
  if (qs.length > 0) {
    qs = qs.substring(0, qs.length - 1); //chop off last "&"
    url = url + "?" + qs;
  }
  return config.API_BASE + url;
}

// Utility Function for setting the filters
// hard bind this to setState to allow passing it in parameters for using this utility
export const setFilter = (arr = [], state, setState) => {
  let filters = Object.assign({}, state.filters); //creating copy of object
  for (let i = 0; i < arr.length; i++) { //updating values
    filters[arr[i].tag] = arr[i].value
  }
  setState({ filters }); //setting state
}

//debounce function implementation
export const debounce = (func, delay) => {
  let inDebounce
  return function () {
    const context = this
    const args = arguments
    clearTimeout(inDebounce)
    inDebounce = setTimeout(() => func.apply(context, args), delay)
  }
}

export const sanitizeDocuments = (leadDocs) => {
  let sanitizedDocs = [];
  try {
    sanitizedDocs = leadDocs.map((item, index) => {
      return !item.media_file_id
        ? item.removed
          ? { media_file_id: item.id, verification_status: 0, status: item.status }
          : { media_file_id: item.id, verification_status: 0, status: 1 }
        : item.removed
          ? { media_file_id: item.media_file_id, verification_status: item.verification_status, status: item.status }
          : { media_file_id: item.media_file_id, verification_status: item.verification_status, status: 1 }
    })
  } catch (err) { console.log('Something Went Wrong', err) }

  return sanitizedDocs;
}

export function getLeadDocPayload(docs) {
  let sanitizedDocs = [];
  try {
    sanitizedDocs = docs.map((item, index) => {
      let a = Object.assign({}, item)
      delete a.filepondId
      if (a.status == undefined) {
        a.status = 1
      }
      return a
    })
  } catch (err) { console.log('Something Went Wrong', err) }

  return sanitizedDocs;
}

export const arrayToObject = (array, key = 'id') =>
  array.reduce((obj, item) => {
    obj[item[key]] = item
    return obj
  }, {})


export const pincodeLoadingOptions = (inputValue, callback) => {
  get(API_URL_PINCODE, {
    q: inputValue
  })
    .then((res) => {
      if (res.status == 200 || res.status == 201) {
        try {
          callback(res.data.data.results);
        } catch (err) {
          callback(res.data.data.results);
        }
      }
    })
};

export const breakFullName = (fullName) => {
  let firstName = '';
  let middleName = '';
  let lastName = '';

  try {
    const fullNameArr = fullName && fullName.trim().split(" ");
    const firstNameArr = fullNameArr.splice(0, 1);
    firstName = firstNameArr.join(" ");
    const lastNameArr = fullNameArr.splice(fullNameArr.length - 1);
    lastName = lastNameArr.join(" ");
    const middleNameArr = fullNameArr;
    middleName = middleNameArr.join(" ");
  } catch (error) {
    console.log("breakFullName", error);
  }

  return {
    firstName,
    middleName,
    lastName
  }
}