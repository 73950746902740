import {
  NAME_REGEX,
  ADDRESS_REGEX,
  PINCODE_REGEX,
  EMAIL_REGEX,
  PANCARD_REGEX,
  AADHAR_REGEX,
  AADHAR_TEXT_REGEX,
  METRO_TEXT_REGEX,
  ELITE_METRO_TEXT_REGEX,
  MOBILE_REGEX,
  STD_REGEX,
  LANDLINE_REGEX,
  GST_REGEX,
  FULL_NAME_REGEX,
  CONSECUTIVE_NAME_REGEX,
} from './validationRules';

import { breakFullName } from '../utils/utility';

export const required = (value) => (value ? undefined : 'Required');

export const name = (value) => {
  return value && !NAME_REGEX.test(value) ? `Invalid Name` : undefined;
};

export const consecutiveCharsName = (value) => {
  return value && !CONSECUTIVE_NAME_REGEX.test(value.toLowerCase())
    ? `Invalid Name -  contains more than 2 same consecutive characters`
    : undefined;
};

export const maxLength = (max) => (value) => {
  return value && value.length > max
    ? `Must be ${max} characters or less`
    : undefined;
};

export const maxValue = (max) => (value) => {
  return value > max ? `Invalid input` : undefined;
};

export const minLength = (min) => (value) => {
  return value && value.length < min
    ? `Must be ${min} characters or more`
    : undefined;
};

export const equalLength = (len) => (value) => {
  return value && value.length == len ? undefined : `Invalid Length`;
};

export const minLength3 = minLength(3);
export const maxLength15 = maxLength(15);
export const maxLength72 = maxLength(72);
export const maxLength24 = maxLength(24);
export const maxLength2 = maxLength(2);
export const maxLength1 = maxLength(1);
export const maxLength4 = maxLength(4);
export const maxLength6 = maxLength(6);
export const equalLength4 = equalLength(4);
export const equalLength6 = equalLength(6);
export const equalLength1 = equalLength(1);
export const equalLength2 = equalLength(2);
export const equalLength15 = equalLength(15);
export const maxDate = maxValue(31);
export const maxMonth = maxValue(12);

export const gstValidator = (value) => {
  return value && !GST_REGEX.test(value) ? `Invalid GSTIN/UIN` : undefined;
};

export const address = (value) => {
  return value && !ADDRESS_REGEX.test(value) ? `Invalid Address` : undefined;
};

export const pincode = (value) => {
  return value && !PINCODE_REGEX.test(value) ? `Invalid Pincode` : undefined;
};

export const email = (value) => {
  return value && !EMAIL_REGEX.test(value) ? `Invalid Email` : undefined;
};

export const aadhar = (value) => {
  return value && !AADHAR_TEXT_REGEX.test(value) ? `Invalid Aadhar` : undefined;
};

export const metroNumber = (value) => {
  return value && !METRO_TEXT_REGEX.test(value)
    ? `Invalid Metro CNC number`
    : undefined;
};

export const eliteMetroNumber = (value) => {
  return value && !ELITE_METRO_TEXT_REGEX.test(value)
    ? `Invalid Elite Metro number`
    : undefined;
};

export const pancard = (value) => {
  return value && !PANCARD_REGEX.test(value) ? `Invalid Pancard` : undefined;
};

export const mobile = (value) => {
  return value && !MOBILE_REGEX.test(value)
    ? `Enter a valid phone number`
    : undefined;
};

export const std = (value) => {
  return value && !STD_REGEX.test(value) ? `Invalid STD code` : undefined;
};

export const isNumber = (value) => {
  return isNaN(value) ? (value ? `Invalid input` : undefined) : undefined;
};

// Not needed as off now
// export const landline = value => {
//   console.log("---------", value);
//   return value && !LANDLINE_REGEX.test(value)
//     ? `Invalid Landline field`
//     : undefined;
// }

export const fullName = (value) => {
  if (value) {
    if (!FULL_NAME_REGEX.test(value)) {
      return `Alphanumeric characters not allowed`;
    }
    const { firstName, middleName, lastName } = breakFullName(value);

    if (firstName.length < 1 || lastName.length < 1) {
      return `Please enter valid first name and last name`;
    }
  }

  return undefined;
  // return value && !FULL_NAME_REGEX.test(value) ? `Alphanumeric characters not allowed` : undefined;
};

export const minLength25 = (value) => {
  let customValue = value;

  if (customValue && customValue.length < 25) {
    return 'Charater length must be greater than 25';
  }
  return undefined;
};

export const containsNumber = (value) => {
  const r = /\d+/;
  if (value && !r.test(value)) {
    return 'Digit should be present';
  }
  return undefined;
};
