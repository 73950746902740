import React, { Component } from 'react';

class DownloadButton extends Component {
    
    render() {
        const {
            handleClick=()=>{}
        } = this.props;
        
        return (
            <div className='textbox'>
                <button className='sample-download' onClick={handleClick}><i className="fas fa-arrow-circle-down"></i></button>
                <div className='sample-download-text'>download sample file</div>
            </div>
        );
    }
}

export default DownloadButton;