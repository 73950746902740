export default {
  transaction: {
    loanDetails:[],
    info: null,
    isLoaded: true,
    error: null,
    InitiateError:null,
    otpError:null,
    transactionData:null,
    transactionMsg:null,
    otpVerificationMsg:null
  },
  approval: {
    txns:null,
    isLoaded:false,
    errorTxns:null,
    errorAction:[],
    apprMsg:null,
    transactionInProgress:false
  },
  loans: {
    details:null,
    isLoaded:false,
    error:null,
    msg:null,
    formError:null
  },
  appConstants: {
    providerId: '-1',
    menu:[],
    lenderConstants:[],
    doc_types: {},
    header_name:'Dashboard',
    lender_product:[],
    multiDocs: {},
    loanHistoryStatuses:[]
  },
  customers: {
    info:[],
    isLoaded: false,
    error: null
  }
};
