import React, { Component } from 'react';
import ReduxTextField from '../ReduxFormFields/ReduxTextField';
import CustomButton from '../components/Button';
import { Field, reduxForm } from 'redux-form'
import { compose } from 'redux';
import { connect } from 'react-redux'
import { autoPopulateFormDataUtility } from '../utils/utility'
import ReduxSelectField from '../ReduxFormFields/ReduxSelectField';
import { loanDetailHistoryKeys } from './keys';
import TableComponent from './TableComponent'
import { post } from '../api/index';

const LOAN_DETAILS_API_URL = '/third-party/loans/status-history';

class UserEditForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      expandingLoanDetail: []
    }
  }

  componentDidMount(){
    let { id } = this.props.initialValues;
    post(LOAN_DETAILS_API_URL, { loanId:Number(id) }).then((res) => {
      if(res){
        if (res && res.status == 200 || res.status == 201) {
          this.setState(prevState => {
              return {
                  expandingLoanDetail: res.data.data.results
              }
          })
        }
        else if (res && res.status >= 400 || res.status <= 403) {
            this.setState(prevState => {
                return {
                    expandingLoanDetail: res.data.errors
                }
            })
        }
      }
    })
  }

  toggleDisable = () => {
    this.setState(prevState => {
      return ({
        disabled: !prevState.disabled
      })
    })
  }

  render() {
    const {
      errors = {},
      handleSubmit,
      submitting,
      loanHistoryStatuses,
      showCheckbox
    } = this.props

    let { expandingLoanDetail } = this.state;

    return (
      <div className='width-100'>
        <form onSubmit={handleSubmit}>
          <div className='inner-container flex-vertical width-100'>
            <ReduxTextField
              name='card'
              type='tel'
              textLabel='Account Number'
              style={{
                height: '40px'
              }}
              disabled={this.state.disabled}
            />
            <ReduxTextField
              name='mobile'
              type='tel'
              textLabel='Mobile Number'
              style={{
                height: '40px'
              }}
              disabled={this.state.disabled}
            />
            <ReduxTextField
              name='amount'
              type='tel'
              textLabel='Sanction Amount'
              style={{
                height: '40px'
              }}
              disabled={this.state.disabled}
            />
            <br />
            <ReduxSelectField
              label='Status'
              name="toggle"
              options={loanHistoryStatuses}
              containerStyle={{ width: 'max-content' }}
              disabled={this.state.disabled}
            />
            {
              showCheckbox &&
              <div>
                <Field
                  style={{ transform: 'scale(1.4)', outline: 'none', cursor: 'pointer' }}
                  name="newCreditLine"
                  id="newCreditLine"
                  component="input"
                  type="checkbox"
                  disabled={this.state.disabled}
                />
                &nbsp;&nbsp;
              <label>  Create new lead</label>
              </div>
            }
            <div className='flex-horizontal j-center'>
              <div className='width-35'>
                <CustomButton
                  label='Edit'
                  fullWidth={true}
                  onClick={this.toggleDisable}
                  type='button'
                  disabled={submitting}
                />
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <div className='width-35'>
                <CustomButton
                  label='Submit'
                  fullWidth={true}
                  disabled={this.state.disabled || submitting}
                />
              </div>
            </div>
          </div>
        </form>
        <TableComponent 
          keys={loanDetailHistoryKeys}
          headers={loanDetailHistoryKeys}
          normalDisplay={true}
          details={
            Array.isArray(expandingLoanDetail) &&
            expandingLoanDetail instanceof Array &&
            expandingLoanDetail instanceof Object ?
            expandingLoanDetail :
            []
          }
        />
      </div>
    );
  }
}

export default compose(
  connect((state, props) => {
    const initialValues = autoPopulateFormDataUtility(props.userDetails)
    return {
      initialValues,
      loanHistoryStatuses: state.appConstants.loanHistoryStatuses
    }
  },
  ),
  reduxForm({
    form: 'edit',
    enableReinitialize: true,
  })
)(UserEditForm);