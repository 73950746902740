import React, { Component } from 'react';
import UserEditForm from '../components/UserEditForm';
import { connect } from 'react-redux';
import * as EditActions from '../actions/EditPageActions';
import SearchFormUI from '../components/SearchFormUI';
import { getFormValues } from 'redux-form';

class AdminContainer extends Component {

  componentWillUnmount() {
    // resetting loan details state
    this.props.resetLoanDetails();
  }

  doSubmit = (values) => {
    const {
      details
    } = this.props
    this.props.sendFormValues({
      mobile: values.mobile,
      accountNo: values.card,
      sanctionAmount: values.amount,
      loanId: details && details.length > 0 ? details[0].id : null,
      providerId: details && details.length > 0 ? details[0].provider_id : null,
      accountProvider: details && details.length > 0 ? details[0].account_provider : null,
      adminStatus: values.toggle,
      newCreditLine: values.newCreditLine && details[0].sanction_amount != values.amount
    })
  }

  render() {
    const {
      formError,
      msg,
      details,
      values
    } = this.props

    return (
      <div>
        <SearchFormUI
          {...this.props}
        >
          <div className='fluid-width-45' style={{ width:'100%' }}>
            <div className='heading'>
              User Details
              </div>
              {
              formError && formError.length > 0 &&
              <div style={{ margin: '10px', maxWidth:'500px' }}>
                {
                  formError.map((item, i) => {
                    return (
                      <div className="alert alert-danger" role="alert" key={i}>
                        {item}
                      </div>
                    )
                  })
                }
              </div>
            }
            {
              msg &&
              <div style={{ margin: '10px' }}>
                <div className="alert alert-success" role="alert">
                  {msg}
                </div>
              </div>
            }
            <UserEditForm
              onSubmit={this.doSubmit}
              userDetails={details}
              showCheckbox={
                details instanceof Array && values 
                ?  details[0].sanction_amount != values.amount
                : false
              }
            />
          </div>
        </SearchFormUI>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    details: state.loans.details,
    formError: state.loans.formError,
    msg: state.loans.msg,
    error: state.loans.error,
    values: getFormValues('edit')(state),
    searchFormValues: getFormValues('search')(state),
  };
}

export default connect(mapStateToProps, {
  ...EditActions
})(AdminContainer);