// Alpha and space
export const NAME_REGEX = /^[a-zA-Z\s]+$/;

//consecutive same aplha
export const CONSECUTIVE_NAME_REGEX = /^(?!.*([A-Za-z])\1\1).*$/;

export const ADDRESS_REGEX = /^[a-zA-Z0-9\s,-]*$/;

export const PINCODE_REGEX = /^[1-9][0-9]{5}$/;

export const MOBILE_REGEX = /^[6789]\d{9}$/;

export const GST_REGEX =
  /^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/;

// STD regex check for start with 0 and length
export const STD_REGEX = /^[0][0-9]{2,4}$/;

export const LANDLINE_REGEX = /^[0-9]{7}$/;

export const PANCARD_REGEX =
  /^([a-zA-Z]){3}([Pp])([a-zA-Z]){1}([0-9]){4}([a-zA-Z]){1}?$/;

export const AADHAR_REGEX = /^\d{12}$/;

export const AADHAR_TEXT_REGEX = /^([0-9]){4}([-])([0-9]){4}([-])([0-9]){4}$/;

export const METRO_TEXT_REGEX = /^([0-9]){2}([-])([0-9]){6}([-])([0-9]){2}$/;

export const ELITE_METRO_TEXT_REGEX =
  /^([0-9]){4}([-])([0-9]){4}([-])([0-9]){4}([-])([0-9]){4}$/;

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const FULL_NAME_REGEX = /^[a-zA-Z\s]+$/;

// [@INFO]Inorder to restrict fields, maxLength works for 'text',
// digit strict digit regex can be changed to string where digits allowed

// Be aware, that \W leaves the underscore. A short equivalent for [^a-zA-Z0-9] would be [\W_]

// text.replace(/[\W_]+/g," ");
// \W is the negation of shorthand \w for [A-Za-z0-9_] word characters (including the underscore)
