import React, { Component } from 'react';
import Upload from './Upload';
import { dataURLtoFile } from '../../utils/utility'

class UploadContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCamera: false,
            buttonConfigFlag: false,
        }
    }

    handleClick = (event) => {
        this.setState({ showCamera: !this.state.showCamera, buttonConfigFlag: false })
        event.preventDefault();
    }

    onTakePhoto = (dataUri) => {
        const {
            setImgFile = () => console.log('Empty'),
        } = this.props;

        const file = dataURLtoFile(dataUri);

        this.setState({
            buttonConfigFlag: true,
            showCamera: false
        })

        setImgFile(URL.createObjectURL(file)) // setting file in parent state
    }

    render() {
        const {
            showCamera,
            buttonConfigFlag
        } = this.state;

        const {
            imgFile = null
        } = this.props;

        return (
            <div className='upload'>
                <span>
                    <button onClick={this.handleClick}>Show Camera</button>
                    &nbsp;
                      {
                        imgFile && buttonConfigFlag && <b className='tick'>&#x2713;</b>
                    }
                </span>
                {
                    showCamera &&
                    <div className='upload'>
                        <Upload
                            onTakePhoto={this.onTakePhoto}
                        />
                    </div>
                }
            </div>
        );
    }
}

export default UploadContainer;