import { take, put, fork, call, select } from 'redux-saga/effects';
import { change, reset } from 'redux-form';
import {
  SEARCH_LOANS,
  SEARCH_LOANS_SUCCESS,
  SEARCH_LOANS_FAILURE,

  SEND_FORM_VALUES,
  SEND_FORM_VALUES_SUCCESS,
  SEND_FORM_VALUES_FAILURE,

  CREDIT_TRANSACTION_INITIATE,
  DEBIT_TRANSACTION_INITIATE
} from '../actions/actionConstants';

import { post } from '../api';

// this is a utility function for handling post api calls in the redux-saga
//pass all the variables in sequence as listed below
export function* postAPI({
  payload = {},
  url = '',
  failAction = '',
  successAction = '',
  key = 'results',
  successCB = () => { console.log('Success') }, //this is the callback function which is called in case of success status
  failCB = () => { console.log('Fail') } //this is the callback function which is called in case of failure status
}) {
  const fetchDataResponse = yield call(
    post,
    url, payload);
  if (fetchDataResponse.status >= 400 || fetchDataResponse.status >= 401) {
    yield put({
      type: failAction,
      error: fetchDataResponse.data.errors,
    });
    // console.log('error detail: ', fetchDataResponse);
    yield call(failCB);
  }
  else if (fetchDataResponse.status === 200 || fetchDataResponse.status === 201) {
    yield put({
      type: successAction,
      data: fetchDataResponse.data.data[key],
    });
    // console.log('resp detail: ', fetchDataResponse)
    yield call(successCB);
  }
}

// This is a utility function to change the form field values
export function* changeFormField(formName, fields = []) {
  for (let i = 0; i < fields.length; i++) {
    yield put(change(formName, fields[i].name, fields[i].value))
  }
}

function* getLoans() {
  while (true) {
    const request = yield take(SEARCH_LOANS);
    const { payload } = request;
    yield call(postAPI, {
      payload,
      url: '/third-party/loans',
      failAction: SEARCH_LOANS_FAILURE,
      successAction: SEARCH_LOANS_SUCCESS
    })
  }
}

function* editForm() {
  while (true) {
    const request = yield take(SEND_FORM_VALUES);
    const { payload } = request;
    yield call(postAPI, {
      payload,
      url: '/third-party/loan/update',
      failAction: SEND_FORM_VALUES_FAILURE,
      successAction: SEND_FORM_VALUES_SUCCESS,
      key: 'message'
    })
  }
}

function* editCreditTransaction() {
  while (true) {
    const request = yield take(CREDIT_TRANSACTION_INITIATE);
    const { payload } = request;
    //see editDebitTransaction for info comments
    const fields = [{
      name: 'amount',
      value: ''
    }]
    const clearField = changeFormField.bind(null, 'credit', fields)

    const success = postAPI.bind(null, {
      payload,
      url: '/third-party/loans',
      failAction: SEARCH_LOANS_FAILURE,
      successAction: SEARCH_LOANS_SUCCESS,
      successCB: clearField
    });
    //transfer all the parameters in the object
    yield call(
      postAPI, {
        payload,
        url: '/third-party/loans/tx/credit',
        failAction: SEND_FORM_VALUES_FAILURE,
        successAction: SEND_FORM_VALUES_SUCCESS,
        key: 'message',
        successCB: success,
      })
  }
}

function* editDebitTransaction() {
  while (true) {
    const request = yield take(DEBIT_TRANSACTION_INITIATE);
    const { payload } = request;
    //creating array in changeFormField function to change the fields value from redux form
    const fields = [{
      name: 'amount',
      value: ''
    }]
    //creating the function reference and binding with parameters
    const clearField = changeFormField.bind(null, 'debit', fields)

    const success = postAPI.bind(
      null, {
        payload,
        url: '/third-party/loans',
        failAction: SEARCH_LOANS_FAILURE,
        key: 'results',
        successCB: clearField,
        successAction: SEARCH_LOANS_SUCCESS,
      });
    //transfer all the parameters in the object

    yield call(postAPI, {
      payload,
      url: '/third-party/loans/tx/debit',
      successAction: SEND_FORM_VALUES_SUCCESS,
      failAction: SEND_FORM_VALUES_FAILURE,
      key: 'message',
      successCB: success,
    })
  }
}


export default function* () {
  yield fork(getLoans);
  yield fork(editForm);
  yield fork(editCreditTransaction);
  yield fork(editDebitTransaction);
}
