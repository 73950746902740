import React, { Component } from 'react';
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux';
import { compose } from 'redux';
import TextBoxWithButton from './TextBoxWithButton';
import ReduxSelectField from '../ReduxFormFields/ReduxSelectField';

class SingleTextFieldForm extends Component {

  render() {
    const {
      error = [],
      handleSubmit,
      textBoxName = 'card',
      textLabel = ' ',
      buttonLabel = 'Search',
      textBoxType = 'tel',
      className = 'fluid-width-45',
      textStyle = {},
      showSelectField = false,
      selectFieldLabel = 'Search By',
      selectFieldName = 'searchBy',
      selectFieldOptions = [],
      disableButton = false
    } = this.props

    return (
      <div className={className}>
        <form onSubmit={handleSubmit}>
          <div className='inner-container horizontal-to-vertical width-100'>
            {
              showSelectField &&
              <div className='padding-side'>
                <ReduxSelectField
                  label={selectFieldLabel}
                  name={selectFieldName}
                  options={selectFieldOptions}
                  containerStyle={{ width: 'max-content' }}
                />
              </div>
            }
            <div className='flex-horizontal width-100 item-end-align'>
              <TextBoxWithButton
                textBoxName={textBoxName}
                textLabel={textLabel}
                textDescription={error && Array.isArray(error) && error.length > 0 ? error[0] : null}
                buttonLabel={buttonLabel}
                textBoxType={textBoxType}
                textStyle={textStyle}
                disabled={disableButton}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default compose(
  connect((state, props) => {
    const {
      formName = 'search',
    } = props;
    return {
      form: formName
    }
  }),
  reduxForm({
    enableReinitialize: true,
  }),
)(SingleTextFieldForm);