import React from 'react';
import { Field } from 'redux-form';
import { renderTextField } from '../ReduxFormFields/utils/fields'

const ReduxTextField = (props) => {
    return (
      <Field
        {...props}
        component={renderTextField}
      />
    );
}

export default ReduxTextField