import React, { Component } from 'react';

class CustomTable extends Component {

  handleClick = (val) => {
    const {
      onRowClick = () => { }
    } = this.props;
    onRowClick(val)
  }
  render() {
    const {
      details,
      headers = ['ID', 'Name', 'Mobile', 'Account'],
      keys = ['id', 'name', 'mobile', 'account_no']
    } = this.props;
    return (
      <div className='inner-container overflow-hidden'>
        <table className="table">
          <thead>
            <tr>
              {
                headers.map((item, index) => {
                  return <th key={index} scope="col">{item}</th>
                })
              }
            </tr>
          </thead>
          <tbody>
            {
              details.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <tr>
                      {
                        keys.map((val, index) => {
                          return index == 0 ?
                            <th scope="row" key={index + '1'}>
                              {/* Assuming first column is ID */}
                              <span className='link' onClick={this.handleClick.bind(null, item[val])}>{item[val]}</span>
                            </th> :
                            <td key={index + '1'}>{item[val]}</td>
                        })
                      }
                    </tr>
                  </React.Fragment>
                )
              })
            }
          </tbody>
        </table>
      </div>
    );
  }
}

export default CustomTable;