import {
    SEARCH_CUSTOMER,
    SEARCH_CUSTOMER_ACCOUNT,
    RESET_CUSTOMER_DETAILS
  } from './actionConstants'
  
  export const searchCustomer = (data) => {
    return {
      type: SEARCH_CUSTOMER,
      payload: data
    }
  }

  export const searchCustomerAccount = (data) => {
    return {
      type: SEARCH_CUSTOMER_ACCOUNT,
      payload: data
    }
  }

  export const resetCustomerDetails = () => {
    return {
      type: RESET_CUSTOMER_DETAILS,
    }
  }