import React from 'react';
import { Field } from 'redux-form';
import { renderSelectField } from '../ReduxFormFields/utils/fields'

const ReduxSelectField = (props) => {
    return (
      <Field
        {...props}
        component={renderSelectField}
      />
    );
}

export default ReduxSelectField