import React, { Component } from 'react';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

class Upload extends Component {
    render() {
        const {
            onTakePhoto = () => console.log('Empty')
        } = this.props;
        return (
            <div>
                <Camera
                    onTakePhoto={(dataUri) => { onTakePhoto(dataUri); }}
                    isImageMirror={false}
                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                />
            </div>
        );
    }
}
export default Upload