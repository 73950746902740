import {
  fork,
} from 'redux-saga/effects';

import AppSaga from './AppSaga';
import ApprovalSaga from './ApprovalSaga';
import EditSaga from './EditSaga';
import MembershipSaga from './MembershipSaga';
import SearchCustomerSaga from './SearchCustomerSaga';

export default function* root() {
  yield fork(AppSaga);
  yield fork(ApprovalSaga);
  yield fork(EditSaga);
  yield fork(MembershipSaga);
  yield fork(SearchCustomerSaga);
}

