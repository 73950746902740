import React from 'react';
import TextField from 'material-ui/TextField';
import { BLUE } from '../constants/index'
import CustomButton from './Button';

class TextBox extends React.PureComponent {

  render() {
    let {
      input,
      autoFocus = false,
      underlineShow = false,
      underlineStyle = {},
      floatingLabelText = '',
      floatingLabelFocusStyle = {},
      floatingLabelStyle = {},
      inputStyle = {},
      textareaStyle={},
      style = {},
      hintStyle = {},
      multiLine = false,
      rows = 0,
      rowsMax = 0,
      maxLength = '',
      name = '',
      type = 'text',
      hintText = '',
      autoComplete = 'off',
      errorText = '',
      textDescription,
      textLabel,
      disabled = false,
      fullWidth = false,
      required = false,
      max = null,
      min = null,
      showBtn = false,
      sideBtnContainerStyle = {},
      sideBtnLabel = '',
      sideBtnType = 'button',
      sideBtnlabelStyle = {},
      sideBtnCoreStyle = {},
      sideButtonStyle = {},
      onClick = () => { console.log('Clicked') },
      sideBtnDisabled = false,
      ...others
    } = this.props

    sideBtnContainerStyle = Object.assign({
      position: 'absolute',
      bottom: '0',
      right: '0',
    }, sideBtnContainerStyle)

    sideBtnlabelStyle = Object.assign({
      textTransform: 'capitalize', padding: '0px',
    }, sideBtnlabelStyle)

    sideBtnCoreStyle = Object.assign({
      minWidth: '1px',
    }, sideBtnCoreStyle)

    sideButtonStyle = Object.assign({
      borderRadius: '2px'
    }, sideButtonStyle)

    underlineStyle = Object.assign({
      borderColor: 'black',
    }, underlineStyle)

    floatingLabelFocusStyle = Object.assign({
      color: BLUE
    }, floatingLabelFocusStyle)

    floatingLabelStyle = Object.assign({
      color: 'rgba(0, 0, 0, 0.3)'
    }, floatingLabelStyle)

    style = Object.assign({
      borderRadius: 5,
      border: '1px solid #dfe3e9',
      minHeight: '35px',
      padding: '3px',
      width: '100%'
    }, style)

    inputStyle = Object.assign({
      color: 'black !important',
    }, inputStyle)

    const errorStyle = {
      textAlign: 'left',
      fontSize: '12px',
      bottom: '10px'
    }

    hintStyle = Object.assign({
      bottom: '7px',
    }, hintStyle)

    return (
      <div className='flex-vertical text-box-wrapper'>
        <label className='text-box-label'>{textLabel}</label>
        <TextField
          name={name}
          type={type}
          underlineShow={underlineShow}
          underlineStyle={underlineStyle}
          underlineFocusStyle={underlineStyle}
          floatingLabelText={floatingLabelText}
          floatingLabelFocusStyle={floatingLabelFocusStyle}
          floatingLabelStyle={floatingLabelStyle}
          hintStyle={hintStyle}
          maxLength={maxLength}
          hintText={hintText}
          autoComplete={autoComplete}
          errorText={errorText}
          errorStyle={errorStyle}
          autoFocus={autoFocus}
          disabled={disabled}
          style={style}
          inputStyle={inputStyle}
          fullWidth={fullWidth}
          required={required}
          max={max}
          min={min}
          multiLine={multiLine}
          rows={rows}
          rowsMax={rowsMax}
          textareaStyle={textareaStyle}

          {...input}
          {...others}
        />
        {
          showBtn &&
          <div style={sideBtnContainerStyle}>
            <CustomButton
              label={sideBtnLabel}
              type={sideBtnType}
              labelStyle={sideBtnlabelStyle}
              style={sideBtnCoreStyle}
              buttonStyle={sideButtonStyle}
              onClick={onClick}
              disabled={sideBtnDisabled}
            />
          </div>
        }
        {
          textDescription &&
          <div className='info-text'>
            <i style={{ color: '#c5d0de' }} className="fas fa-info-circle"></i>
            &nbsp;
        <span className='text-box-desc'>
              {textDescription}
            </span>
          </div>
        }
      </div>
    )
  }
}

export default TextBox;