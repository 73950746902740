import React, { Component } from 'react'

export default class CustomPaper extends Component {
  render() {
    const {
      sanctionLimit,
      availableLimit,
      utilizedLimit,
      lenderName
    } = this.props;

    return (
      <div className='paper'>
        <div className='flex-vertical'>
          {
            lenderName &&
            <div style={{  margin: '5% 0px 0px 24%'}}>
            <b>Lender : {lenderName}</b>
          </div>
          }
          <div style={{  margin: '5% 0px 0px 24%'}}>
            Sanction Limit : &#8377; {sanctionLimit}
          </div>
          <div style={{  margin: '5% 0px 0px 24%'}}>
            Available Limit : &#8377; {availableLimit}
          </div>
          <div style={{  margin: '5% 0px 0px 24%'}}>
            Utilized Limit : &#8377; {utilizedLimit}
          </div>
        </div>
      </div>
    )
  }
}
