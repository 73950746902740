import React, { Component } from 'react';
import Dropdown from '../components/Dropdown';
import { SEARCH_PARAMS } from '../constants/index'
import TextBox from '../components/TextBox';
import TableComponent from '../components/TableComponent';
import { connect } from 'react-redux';
import * as CustomerActions from '../actions/SearchCustomerPageActions'
import { getHistory } from '../actions/AppActions'
import CustomButton from '../components/Button';
import DetailsCard from '../components/DetailsCard';
const STATUS_DISBURSED = 11;
const STATUS_APPROVED = 7;

class CustomerSearchContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchBy: 'accountNo',
            searchValue: null,
        }
    }

    changeSearchTerm = (e, index, value) => {
        this.setState({ searchBy: value })
    }

    setSearchText = (e, val) => {
        this.setState({ searchValue: val })
    }

    goSearch = (e) => {
        const {
            searchBy,
            searchValue
        } = this.state;
        // searching for customers with pre-qualified limits 
        //calling getHistory props is required in the same sequence
        this.props.searchCustomer({ [searchBy]: searchValue })
        this.props.getHistory(this.props.history)
    }

    handleCardClick = (lead_id, user_id, credit_score) => {
        const history = this.props.history;
        // if(credit_score){
            history.push(`/onboard/submit/${lead_id}/${user_id}`)
        // }
        // else{
        //     history.push(`/score/${lead_id}/${user_id}`)
        // }
    }

    render() {
        const {
            searchBy,
            searchValue
        } = this.state

        const {
            customers
        } = this.props;

        return (
            <div className='inner-container'>
                <div className='flex-horizontal width-100 item-center-align inner-container-dropdown'>
                    <div className=''>
                        <Dropdown
                            value={searchBy}
                            items={SEARCH_PARAMS}
                            floatingLabelText='Search By'
                            onChange={this.changeSearchTerm}
                            style={{ width: '180px' }}
                        />
                    </div>
                    <div className=''>
                        <TextBox
                            onChange={this.setSearchText}
                            showBtn={true}
                            style={{ height: '40px' }}
                            sideBtnCoreStyle={{ width: '40px', height: '32px' }}
                            sideButtonStyle={{ height: '40px' }}
                            sideBtnlabelStyle={{ top: '5px', fontSize: '15px' }}
                            sideBtnContainerStyle={{ top: '-20px' }}
                            sideBtnLabel='Go'
                            hintText='Search'
                            onClick={this.goSearch}
                            sideBtnDisabled={!searchValue}
                        />
                    </div>
                </div>
                <div style={{ width: '70px' }}>
                    <div style={{ paddingLeft: '10px' }}>
                        <CustomButton
                            label='Add Lead'
                            labelStyle={{ padding: '0px', fontSize: '12px' }}
                            onClick={() => this.props.history.push('/onboard/edit')}
                        />
                    </div>
                </div>
                <hr />
                {
                    customers instanceof Array && customers.map((item, index) => {
                        return (
                            <div className='inner-container' key={index}>
                                <DetailsCard
                                    clickHandle={this.handleCardClick.bind(null, item.lead_id, item.user_id, item.credit_score)}
                                    item={item}
                                    keys={['metro_card_no', 'company_name', 'profile_name', 'mobile','lead_status_text', 'lead_approved_amount_formatted', 'sanction_amount_formatted', 'pre_approved_amount_formatted']}
                                    headers={['Metro Card Number', 'Business Name', 'Name', 'Mobile','Status', 'Approved Amount', 'Disbursed Amount', 'Pre-approved Limit']}
                                    hide={[,,,,,item['lead_status'] == STATUS_DISBURSED,item['lead_status'] == STATUS_APPROVED ,(item['lead_status'] == STATUS_DISBURSED || item['lead_status'] == STATUS_APPROVED)]}
                                />
                                <br />
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        customers: state.customers.info,
    };
}

export default connect(mapStateToProps, {
    ...CustomerActions, getHistory
})(CustomerSearchContainer);