import React from 'react';

const terms = [
    'Offer is valid and applicable to select registered business customers of METRO Cash & Carry India Pvt. Ltd.',
    'Expired coupons will not be considered in any case after the validity month is over.',
    'Offer coupons once lost will not be replaced.',
    'Program enrolment is non–refundable.',
    'Membership benefits & offers are non–transferable to other businesses and card holders.',
    'Members can avail the benefits of only one Loyalty Program – Bandhan Gold or Bandhan Elite/Aspire.',
    'Coupon offers & discounts can be availed only at Business Registration Store.',
    'All liquor, tobacco & baby food articles are excluded from this offer.',
    'METRO can withdraw/modify this scheme, without giving any prior intimation.',
    'In case of any dispute, the decision of METRO shall be final & binding on both the parties.',
    'All disputes are subject to the exclusive jurisdiction of the courts in Bangalore.'
]
const MembershipTnC = (props) => (
    <div className='padding-side fluid-width-70 tnc-text'>
        You agree to terms and conditions of Metro Cash and Carry & agree to share Metro card data and mobile number with any third party administering the program on behalf of METRO
        <ol>
            {
                terms.map((item, i) => {
                    return <li key={i}>{item}</li>
                })
            }
        </ol>
    </div>
);

export default MembershipTnC;