import React, { Component } from 'react'
import { connect } from 'react-redux';
import * as ApprovalActions from '../actions/ApprovalPageActions';
import TransactionRow from '../components/TransactionRow';
import { getFormValues } from 'redux-form';
import { getStoreId } from '../constants/index'
import Modal from '../components/Modal';
import ApprovalModalForm from '../components/ApprovalModalForm';
import {
  isInt,
  addDaysInDate,
  getDateString,
  findObjectInArray
} from '../utils/utility'
import Dropdown from '../components/Dropdown';
import { ACCOUNT_PROVIDER } from '../constants'

class ApprovalContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      transactionId: null,
      mismatchError: null,
      pdcDateMin: null,
      pdcDateMax: null,
    }
  }

  getTransactions = (providerId) => {
    const storeIds = getStoreId();
    this.props.fetchTransactions({
      providerId,
      accountProvider: ACCOUNT_PROVIDER,
      storeIds
    })
  }

  componentDidMount() {
    const {
      providerId
    } = this.props;

    if (providerId !== '-1') {
      this.getTransactions(providerId);
    }

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.apprMsg) {
      setTimeout(function () {
        this.setState({ showModal: false }, function () {
          this.props.resetApprovalSuccess(null)
        })
      }.bind(this), 1000)
    }
  }

  componentWillUnmount() {
    this.props.resetTransaction(); // resetting transactions
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.providerId === '-1' && this.props.providerId !== '-1') {
      this.getTransactions(this.props.providerId);
    }
  }

  handleCloseModal = () => {
    this.setState({ showModal: false })
  }

  submit = (transactionId, amount, values) => {
    const {
      txns
    } = this.props;

    let selectedTxn = findObjectInArray(txns, transactionId, 'id')

    // console.log('approved', transactionId, amount, values)
    //To prevent form submission if a different row button is clicked
    let keysArray = Object.keys(values);
    if (keysArray.includes(`${transactionId}-amount`)) {
      if (values[`${transactionId}-amount`] <= amount && isInt(values[`${transactionId}-amount`])) {
        this.props.resetTransactionError([]); // resetting error message
        //setting min and max pdc Date criteria
        let minDate = getDateString(addDaysInDate(new Date(), 1));
        let maxDate = getDateString(addDaysInDate(new Date(), selectedTxn ? selectedTxn.offered_duration : 14));

        this.setState({
          showModal: true,
          transactionId,
          mismatchError: null, // resetting mismatch error message
          pdcDateMin: minDate,
          pdcDateMax: maxDate
        });
      }
      else {
        // to throw the error from the server
        this.props.approveTransaction({
          transactionId,
          billedAmount: values[`${transactionId}-amount`], //check name of text-field in the form
        })
      }
    }
    else {
      console.log('Wrong transaction clicked')
    }
  }

  submitInnerForm = (values) => {
    // console.log('Values', values)
    const storeIds = getStoreId();
    const {
      transactionId
    } = this.state

    const {
      rowValues,
      approveTransaction
    } = this.props

    if (rowValues[`${transactionId}-amount`] == values.amount) {
      if (this.state.mismatchError) {
        this.setState({ mismatchError: null })
      }
      try {
        approveTransaction({
          transactionId,
          billedAmount: rowValues[`${transactionId}-amount`], //check name of text-field in the form
          storeIds,
          chequeNum: values.chequeNum,
          chequeAccountNo: values.chequeAccountNo,
          bankName: values.bankName,
          ifsc: values.ifsc,
          micrCode: values.micrCode,
          pdcDate: values.pdcDate,
          invoiceNo: values.invoiceNo,
        })
      }
      catch (err) {
        console.log(err)
      }
    }
    else {
      console.log('Not Match')
      this.setState({ mismatchError: 'Approved amount do not match' })
    }
  }

  rejectClick = (transactionId) => {
    const storeIds = getStoreId();
    // console.log('rejected')
    this.props.rejectTransaction({
      transactionId,
      storeIds
    })
  }

  handleChange = (event, index, value) => {
    this.props.setLender(value) //setting lender in store
    this.getTransactions(value); // re-fetching transactions
  }

  render() {
    const {
      isLoaded,
      txns,
      errorTxns,
      errorAction,
      apprMsg,
      providerId,
      lenderConstants = [],
      modalFormValues
    } = this.props

    const {
      transactionId,
      mismatchError,
      pdcDateMin,
      pdcDateMax,
    } = this.state

    let errors = {
      errorAction: errorAction
    }
    return (
      <div>
        <div className='inner-container-dropdown'>
          <Dropdown
            floatingLabelText='Lender'
            items={lenderConstants}
            value={providerId}
            onChange={this.handleChange}
            keys={['bank_id', 'bank_name']}
          />
        </div>
        {
          isLoaded ?
            <div className='other'>
              <Modal
                showModal={this.state.showModal}
                handleCloseModal={this.handleCloseModal}
              >
                <div>
                  <ApprovalModalForm
                    onSubmit={this.submitInnerForm}
                    formError={errorAction}
                    transactionId={transactionId}
                    approvalMsg={apprMsg}
                    mismatchError={mismatchError}
                    onCancel={this.handleCloseModal}
                    min={pdcDateMin}
                    max={pdcDateMax}
                    providerId={providerId}
                    toggle={modalFormValues ? modalFormValues.toggle: null}
                  />
                </div>
              </Modal>
              {
                txns && txns.length > 0 ?
                  txns.map((item, index) => {
                    let errorArr = errors.errorAction ? errors.errorAction.find((val, i) => {
                      let keys = Object.keys(val)
                      return keys[0] == item.id ? true : false
                    }) :
                      null
                    {/* console.log('errContainer ', errorArr) */ }
                    return (
                      <div key={index}>
                        <TransactionRow
                          ref={`${index}Row`}
                          key={index}
                          item={item}
                          onSubmit={this.submit.bind(null, item.id, item.amount)}
                          rejectClick={this.rejectClick.bind(null, item.id)}
                          errors={errorArr}
                        />
                        <hr className='hidden-mobile' />
                      </div>
                    );
                  })
                  : <div
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '20px',
                    }}>
                    No data found!
                  </div>
              }
            </div>
            : <div style={{ textAlign: 'center' }}>
              Loading ...
        </div>
        }
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    txns: state.approval.txns,
    isLoaded: state.approval.isLoaded,
    errorTxns: state.approval.errorTxns,
    errorAction: state.approval.errorAction,
    apprMsg: state.approval.apprMsg,
    rowValues: getFormValues('metro')(state),
    modalFormValues: getFormValues('dataForm')(state),
    providerId: state.appConstants.providerId,
    lenderConstants: state.appConstants.lenderConstants
  };
}
export default connect(mapStateToProps, {
  ...ApprovalActions
})(ApprovalContainer);