import {
  fieldSanitize,
  sanitizeDocuments
} from './utility'

export const prepareOnboardSubmit = (data) => {
  const {
    lender, //done
    firstname, //done
    lastname, //done
    pancard, //done
    requiredLimit,
    mobile, //done
    otp = null, //done
    experianConsent, //done
    metroCardNo, //done
    unverifiedLeadId = null, //done
    leadDocs = [],
    companyName,
    productId = null,
    userId,
    action = null
  } = data;

  let sanitizedDocs = sanitizeDocuments(leadDocs)

  if (action == 'submit') {
    return {
      metroCardNo: fieldSanitize(metroCardNo),
      mobile,
      otp,
      pan: pancard,
      firstName: firstname,
      lastName: lastname,
      instrumentId: lender,
      leadId: unverifiedLeadId,
      loanAmount: requiredLimit,
      pullCreditReport: experianConsent,
      leadDocs: sanitizedDocs.length !== 0 ? JSON.stringify(sanitizedDocs) : null,
      productId,
      companyName,
      userId
    }
  } else {
    return {
      metroCardNo: fieldSanitize(metroCardNo),
      mobile,
      otp,
      pan: pancard,
      firstName: firstname,
      lastName: lastname,
      instrumentId: lender,
      unverifiedLeadId,
      loanAmount: requiredLimit,
      pullCreditReport: experianConsent,
      leadDocs: sanitizedDocs.length !== 0 ? JSON.stringify(sanitizedDocs) : null,
      productId,
      companyName
    }
  }
}