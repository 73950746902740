import initialState from './initialState';
import {
  RESET_TRANSACTIONS,
  FETCH_TRANSACTIONS,
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_TRANSACTIONS_FAILURE,

  APPROVE_TRANSACTION,
  APPROVE_TRANSACTION_SUCCESS,
  APPROVE_TRANSACTION_FAILURE,
  RESET_APPROVE_SUCCESS_MESSAGE,
  RESET_TRANSACTION_ERROR,

  REJECT_TRANSACTION,
  REJECT_TRANSACTION_SUCCESS,
  REJECT_TRANSACTION_FAILURE
} from '../actions/actionConstants'


export default function TransactionReducer(state = initialState.approval, action) {

  switch (action.type) {

    case FETCH_TRANSACTIONS:
      return Object.assign({}, state, { isLoaded: false }, { errorTxns: null })

    case FETCH_TRANSACTIONS_SUCCESS:
      return Object.assign({}, state, { isLoaded: true }, { txns: action.data })

    case FETCH_TRANSACTIONS_FAILURE:
      return Object.assign({}, state, { isLoaded: true }, { errorTxns: action.error })
    
    case RESET_TRANSACTIONS:
      return Object.assign({}, state, { isLoaded: false }, { errorTxns: null }, { txns: null })

    case APPROVE_TRANSACTION:
      //remove {errorAction:[]} if want to persist all the errors throughout the table
      return Object.assign({}, state, { transactionInProgress: true }, { apprMsg: null },{ errorAction:[] })

    case APPROVE_TRANSACTION_FAILURE:
      let aprvArr = [action.error]
      return Object.assign({}, state, { transactionInProgress: false }, { errorAction: state.errorAction.concat(aprvArr) }, { apprMsg: null })

    case APPROVE_TRANSACTION_SUCCESS:
      return Object.assign({}, state, { transactionInProgress: false }, { errorAction: [] }, { apprMsg: action.data })
    
    case RESET_APPROVE_SUCCESS_MESSAGE:
      return Object.assign({}, state, { apprMsg: action.data })

    case RESET_TRANSACTION_ERROR:
      return Object.assign({}, state, { errorAction: action.data })

    case REJECT_TRANSACTION:
      return Object.assign({}, state, { transactionInProgress: true })

    case REJECT_TRANSACTION_FAILURE:
      let rejectArr = [action.error]
      return Object.assign({}, state, { transactionInProgress: false }, { errorAction: state.errorAction.concat(rejectArr) })

    default:
      return state;
  }
}

