import React, { Component } from 'react';
import HomePage from './containers/HomePage';
import ApprovalContainer from './containers/ApprovalContainer';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import Header from './components/Header';
import NotFoundPage from './components/NotFoundPage';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import AdminContainer from './containers/AdminContainer';
import CreditTransactionContainer from './containers/CreditTransactionContainer';
import DebitTransactionContainer from './containers/DebitTransactionContainer';
import MembershipContainer from './containers/MembershipContainer';
import HealthCheck from './components/HealthCheck';
import UploadContainer from './containers/UploadContainer';
import CustomerSearchContainer from './containers/CustomerSearchContainer';
import OnboardContainer from './containers/OnboardContainer';
import LenderContainer from './containers/LenderContainer';
import CreditScoreContainer from './containers/CreditScoreContainer';
import SuccessPage from './components/SuccessPage';
import TestMembershipContainer from './containers/TestMembershipContainer';

const DefaultLayout = ({ component: Component, ...rest }) => {
  // console.log('rest', rest)
  return (
    <Route {...rest} render={matchProps => (
      <div className="App">
        <Header {...rest} />
        <Component {...matchProps} />
      </div>
    )} />
  )
};


class App extends Component {
  // Whenever adding a route make sure you add the route path in ADDITIONAL_ROUTES constants for Authenticated routes functionality
  render() {
    return (
      <BrowserRouter>
        <MuiThemeProvider>
          <Switch>
            <AuthenticatedRoute path={`/`} exact component={HomePage} />
            <AuthenticatedRoute path='/approval' component={ApprovalContainer} />
            <AuthenticatedRoute path='/loan/edit' component={AdminContainer} />
            <AuthenticatedRoute path='/loan/credit' component={CreditTransactionContainer} />
            <AuthenticatedRoute path='/loan/debit' component={DebitTransactionContainer} />
            <AuthenticatedRoute path='/membership' component={MembershipContainer} />
            <AuthenticatedRoute path='/health-check' component={HealthCheck} />
            {/* <AuthenticatedRoute path='/upload' component={UploadContainer} /> */}
            <AuthenticatedRoute path='/leads' component={CustomerSearchContainer} />
            <AuthenticatedRoute path='/onboard/:action/:leadId?/:userId?' component={OnboardContainer} />
            <AuthenticatedRoute path='/score/:leadId?/:userId?' component={CreditScoreContainer} />
            <AuthenticatedRoute path='/providers' component={LenderContainer} />
            <AuthenticatedRoute path='/success/:leadId?' component={SuccessPage} />
            <AuthenticatedRoute path='/xiaomi-membership' component={TestMembershipContainer} />
            <AuthenticatedRoute path='/approval-via-upload' component={UploadContainer} />
            <Redirect to='/' />
          </Switch>
        </MuiThemeProvider>
      </BrowserRouter>
    );
  }
}

export default App;