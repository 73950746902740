import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as CustomerActions from '../actions/SearchCustomerPageActions'
import { LEAD_STATUS } from '../constants/index';
import Dropdown from '../components/Dropdown';
import LenderRow from '../components/LenderRow';
import CustomPagination from '../components/CustomPagination';
import { getSetLocale, setFilter, postSetLocale, getLeadDocPayload } from '../utils/utility'
import CheckboxesWrapper from '../components/CheckboxesWrapper';
import Modal from '../components/Modal';
import CustomButton from '../components/Button';
import DocumentPreview from '../components/DocumentPreview';
import LeadDetails from '../components/LeadDetails';
import TextBox from '../components/TextBox';
import { RingLoader } from 'react-spinners';
import { CreditReportService } from '../Services/CreditReportService';
import { resetForm } from '../actions/MembershipFormActions';
import PendingDocuments from '../components/PendingDocuments';
import { LEAD_SEARCH_PARAMS } from '../constants/index'

const API_URL_LEADS = '/leads/history/get';
const API_URL_GET_LEAD_DOCS = '/lead/docs';
const API_URL_DOC_VERIFY = '/lead/docs/update'
const API_URL_CHANGE_STATUS = '/third-party/loan/lead/history'
const API_URL_APPROVE_LOAN_AMOUNT = '/lead/credit-line/update'
const PRODUCT_ID = 15;
const OTHER_DOC = 'other_doc'

class LenderContainer extends Component {
    creditReport = new CreditReportService(this);

    constructor(props) {
        super(props);

        const products = JSON.parse(window.sessionStorage.getItem('products'))

        this.state = {
            leads: [],
            metadata: {},
            docs: [],
            leadDocs: [],
            showModal: false,
            leadId: null,
            userId: null,
            isFetching: false,

            isVerifying: false,
            docVerificationMsg: null,
            docVerificationError: [],

            remarks: null,
            historyStatus: '-1',
            isChangingStatus: false,
            statusMsg: null,
            statusError: [],

            loanAmount: null,
            isApproving: false,
            approveMsg: null,
            approveError: [],

            isPending: [],

            creditReportError: null,
            updateDocErrors: {},
            otherDocs: [],

            searchBy: 'mobile',
            searchValue: '',

            products: products,

            filters: {
                productIds: products instanceof Array && products.length > 0 ? products[0]['product_id']: '-1',
                statusIds: '-1',
                limit: 10,
                offset: 0
            },
        }
        this.setState = this.setState.bind(this)
    }

    componentWillUnmount() {
        //resetting all the details on Component Unmount as *getCustomerAccount saga is being used in onboarding container
        this.props.resetForm({ formName: 'onboard' });
        this.props.resetCustomerDetails();
    }


    verifyDocs = (event, action = 'verify') => {
        const {
            leadDocs,
            docs,
            leadId,
            userId
        } = this.state;

        //obtaining the unchecked items from the lead/docs api list. docs array contains checked items
        let unverifiedDocList = leadDocs[0].lead_docs.filter((item) => {
            const result = docs.find(value => value == item.media_file_id)
            return result === undefined ? true : false
        })

        let payload = [];
        // creating the payload adding unchecked items with verification status 0
        payload = unverifiedDocList.map((item) => {
            return { media_file_id: item.media_file_id, verification_status: 0, status: 1 }
        });
        // creating the payload adding checked items with verification status 1
        payload = payload.concat(docs.map((item) => {
            return { media_file_id: item, verification_status: 1, status: 1 }
        }));
        // console.log('finalDocList', payload)
        payload = payload.length !== 0 ? JSON.stringify(payload) : null

        if (action == 'verify') {
            // Verifying documents
            postSetLocale(API_URL_DOC_VERIFY, { leadDocs: payload, userId, leadId }, this.setState, { msg: 'docVerificationMsg', errors: 'docVerificationError', isLoading: 'isVerifying' })
                .catch(err => { })
        }

        return payload;
    }

    setFreshUploadedDoc = (doc) => {
        const {
            info,
            multiDocs
        } = this.props;
        const { otherDocs } = this.state;

        const leadId = info.lead_id;
        const userId = info.user_id;

        try {
            let payload = this.verifyDocs(null, 'doc') // obtaining final doc list
            payload = payload && payload.length !== 0 ? [].concat(doc).concat(JSON.parse(payload)).concat(otherDocs) : [].concat(doc).concat(otherDocs)
            let sanitizedPayload = getLeadDocPayload(payload)
            payload = JSON.stringify(sanitizedPayload)
            postSetLocale(API_URL_DOC_VERIFY, { leadDocs: payload, userId, leadId }, this.setState)
                .then(() => {
                    //get lead specific docs list - rehydrate
                    this.setState({ leadDocs: [] }) // [@TODO: state not updating due to mutation]
                    getSetLocale(API_URL_GET_LEAD_DOCS, { leadId, userId, thumbnails: 1 }, this.setState, { data: 'leadDocs' })
                })
                .catch((err) => {
                    this.setState(prevState => {
                        return { updateDocErrors: Object.assign({}, prevState.updateDocErrors, { [doc.pretty_name]: err.data.errors[0] }) }
                    })
                })
        } catch (err) { console.log('Something Went Wrong', err) }

        //setting otherDocs state if other document is being uploaded
        if (multiDocs.hasOwnProperty(doc.pretty_name)) {
            this.setState(prevState => {
                return { otherDocs: prevState.otherDocs.concat(doc) }
            })
        }
    }

    removeUpdateError = (err, prettyName) => {
        this.setState(prevState => {
            return { updateDocErrors: Object.assign({}, prevState.updateDocErrors, { [prettyName]: null }) }
        })
    }

    showModalOnClick = (leadId, userId) => {
        this.setState({ showModal: true, leadId, userId })
        //get lead specific docs list
        getSetLocale(API_URL_GET_LEAD_DOCS, { leadId, userId, thumbnails: 1 }, this.setState, { data: 'leadDocs' })
        //get Lead details
        this.props.searchCustomerAccount({ leadId })
    }

    getDocs = (arr) => {
        this.setState({ docs: arr })
    }

    pendingDocs = (arr) => {
        this.setState({ isPending: arr })
    }

    handleCloseModal = () => {
        this.props.resetCustomerDetails();
        this.setState({
            showModal: false,
            docs: [],
            leadId: null,
            userId: null,
            leadDocs: [],
            docVerificationError: [],
            docVerificationMsg: null,
            statusError: [],
            statusMsg: null,
            approveError: [],
            approveMsg: null,
            creditReportError: null,
            historyStatus: '-1',
            loanAmount: null
        })
    }

    changeSearchTerm = (e, index, value) => {
      this.setState({searchBy: value})
    }

    setSearchText = (e, value) => {
        this.setState({searchValue: value})
     }

     goSearch = () => {
         const {
            searchBy,
            searchValue
         } = this.state;

        getSetLocale(
            API_URL_LEADS,
            { ...this.state.filters, statusIds: -1, offset: 0, [searchBy]: searchValue },
            this.setState,
            { data: 'leads', meta: 'metadata', isLoading: 'isFetching' })

        const filterArr = [{ tag: 'statusIds', value: '-1' }, { tag: 'offset', value: 0 }]
        setFilter(filterArr, this.state, this.setState)
     }

    changeStatus = (e, index, value) => {
        getSetLocale(
            API_URL_LEADS,
            { ...this.state.filters, statusIds: value, offset: 0 },
            this.setState,
            { data: 'leads', meta: 'metadata', isLoading: 'isFetching' })

        const filterArr = [{ tag: 'statusIds', value }, { tag: 'offset', value: 0 }]
        setFilter(filterArr, this.state, this.setState)
        this.setState({searchValue:''})
    }

    changeProduct = (e, index, value) => {
        getSetLocale(
            API_URL_LEADS,
            { ...this.state.filters, productIds: value, offset: 0 },
            this.setState,
            { data: 'leads', meta: 'metadata', isLoading: 'isFetching' })

        const filterArr = [{ tag: 'productIds', value }, { tag: 'offset', value: 0 }]
        setFilter(filterArr, this.state, this.setState)
    }

    handlePageChange = (obj) => {
        const{
            searchBy,
            searchValue
        } = this.state;

        const {
            limit,
            statusIds
        } = this.state.filters;

        let searchFilter = {};
        //if searchValue is empty and Status == All
        if(searchValue && statusIds == '-1'){
            searchFilter = {
                [searchBy]: searchValue
            }
        }

        getSetLocale(
            API_URL_LEADS,
            { ...this.state.filters, offset: limit * obj.selected, ...searchFilter },
            this.setState,
            { data: 'leads', meta: 'metadata', isLoading: 'isFetching' })

        const filterArr = [{ tag: 'offset', value: limit * obj.selected }]
        setFilter(filterArr, this.state, this.setState)
    }

    onHistoryChange = (event, index, value) => {
        this.setState({ historyStatus: value })
    }

    setTextBoxValue = (name, event, value) => {
        this.setState({ [name]: value })
    }

    submitHistory = () => {
        const {
            remarks,
            historyStatus,
            leadId,
            userId,
        } = this.state;
        //submit history
        postSetLocale(
            API_URL_CHANGE_STATUS,
            { status: historyStatus, remark: remarks, userId, leadId },
            this.setState,
            { msg: 'statusMsg', errors: 'statusError', isLoading: 'isChangingStatus' })
            .then(res => {
                //get Lead details - rehydrate
                this.props.searchCustomerAccount({ leadId })
            })
            .catch(err => { })
    }

    approveLoanAmount = () => {
        const {
            loanAmount,
            leadId,
            userId,
        } = this.state;

        //approve loan amount
        postSetLocale(
            API_URL_APPROVE_LOAN_AMOUNT,
            { approvedAmount: loanAmount, userId, leadId },
            this.setState,
            { msg: 'approveMsg', errors: 'approveError', isLoading: 'isApproving' })
            .catch(err => { })

    }

    render() {
        const {
            leads,
            leadDocs,
            metadata,
            isFetching,

            isVerifying,
            docVerificationError,
            docVerificationMsg,

            historyStatus,
            isChangingStatus,
            statusMsg,
            statusError,

            approveMsg,
            approveError,
            isApproving,

            creditReportError,

            isPending,
            updateDocErrors,
            loanAmount,

            searchBy,
            searchValue,

            products,
            userId
        } = this.state;

        const {
            limit,
            offset,
            statusIds,
            productIds
        } = this.state.filters;

        const {
            info,
            doc_types,
            multiDocs
            // products
        } = this.props

        return (
            <div>
                <Modal
                    showModal={this.state.showModal}
                    handleCloseModal={this.handleCloseModal}
                >
                    <div>
                        <div>
                            <h5>Customer details</h5>
                            <LeadDetails
                                item={info instanceof Array ? {} : info}
                                viewReport={info instanceof Array ? () => { } : this.creditReport.viewFullReport.bind(null, info.user_id)}
                                errorReport={creditReportError}
                            />
                        </div>
                        <hr />
                        <div className='document-verification'>
                            {
                                leadDocs.length !== 0 && leadDocs[0].lead_docs.length !== 0 &&
                                <h5>Document Verification</h5>
                            }
                            <CheckboxesWrapper
                                checkboxes={[{ pretty_name: 'show', media_file_id: 1, title: 'Upload pending documents', verification_status: 0 }]}
                                getValueArray={this.pendingDocs}
                            />
                            {
                                isPending[0] === 1 &&
                                <PendingDocuments
                                    leadDocs={leadDocs}
                                    requiredDocs={doc_types}
                                    info={info}
                                    setFreshUploadedDoc={this.setFreshUploadedDoc}
                                    removeUpdateError={this.removeUpdateError}
                                    updateDocErrors={updateDocErrors}
                                    multiDocs={multiDocs}
                                />
                            }
                            <br />
                            {
                                leadDocs.length !== 0 && leadDocs[0].lead_docs.length !== 0
                                    ?
                                    <div>
                                        <CheckboxesWrapper
                                            checkboxes={leadDocs[0].lead_docs}
                                            userId={userId}
                                            getValueArray={this.getDocs}
                                            showPreview={true}
                                        />
                                        <div style={{ width: '200px' }}>
                                            <CustomButton
                                                label='Verify'
                                                fullWidth={true}
                                                type='button'
                                                onClick={this.verifyDocs}
                                                isLoading={isVerifying}
                                            />
                                        </div>
                                        {
                                            !isVerifying && docVerificationError && docVerificationError.length > 0 &&
                                            <div className='membership-msg' style={{ bottom: '12px', padding: '0' }}>
                                                <br />
                                                {
                                                    docVerificationError.map((item, i) => {
                                                        return (
                                                            <div className="alert alert-danger" role="alert" key={i}>
                                                                {item}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        {
                                            !isVerifying && docVerificationMsg &&
                                            <div className='membership-msg' style={{ top: '10px', padding: '0' }}>
                                                <div className="alert alert-success" role="alert">
                                                    {docVerificationMsg}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    : <h6>No Documents Found</h6>
                            }
                        </div>
                    </div>
                    <hr />
                    <div className='approve-limit'>
                        <h5>Loan Amount Approval</h5>
                        <TextBox
                            onChange={this.setTextBoxValue.bind(null, 'loanAmount')}
                            type='tel'
                            showBtn={true}
                            style={{ height: '40px' }}
                            sideBtnCoreStyle={{ width: '100px', height: '32px' }}
                            sideButtonStyle={{ height: '40px' }}
                            sideBtnlabelStyle={{ top: '5px', fontSize: '15px' }}
                            sideBtnContainerStyle={{ top: '-20px' }}
                            sideBtnLabel='Approve'
                            hintText='Loan Amount'
                            onClick={this.approveLoanAmount}
                            sideBtnDisabled={isApproving || !loanAmount}
                        />
                        {
                            !isApproving && approveError && approveError.length > 0 &&
                            <div className='membership-msg' style={{ bottom: '12px', padding: '0' }}>
                                <br />
                                {
                                    approveError.map((item, i) => {
                                        return (
                                            <div className="alert alert-danger" role="alert" key={i}>
                                                {item}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                        {
                            !isApproving && approveMsg &&
                            <div className='membership-msg' style={{ top: '10px', padding: '0' }}>
                                <div className="alert alert-success" role="alert">
                                    {approveMsg}
                                </div>
                            </div>
                        }
                    </div>
                    <hr />
                    <div className='lead-status-change'>
                        <div>
                            <h5>Status Change</h5>
                            <Dropdown
                                items={LEAD_STATUS}
                                floatingLabelText='Change Lead Status'
                                style={{ width: '250px' }}
                                value={historyStatus}
                                onChange={this.onHistoryChange}
                            />
                            <div style={{ marginBottom: '35px' }}>
                                <TextBox
                                    style={{ height: '100px' }}
                                    hintText='Enter your remarks here (if any)'
                                    hintStyle={{ top: '5px' }}
                                    maxLength={500}
                                    multiLine={true}
                                    rows={2}
                                    rowsMax={4}
                                    textareaStyle={{ margin: '0px' }}
                                    onChange={this.setTextBoxValue.bind(null, 'remarks')}
                                />
                            </div>
                            <div style={{ width: '200px' }}>
                                <CustomButton
                                    label='Confirm'
                                    fullWidth={true}
                                    type='button'
                                    onClick={this.submitHistory}
                                    isLoading={isChangingStatus}
                                />
                            </div>
                            {
                                !isChangingStatus && statusError && statusError.length > 0 &&
                                <div className='membership-msg' style={{ bottom: '12px', padding: '0' }}>
                                    <br />
                                    {
                                        statusError.map((item, i) => {
                                            return (
                                                <div className="alert alert-danger" role="alert" key={i}>
                                                    {item}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                            {
                                !isChangingStatus && statusMsg &&
                                <div className='membership-msg' style={{ top: '10px', padding: '0' }}>
                                    <div className="alert alert-success" role="alert">
                                        {statusMsg}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <br /><br />
                </Modal>
                <div className='width-100 inner-container-dropdown horizontal-to-vertical'>
                <div style={{ zIndex: '0', padding: '5px' }}>
                        <Dropdown
                            value={ productIds }
                            items={products instanceof Array ? products : []}
                            floatingLabelText='Product'
                            onChange={this.changeProduct}
                            style={{ width: '250px' }}
                            keys={['product_id','product_name']}
                        />
                    </div>
                    <div style={{ zIndex: '0', padding: '5px' }}>
                        <Dropdown
                            value={statusIds}
                            items={LEAD_STATUS}
                            floatingLabelText='Lead Status'
                            onChange={this.changeStatus}
                            style={{ width: '250px' }}
                        />
                    </div>
                    <div className='flex-horizontal width-100 item-center-align inner-container-dropdown'>
                    <div style={{ zIndex: '0' }}>
                        <Dropdown
                            value={searchBy}
                            items={LEAD_SEARCH_PARAMS}
                            floatingLabelText='Search By'
                            onChange={this.changeSearchTerm}
                            style={{ width: '162px' }}
                        />
                    </div>
                    <div className=''>
                        <TextBox
                            onChange={this.setSearchText}
                            showBtn={true}
                            style={{ height: '40px' }}
                            sideBtnCoreStyle={{ width: '40px', height: '32px' }}
                            sideButtonStyle={{ height: '40px' }}
                            sideBtnlabelStyle={{ top: '5px', fontSize: '15px' }}
                            sideBtnContainerStyle={{ top: '-20px' }}
                            sideBtnLabel='Go'
                            hintText='Search'
                            onClick={this.goSearch}
                            sideBtnDisabled={!searchValue}
                            value={searchValue}
                        />
                    </div>  
                    </div>
                   
                </div>
                <div className='inner-container'>
                    {
                        !isFetching ? leads.map((item, index) => {
                            return (
                                <div key={index}>
                                    <LenderRow
                                        item={item}
                                        clickHandle={this.showModalOnClick.bind(null, item.lead_id, item.user_id)}
                                    />
                                    <br />
                                </div>
                            )
                        })
                            : <div className='flex-center' style={{ padding: '60px' }}>
                                <RingLoader
                                    color={'dodgerblue'}
                                    loading={true}
                                    size={80}
                                />
                            </div>
                    }
                    <CustomPagination
                        customMsg = {productIds == '-1' ? 'Please Select a product' : null}
                        onPageChange={this.handlePageChange}
                        forcePage={offset / limit}
                        pageCount={metadata ? Math.ceil(metadata.num_results / limit) : 0}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        doc_types: state.appConstants.doc_types,
        info: state.customers.info,
        multiDocs: state.appConstants.multiDocs,
        // products: state.appConstants.lender_product
    };
}

export default connect(mapStateToProps, {
    ...CustomerActions, resetForm
})(LenderContainer);