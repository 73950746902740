import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TableComponent extends Component {

    render() {
        const {
            details = [],
            keys = [],
            tableName = '',
            headers = [],
            dataStyle = {},
            link = null,
            url = null,
            normalDisplay = false
        } = this.props;
        return (
            <div className='inner-container-static overflow-hidden'>
                <br />
                {tableName && <h5>{tableName}</h5>}
                <table className="table">
                    <thead>
                        <tr>
                            {
                                headers.map((item, i) => {
                                    if(normalDisplay){
                                        return (
                                            <th scope="col" key={i}>{item.title}</th>
                                        );
                                    }else{
                                        return (
                                            <th scope="col" key={i}>{item}</th>
                                        );
                                    }
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            details instanceof Array && details.map((item, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <tr>
                                                {
                                                    keys.map((value, index) => {
                                                        if(normalDisplay){
                                                            return <td style={dataStyle} key={index}>{item[value.id]}</td>
                                                        }else{
                                                            return url && link && link === value //link and the key of rendered link should be same;This is kinda hackish; have to improve
                                                                ? item.credit_score
                                                                    ? (<td style={dataStyle} key={index}><Link to={url + `/${item['lead_id']}/${item['user_id']}`}>{item[value]}</Link></td>)
                                                                    : (<td style={dataStyle} key={index}><Link to={`/score/${item['lead_id']}/${item['user_id']}`}>{item[value]}</Link></td>)
                                                                : <td style={dataStyle} key={index}>{item[value]}</td>
                                                        }
                                                    })
                                                }
                                            </tr>
                                        </React.Fragment>
                                    )
                            })
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default TableComponent;