import React, { Component } from 'react';

class HealthCheck extends Component {
    render() {
        return (
            <div>
                Health Check Component
            </div>
        );
    }
}

export default HealthCheck;