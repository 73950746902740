import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import AppConstants from './AppConstantsReducer';
import transaction from './transactionReducer';
import approval from './approvalReducer';
import loans from './editLoansReducer';
import customers from './customerReducer'

const rootReducer = combineReducers({
  transaction,
  approval,
  loans,
  appConstants: AppConstants,
  customers,
  form: formReducer,
  routing: routerReducer
});

export default rootReducer;
