import { take, put, fork, call, select } from 'redux-saga/effects';
import { change, reset } from 'redux-form';
import {
  FETCH_LOANS,
  FETCH_LOANS_SUCCESS,
  FETCH_LOANS_FAILURE,

  FETCH_DETAIL,
  FETCH_DETAIL_SUCCESS,
  FETCH_DETAIL_FAILURE,

  INITIATE_TRANSACTION,
  INITIATE_TRANSACTION_SUCCESS,
  INITIATE_TRANSACTION_FAILURE,

  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,

  GET_MENU,
  GET_MENU_SUCCESS,
  GET_MENU_FAILURE,

  SET_LENDER,
  FETCH_TRANSACTIONS
} from '../actions/actionConstants'
import { post, get } from '../api';
import { getStoreId } from '../constants/index'
import config from '../../src/config';
import { arrayToObject } from '../utils/utility'
import { ACCOUNT_PROVIDER } from '../constants'


function* getLoanDetails() {
  while (true) {
    const request = yield take(FETCH_LOANS);
    // yield put(reset('metro')); // resetting form
    const { payload } = request;
    const fetchDataResponse = yield call(
      post,
      '/third-party/loans', payload);
    if (fetchDataResponse.status >= 400 || fetchDataResponse.status >= 401) {
      yield put({
        type: FETCH_LOANS_FAILURE,
        error: fetchDataResponse.data.errors,
      });
      // console.log('error detail: ', fetchDataResponse)
    }
    else if (fetchDataResponse.status === 200 || fetchDataResponse.status === 201) {
      yield put({
        type: FETCH_LOANS_SUCCESS,
        data: fetchDataResponse.data.data.results,
      });
      if(fetchDataResponse.data.data.results.length == 0){
        //if results is empty array
        yield put(change('metro', 'customer', ''))
        yield put(change('metro', 'amount', ''))
        yield put(change('metro', 'otp', ''))
      }
    }
  }
}


function* getUserDetails() {
  while (true) {
    const request = yield take(FETCH_DETAIL);
    // yield put(reset('metro')); // resetting form
    const { payload } = request;
    const fetchDataResponse = yield call(
      post,
      '/third-party/loan/details', payload);
    if (fetchDataResponse.status >= 400 || fetchDataResponse.status >= 401) {
      yield put({
        type: FETCH_DETAIL_FAILURE,
        error: fetchDataResponse.data.errors,
      });
      // console.log('error detail: ', fetchDataResponse)
    }
    else if (fetchDataResponse.status === 200 || fetchDataResponse.status === 201) {
      yield put({
        type: FETCH_DETAIL_SUCCESS,
        data: fetchDataResponse.data.data.results,
      });
      // console.log('resp detail: ', fetchDataResponse)
      yield put({
        type: FETCH_TRANSACTIONS,
        payload: {
          accountProvider: ACCOUNT_PROVIDER,
          providerId: fetchDataResponse.data.data.results.provider_id,
          accountNo: fetchDataResponse.data.data.results.account_no,
          txStatus: 3,
          limit: 3,
          verified: 1,
          storeIds: sessionStorage.getItem('storeId')
        }
      })
      //Putting customer name in the form text field
      const name = fetchDataResponse.data.data.results.name !== undefined ? fetchDataResponse.data.data.results.name : '';
      yield put(change('metro', 'customer', name))
      yield put(change('metro', 'amount', ''))
      yield put(change('metro', 'otp', ''))
    }
  }
}

function* initiateTransaction() {
  while (true) {
    const request = yield take(INITIATE_TRANSACTION);
    const { payload } = request;
    const fetchDataResponse = yield call(
      post,
      '/third-party/loan/transaction/initiate', payload);
    if (fetchDataResponse.status >= 400 || fetchDataResponse.status >= 401) {
      yield put({
        type: INITIATE_TRANSACTION_FAILURE,
        error: fetchDataResponse.data.errors,
      });
      yield put(change('metro', 'otp', ''))
      // console.log('error transact: ', fetchDataResponse)
    }
    else if (fetchDataResponse.status === 200 || fetchDataResponse.status === 201) {
      yield put({
        type: INITIATE_TRANSACTION_SUCCESS,
        data: fetchDataResponse.data.data.results,
        msg: fetchDataResponse.data.data.message
      });
      // console.log('resp transact: ', fetchDataResponse)
      yield put(change('metro', 'otp', ''))
    }
  }
}

function* verifyOTP() {
  while (true) {
    const request = yield take(VERIFY_OTP);
    const { payload } = request;
    const fetchDataResponse = yield call(
      post,
      '/third-party/loan/transaction/verify', payload);
    if (fetchDataResponse.status >= 400 || fetchDataResponse.status >= 401) {
      yield put({
        type: VERIFY_OTP_FAILURE,
        error: fetchDataResponse.data.errors,
      });
      // console.log('error verify: ', fetchDataResponse)
    }
    else if (fetchDataResponse.status === 200 || fetchDataResponse.status === 201) {
      // console.log('resp verify: ', fetchDataResponse)
      yield put({
        type: VERIFY_OTP_SUCCESS,
        // data: fetchDataResponse.data.data.results,
        msg: fetchDataResponse.data.data.message
      });
      //clearing amount and otp fields
      yield put(change('metro', 'amount', ''))
      yield put(change('metro', 'otp', ''))
      //fetching details again
      const state = yield select();
      // console.log('State from Saga', state.form.metro.values.card)
      const storeIds = getStoreId();
      const card = state.form.metro.values.card;
      const fetchDetailResponse = yield call(
        post,
        '/third-party/loan/details', {
          accountNo: card,
          accountProvider: ACCOUNT_PROVIDER,
          storeIds
        });
      if (fetchDetailResponse.status >= 400 || fetchDetailResponse.status >= 401) {
        yield put({
          type: FETCH_DETAIL_FAILURE,
          error: fetchDetailResponse.data.errors,
        });
        // console.log('error detail: ', fetchDetailResponse)
      }
      else if (fetchDetailResponse.status === 200 || fetchDetailResponse.status === 201) {
        yield put({
          type: FETCH_DETAIL_SUCCESS,
          data: fetchDetailResponse.data.data.results,
        });
        // console.log('resp detail: ', fetchDetailResponse)
      }
      //fetching details again
    }
  }
}

function* getAppMenu() {
  while (true) {
    yield take(GET_MENU);
    const fetchDataResponse = yield call(
      get,
      '/third-party/loans/menu');
    if (fetchDataResponse.status >= 400 || fetchDataResponse.status >= 401) {
      //logging out
      const logOutResponse = yield call(
        get,
        '/user/logout');
      if (logOutResponse.status >= 200 || logOutResponse.status >= 201) {
        const path = encodeURIComponent(window.location.href);
        const msg = encodeURIComponent('Access Denied');
        window.sessionStorage.removeItem('user');
        window.location.href = config.SSO_URI + `?service=admin&redirectUrl=${path}&msg=${msg}`;
      }
    }
    else if (fetchDataResponse.status === 200 || fetchDataResponse.status === 201) {

      let multiDocs = {}
      try {
        multiDocs = fetchDataResponse.data.metadata.doc_types;
        //Doc types will be filtered for making an objectwhich includes all the documents which have to be made multidocs
        multiDocs = multiDocs.filter( item => item.multidoc_support)
        const keyIds = arrayToObject(multiDocs,'id');
        const keyPrettyNames = arrayToObject(multiDocs,'file_name');
        multiDocs =  {...keyIds, ...keyPrettyNames}
      } catch (e) {
        console.log('docTypes Error', e)
      }

      yield put({
        type: GET_MENU_SUCCESS,
        data: fetchDataResponse.data.data.results,
        meta: fetchDataResponse.data.metadata,
        multiDocs
      });
      
      yield put({
        type: SET_LENDER,
        data: fetchDataResponse.data.metadata.providers[0]['bank_id']
      })
      // console.log('resp transact: ', fetchDataResponse.data.metadata.doc_types_grouped)
    }
  }
}

export default function* () {
  yield fork(getLoanDetails);
  yield fork(getUserDetails);
  yield fork(initiateTransaction);
  yield fork(verifyOTP);
  yield fork(getAppMenu);
}
