import { take, put, fork, call, select } from 'redux-saga/effects';
import { change, reset } from 'redux-form';
import {
  FETCH_TRANSACTIONS,
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_TRANSACTIONS_FAILURE,

  APPROVE_TRANSACTION,
  APPROVE_TRANSACTION_SUCCESS,
  APPROVE_TRANSACTION_FAILURE,

  REJECT_TRANSACTION,
  REJECT_TRANSACTION_SUCCESS,
  REJECT_TRANSACTION_FAILURE

} from '../actions/actionConstants';

import { post } from '../api';
import { getStoreId} from '../constants/index'
import { ACCOUNT_PROVIDER } from '../constants'

export const getAppConstants = (state) => state.appConstants;

function* getTransactions() {
  while (true) {
    const request = yield take(FETCH_TRANSACTIONS);
    const { payload } = request;
    const fetchDataResponse = yield call(
      post,
      '/third-party/loans/txs', payload);
    if (fetchDataResponse.status >= 400 || fetchDataResponse.status >= 401) {
      yield put({
        type: FETCH_TRANSACTIONS_FAILURE,
        error: fetchDataResponse.data.errors,
      });
      // console.log('error detail: ', fetchDataResponse)
    }
    else if (fetchDataResponse.status === 200 || fetchDataResponse.status === 201) {
      yield put({
        type: FETCH_TRANSACTIONS_SUCCESS,
        data: fetchDataResponse.data.data.results,
      });
      // console.log('resp detail: ', fetchDataResponse)
    }
  }
}

function* approveTransactionSaga() {
  while (true) {
    const request = yield take(APPROVE_TRANSACTION);
    const { payload } = request;
    // console.log('Payload', payload)
    const fetchDataResponse = yield call(
      post,
      '/third-party/loans/tx/approve', payload);
    if (fetchDataResponse.status >= 400 || fetchDataResponse.status >= 401) {
      yield put({
        type: APPROVE_TRANSACTION_FAILURE,
        error: { [payload.transactionId]: fetchDataResponse.data.errors },
      });
      // console.log('error detail: ', fetchDataResponse)
    }
    else if (fetchDataResponse.status === 200 || fetchDataResponse.status === 201) {
      // console.log('resp detail: ', fetchDataResponse)
      yield put({
        type: APPROVE_TRANSACTION_SUCCESS,
        data: fetchDataResponse.data.data.message,
      });
      const storeIds = getStoreId();
      let constants = yield select(getAppConstants); // get AppContants from redux store
      //Refetching transactions again
      const fetchTxnsResponse = yield call(
        post,
        '/third-party/loans/txs', {
          providerId: constants.providerId,
          accountProvider: ACCOUNT_PROVIDER,
          storeIds
        });
      if (fetchTxnsResponse.status >= 400 || fetchTxnsResponse.status >= 401) {
        yield put({
          type: FETCH_TRANSACTIONS_FAILURE,
          error: fetchTxnsResponse.data.errors,
        });
        // console.log('error detail: ', fetchTxnsResponse)
      }
      else if (fetchTxnsResponse.status === 200 || fetchTxnsResponse.status === 201) {
        yield put({
          type: FETCH_TRANSACTIONS_SUCCESS,
          data: fetchTxnsResponse.data.data.results,
        });
      }
    }
  }
}

function* rejectTransactionSaga() {
  while (true) {
    const request = yield take(REJECT_TRANSACTION);
    const { payload } = request;
    // console.log('Payload', payload)
    const fetchDataResponse = yield call(
      post,
      '/third-party/loans/tx/cancel', payload);
    if (fetchDataResponse.status >= 400 || fetchDataResponse.status >= 401) {
      yield put({
        type: REJECT_TRANSACTION_FAILURE,
        error: { [payload.transactionId]: fetchDataResponse.data.errors },
      });
      // console.log('error detail: ', fetchDataResponse)
    }
    else if (fetchDataResponse.status === 200 || fetchDataResponse.status === 201) {
      // console.log('resp detail: ', fetchDataResponse)
      const storeIds = getStoreId();
      let constants = yield select(getAppConstants); // get AppContants from redux store
      //Refetching transactions again
      const fetchTxnsResponse = yield call(
        post,
        '/third-party/loans/txs', {
          providerId: constants.providerId,
          accountProvider: ACCOUNT_PROVIDER,
          storeIds
        });
      if (fetchTxnsResponse.status >= 400 || fetchTxnsResponse.status >= 401) {
        yield put({
          type: FETCH_TRANSACTIONS_FAILURE,
          error: fetchTxnsResponse.data.errors,
        });
        // console.log('error detail: ', fetchTxnsResponse)
      }
      else if (fetchTxnsResponse.status === 200 || fetchTxnsResponse.status === 201) {
        yield put({
          type: FETCH_TRANSACTIONS_SUCCESS,
          data: fetchTxnsResponse.data.data.results,
        });
      }
    }
  }
}

export default function* () {
  yield fork(getTransactions);
  yield fork(approveTransactionSaga);
  yield fork(rejectTransactionSaga);
}
