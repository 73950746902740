import React, { Component } from 'react';
import TestMembershipForm from '../components/TestMembershipForm';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import * as MembershipFormActions from '../actions/MembershipFormActions';
import {
    // getAge,
    getcbMetroCardInfo
} from '../utils/utility'
import { get, post } from '../api';
import {
    prepareMembershipAutoPopulate
} from '../utils/prepareMembershipAutoPopulate'
import {
    getMembershipPayAndSavefields
} from '../utils/prepareMembershipSubmitData'
import MembershipTnC from '../components/MembershipTnC';
import Image from '../components/Image';
import {
    normalizeMetroNumber,
} from '../utils/normalizerUtility'

const selector = formValueSelector('membership');
const AUTO_POPULATE_URL = '/partners/metro/cards';
const PAY_AND_SAVE_URL = '/membership/via-qc/pay-and-add';
const MEMBERSHIP_ACTIVATE_URL = '/membership/activate';
const VERIFY_OTP_URL = '/user/verify/mobile';
const SEND_OTP_URL = '/user/otp';

class TestMembershipContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            metroHolderFlag: true,
            // nomineeFlag: false,
            autoPopulatedItem: {},
            error: [],
            msg: null,
            payAndAdd: {},
            isSubmitting: false,
            imgFile: null,
            sendOTPAlert: null,
            otpBtnClickFlag: false,
            scannedMetroCard: null,
            metroCardImage: null
        }
    }

    onScanBarcode = () => {
        let metroCardNo = null;
        let metroCardImage = null;
        try {

            /*eslint-disable */
            AndroidWebView.scanBarcode();
            let intervalId = setInterval(() => {
                metroCardNo = AndroidWebView.getScannedCode();
                if (metroCardNo) {
                    alert('Metro Card Number: ' + metroCardNo)
                    this.setState({
                        scannedMetroCard: metroCardNo,
                    });
                    clearInterval(intervalId);
                }
            }, 1000);

            let imagIntervalId = setInterval(() => {
                metroCardImage = AndroidWebView.getScannedImage();
                if (metroCardImage) {
                    this.setState({
                        metroCardImage
                    });
                    clearInterval(imagIntervalId);
                }
            }, 1000);
            /*eslint-enable */

        } catch (err) {
            console.log('Something went wrong ', err)
            let formData = {
                metroCardNumber: normalizeMetroNumber('2123412312'),
                metroHolderNumber: '1'
            }
            this.props.sendFormAutoPopulate(formData)
        }
    }

    toggleOtpBtnClickFlag = () => {
        this.setState({ otpBtnClickFlag: !this.state.otpBtnClickFlag })
    }

    setImgFile = (file) => {
        this.setState({
            imgFile: file
        })
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        //To set the metroCardFlag to false when metroCardNumber last digit is 1

        if (nextProps.metroHolderNumber) {
            let metroHolderFlag = true;
            if (nextProps.metroHolderNumber && nextProps.metroHolderNumber[0] != 1) {
                metroHolderFlag = false
            }

            if (metroHolderFlag && prevState.metroHolderFlag) {
                // to avoid unnecessary setState in the components 
                // only returning state object if the flag are not in sync with flags
                return null
            }
            else return { metroHolderFlag }
        }
        else return null;
    }

    //utility function for setting the data
    setData = (url, data) => {
        get(url, data)
            .then((res) => {
                if (res.status == 200 || res.status == 201) {
                    const infoObj = res.data.data.results.length > 0 ? res.data.data.results[0] : {}
                    this.setState({
                        autoPopulatedItem: infoObj,
                    })
                    let formData = prepareMembershipAutoPopulate(infoObj)
                    this.props.sendFormAutoPopulate(formData)
                }
            })
    }

    sendOTP = () => {
        // const user = window.sessionStorage.getItem('user');
        const { mobile } = this.props
        if (mobile) {
            const payload = {
                mobile,
                // userId: user ? JSON.parse(user).id : null,
                type: 1,
                objectType: 12
            }
            this.setState({ sendOTPAlert: null })
            this.toggleOtpBtnClickFlag();
            post(SEND_OTP_URL, payload)
                .then(res => {
                    if (res.status == 200) {
                        const msg = res.data.data.message
                        this.setState({ sendOTPAlert: msg })
                        // console.log('SEND_OTP_SUCCESS', msg)
                    }
                    else if (res.status == 400) {
                        const err = res.data.message;
                        // this.setState({ sendOTPAlert: err })
                        // console.log('SEND_OTP_ERROR', err)
                        this.toggleOtpBtnClickFlag();
                    }
                })
        }
    }

    verifyOTP = (mobile, otp) => {
        // const user = window.sessionStorage.getItem('user');
        const payload = {
            mobile,
            otp,
            // userId: user ? JSON.parse(user).id : null,
        }
        return new Promise((resolve) => {
            post(VERIFY_OTP_URL, payload)
                .then(response => {
                    resolve(response)
                })
        })
    }

    handleDataPopulate = (cbMetroCardInfo) => {
        //only making the network call when we have cbMetroCardNo
        //which in itself checks all the conditions internally
        if (cbMetroCardInfo.cbMetroCardNo != null) {
            const data = {
                cbMetroCardNo: cbMetroCardInfo.cbMetroCardNo,
                storeId: cbMetroCardInfo.metroStoreCode,
                metroCustomerNumber: cbMetroCardInfo.metroCustomerNumber,
                cardHolderLevel: cbMetroCardInfo.metroHolderNumber
            }
            this.setData(AUTO_POPULATE_URL, data)
        }
    }

    handleChange = (event, value, initial) => {
        const {
            metroCardNumber
        } = this.props;
        if (initial !== undefined && event.nativeEvent.inputType !== 'deleteContentBackward') {
            event.preventDefault();
            //not making network call when pressing the backspace
        }
        else {
            if (value) {
                const cbMetroCardInfo = getcbMetroCardInfo({ metroHolderNumber: value, metroCardNumber });
                this.handleDataPopulate(cbMetroCardInfo);
            }
        }
    }

    handleBlur = (event, value) => {
        const {
            metroHolderNumber
        } = this.props;
        const cbMetroCardInfo = getcbMetroCardInfo({ metroHolderNumber, metroCardNumber: value });
        this.handleDataPopulate(cbMetroCardInfo);
    }

    prepareMembershipData = (values) => {
        try {
            return {
                // nomineeData: getMembershipPayAndSavefields(values).nomineeData,//sanitize it
                gstNum: values.gstNum,
                userId: this.state.payAndAdd.user_id,
                paymentId: this.state.payAndAdd.payment_id,
                transactionId: this.state.payAndAdd.transaction_id,
                membershipId: this.state.payAndAdd.membership_id,
            }
        }
        catch (err) {
            return null;
        }
    }

    // Too Complicated - move this to saga
    postMembershipReq = (
        url,
        data,
        values,
        cb = () => {
            this.setState({ isSubmitting: false })
            this.props.resetForm();
        }
    ) => {
        this.setState({ error: [], msg: null })
        //setting error and msg to null when calling this function to reset success and error msgs
        post(url, data)
            .then((res) => {
                if (res.status == 200 || res.status == 201) {
                    // debugger;
                    const msg = res.data.data.message;
                    const payAndAddResponse = res.data.data.results;
                    this.setState({ payAndAdd: payAndAddResponse, error: [], msg, otpBtnClickFlag: false, sendOTPAlert: null }, () => {
                        cb(MEMBERSHIP_ACTIVATE_URL, this.prepareMembershipData(values)); //executing the callback function in case of Apply btn click
                    })
                }
                else if (res.status == 400) {
                    this.setState({ error: res.data.errors, msg: null, isSubmitting: false })
                }
            })
    }

    submit = (values) => {
        console.log('values', values, this.state)
        const data = getMembershipPayAndSavefields(values);
        this.setState({ isSubmitting: true }, () => {
            // this.verifyOTP(values.mobile, values.otp)
            //   .then(res => {
            //     if (res.status == 200) {
            this.postMembershipReq(PAY_AND_SAVE_URL, data, values, this.postMembershipReq)
            //   }
            //   else if (res.status == 400) {
            //     this.setState({ error: res.data.errors, msg: null, isSubmitting: false })
            //   }
            // })
        })
    }

    handleReset = () => {
        //reset local error and msg + dispatch form clear function
        this.setState({ error: [], msg: null, imgFile: null }, () => {
            this.props.resetForm({ formName: 'membership' });
        })
    }

    render() {
        const {
            error,
            msg,
            isSubmitting,
            imgFile,
            sendOTPAlert,
            otpBtnClickFlag,
            metroCardImage
        } = this.state;

        return (
            <div>
                <TestMembershipForm
                    onSubmit={this.submit}
                    isSubmitting={isSubmitting}
                    metroHolderFlag={this.state.metroHolderFlag}
                    // nomineeFlag={this.state.nomineeFlag}
                    handleFieldChange={this.handleChange}
                    handleFieldBlur={this.handleBlur}
                    handleReset={this.handleReset}
                    handleOTP={this.sendOTP}
                    setImgFile={this.setImgFile}
                    imgFile={this.state.imgFile}
                    sendOTPAlert={sendOTPAlert}
                    otpBtnClickFlag={otpBtnClickFlag}
                    toggleOtpBtnClickFlag={this.toggleOtpBtnClickFlag}
                    onScanBarcode={this.onScanBarcode}
                    metroCardImage={metroCardImage}
                />
                {
                    error && error.length > 0 &&
                    <div className='membership-msg'>
                        {
                            error.map((item, i) => {
                                return (
                                    <div className="alert alert-danger" role="alert" key={i}>
                                        {item}
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                {
                    msg &&
                    <div className='membership-msg'>
                        <div className="alert alert-success" role="alert">
                            {msg}
                        </div>
                    </div>
                }
                <div className='horizontal-to-vertical j-between item-center-align-mobile'>
                    {/* <MembershipTnC /> */}
                    <Image
                        src={imgFile}
                    />
                </div>
                <br />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        metroCardNumber: selector(state, 'metroCardNumber'),
        metroHolderNumber: selector(state, 'metroHolderNumber'),
        mobile: selector(state, 'mobile'),
        otp: selector(state, 'otp'),
    };
}

export default connect(mapStateToProps, {
    ...MembershipFormActions
})(TestMembershipContainer);