import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import ReduxTextField from '../ReduxFormFields/ReduxTextField';
import TextBoxWithButton from './TextBoxWithButton';
import Paper from './Paper';

class FormContainer extends Component {
  render() {
    const {
      userInfo,
      errors,
      msgs,
      checkLimitDisabled,
      show = true
    } = this.props;

    // console.log('userInfo', userInfo)
    return (
      <React.Fragment>
        <div className='outer-container'>
          <div className='inner-container flex-horizontal width-100' style={{ maxWidth: '500px' }}>
            <TextBoxWithButton
              textBoxName='card'
              textLabel='Metro Card No.(first 8-digits)'
              textDescription={errors.error ? errors.error[0] : null}
              buttonLabel='Check Limit'
              textBoxType='text'
              onClick={this.props.limitCheck}
              disabled={checkLimitDisabled}
            />
          </div>
          {
            show &&
            <React.Fragment>
              <div className='inner-container width-100'>
                <ReduxTextField
                  name='customer'
                  textLabel='Business Name'
                  disabled={true}
                />
              </div>
              <div className='inner-container width-100'>
                <Paper
                  sanctionLimit={userInfo && userInfo.length !== 0 ? userInfo.sanction_amount_formatted : 0}
                  availableLimit={userInfo && userInfo.length !== 0 ? userInfo.available_amount_formatted : 0}
                  utilizedLimit={userInfo && userInfo.length !== 0 ? userInfo.loan_amount_formatted : 0}
                  lenderName={userInfo && userInfo.length !== 0 ? userInfo.lender_name : null}
                />
              </div>
            </React.Fragment>
          }
        </div>
        {
          show &&
          <div className='outer-container'>
            <div className='inner-container flex-horizontal width-100'>
              <TextBoxWithButton
                textBoxName='amount'
                textLabel='Enter billing amount'
                textDescription={errors.InitiateError ? errors.InitiateError[0] : msgs.transactionMsg}
                buttonLabel='Verify'
                onClick={this.props.verifyAmount}
                textBoxType='tel'
              />
            </div>
            <div className='inner-container flex-horizontal width-100' style={{alignItems: 'end'}}>
              <TextBoxWithButton
                textBoxName='otp'
                textLabel={`Enter OTP sent to ${userInfo && userInfo.length !== 0 ? userInfo.mobile : 'user'}`}
                textDescription={errors.otpError ? errors.otpError[0] : msgs.otpVerificationMsg}
                buttonLabel='Initiate'
                wrapperClass='margin-left'
                onClick={this.props.authorize}
                textBoxType='tel'
              />
            </div>
          </div>
        }
      </React.Fragment>
    )
  }
}


const FormFunc = reduxForm({
  form: 'metro',
  enableReinitialize: true,
});

FormContainer = FormFunc(FormContainer);

export default FormContainer;
