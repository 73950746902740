import React, { Component } from 'react';
import Checkbox from 'material-ui/Checkbox';

const styles = {
    block: {
        maxWidth: '100%',
    },
    checkbox: {
        margin: 15,
    },
    closeBorder: {
        border: '1px solid lightgray',
        margin: '10px 10px 10px 0px',
        borderRadius: '15px',
        paddingTop:'10px'
    }
};

class CustomCheckbox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checked: props.checked,
        }
        const {
            checked,
            setCheckBoxValue = () => { },
            value,
            checkboxName
        } = props;

        checked ? setCheckBoxValue(value, checkboxName) : setCheckBoxValue(0, checkboxName); // setting the init value based on checked prop
    }

    updateCheck() {
        const {
            setCheckBoxValue = () => { },
            checkboxName,
            value
        } = this.props;

        this.setState((oldState) => {
            !oldState.checked ? setCheckBoxValue(value, checkboxName) : setCheckBoxValue(0, checkboxName);
            return {
                checked: !oldState.checked,
            };
        });
    }

    render() {

        const {
            label = 'label',
            children
        } = this.props;

        return (
            <div className='flex-vertical j-between' style={children ? styles.closeBorder: {}}>
                {children}
                <Checkbox
                    label={label}
                    checked={this.state.checked}
                    onCheck={this.updateCheck.bind(this)}
                    style={styles.checkbox}
                />
            </div>
        );
    }
}

export default CustomCheckbox;