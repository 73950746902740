import React, { Component } from 'react'
import Drawer from './DrawerContainer';
import bannerMetro from '../images/Metro Logo.png'
import bannerChqbook from '../images/chqbook_logo_vector_rembho.svg'
import { Link } from 'react-router-dom';
import { get } from '../api/index'
import { UtilityService } from '../Services'
import { MEMBERSHIP_HEADER_TEXT } from '../constants'
import { connect } from 'react-redux';
import * as AppActions from '../actions/AppActions';

class Header extends Component {
  service = new UtilityService(this);

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      headerText: ''
    };
  }

  setHeaderText = (name) => {
    this.props.setName(name)
  }

  handleToggle = () => this.setState({ open: !this.state.open });

  handleClose = () => {
    this.setState({ open: false });
    get('/user/logout')
      .then(res => {
        if (res.status == 200 || res.status == 201) {
          window.sessionStorage.removeItem('user');
          window.location.reload();
        }
        else {
          console.log('Something went wrong')
        }
      })
  }

  componentDidMount() {
    // this.service.changeHeaderText('/membership', MEMBERSHIP_HEADER_TEXT); //changing banner text for membership
  }

  componentDidUpdate(prevProps, prevState) {
    // if (prevProps.path != this.props.path) {
    //   this.service.changeHeaderText('/membership', MEMBERSHIP_HEADER_TEXT); //changing banner text for membership
    // }
  }

  render() {
    const {
      headerText
    } = this.props;

    const {
      menu = []
    } = this.props;

    return (
      <div className='header'>
        <Drawer
          handleToggle={this.handleToggle}
          handleClose={this.handleClose}
          open={this.state.open}
          menu={menu}
          setHeaderText={this.setHeaderText}
        />
        <div className='flex-horizontal j-between item-center-align'>
          <div className='flex-horizontal item-center-align '>
            <div
              style={{ cursor: 'pointer' }}
              onClick={this.handleToggle}>
              <i className="fas fa-bars fa-2x"></i>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div className=''>
              <Link style={{ textDecoration: 'none' }} to='/'>
                <img
                  src='https://res.cloudinary.com/chqbook/image/upload/q_auto/v1550643436/logos/final_logo_chqbook.png'
                  className='chqbook-logo'
                  alt='Chqbook Banner' />
              </Link>
            </div>
            &nbsp;&nbsp;&nbsp;
          {/* <div className='metro-logo-div'>
              <Link style={{ textDecoration: 'none' }} to='/'>
                <img
                  src={bannerMetro}
                  className='metro-logo'
                  alt='Metro Banner' />
              </Link>
            </div> */}
            <div className='header-text'>
              <b>{headerText}</b>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    menu: state.appConstants.menu,
    headerText: state.appConstants.header_name
  };
}

export default connect(mapStateToProps, { ...AppActions })(Header);
